<template>
  <div v-if="settings.expanded" class="px-16">
    <v-row
      v-for="(item, i) in formattedFaqs"
      :key="i"
      class="mx-16 mb-12"
      justify="center"
      no-gutters
    >
      <v-col align="center" class="px-16">
        <v-row justify="center" no-gutters class="mb-4">
          <span
            :style="
              `color: ${settings['text-color'] ||
                'black'}; font-size: 20px; font-weight: 600;`
            "
            >{{ item.grupo }}</span
          >
        </v-row>

        <v-expansion-panels flat>
          <v-expansion-panel v-for="(faq, i) in item.faq" :key="i" class="my-2">
            <v-card
              elevation="0"
              :color="settings['item-color'] || 'grey lighten-2'"
            >
              <v-expansion-panel-header
                ><h1 :style="`color: ${settings['text-color'] || 'black'};`">
                  {{ faq.pergunta }}
                </h1></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row no-gutters>
                  <span
                    class="answer-html"
                    :style="`color: ${settings['text-color'] || 'black'};`"
                    v-html="formatedText(faq.resposta)"
                  ></span>
                </v-row>
              </v-expansion-panel-content>
            </v-card>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>

  <div v-else>
    <v-row justify="center" no-gutters>
      <v-card
        class="mt-4"
        style="background-color: transparent !important"
        width="750px"
        elevation="0"
      >
        <v-expansion-panels
          flat
          multiple
          v-for="(item, i) in formattedFaqs"
          :key="i"
          class="mb-4"
          v-model="item.groupExpandeds"
        >
          <v-expansion-panel>
            <v-card
              elevation="0"
              :color="settings['item-color'] || 'grey lighten-2'"
            >
              <v-expansion-panel-header>
                <h1 :style="`color: ${settings['text-color'] || 'black'};`">
                  {{ item.grupo }}
                </h1>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="expansion-content">
                <v-card elevation="0" color="transparent">
                  <v-expansion-panels flat multiple v-model="item.expandeds">
                    <v-expansion-panel
                      v-for="(faq, index) in item.faq"
                      flat
                      :key="index"
                      class="my-2"
                    >
                      <v-card
                        elevation="0"
                        :color="settings['item-color'] || 'grey lighten-2'"
                        flat
                        style="border-radius: 0px !important;"
                      >
                        <v-expansion-panel-header
                          style="border-radius: 0px !important;"
                        >
                          <h1
                            :style="
                              `color: ${settings['text-color'] || 'black'};`
                            "
                          >
                            {{ faq.pergunta }}
                          </h1>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pb-0 mb-0">
                          <v-row no-gutters>
                            <span
                              class="answer-html"
                              :style="
                                `color: ${settings['text-color'] || 'black'};`
                              "
                              v-html="formatedText(faq.resposta)"
                            ></span>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-card>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-expansion-panel-content>
            </v-card>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["formattedFaqs", "settings"],

  methods: {
    formatedText(item) {
      if (item) {
        let text = item.replaceAll(
          '<span style="color: rgba(0, 0, 0, 0.87);">',
          `<span style="color: ${this.settings["text-color"]}">`
        );

        return text;
      } else return "";
    },
  },
};
</script>

<style>
.answer-html > p {
  margin: 0px !important;
  padding: 0px !important;
}
</style>
