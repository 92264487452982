var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      (_vm.type === 'organization' ? true : false) && !_vm.section[_vm.type].logos.length
    )?_c('b',[_vm._v("Logos")]):_vm._e(),(
      _vm.type !== 'organization'
        ? _vm.type === 'media'
          ? !_vm.section[_vm.type].content
          : _vm.type === 'model'
          ? !_vm.model
          : !_vm.section[_vm.type]
        : !_vm.section[_vm.type].logos.length
    )?_c('div',{staticClass:"cardImage mt-2"},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-card',{staticClass:"cardIcon",attrs:{"color":"#eceeef","elevation":"0"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("la-image")])],1),_c('b',{staticClass:"ml-2",staticStyle:{"font-family":"Inter"}},[_vm._v("Adicionar")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","rounded":"","elevation":"0"},on:{"click":_vm.upload}},[_c('v-icon',[_vm._v("la-plus")])],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","name":"photo","accept":"image/*"},on:{"change":_vm.onFileChanged}})],1)],1):_vm._e(),(
      _vm.type !== 'organization'
        ? _vm.type === 'media'
          ? _vm.section[_vm.type].content
          : _vm.type === 'model'
          ? _vm.model
          : _vm.section[_vm.type]
        : false
    )?_c('div',[_c('v-card',{attrs:{"height":"123","width":"267","elevation":"0"}},[_c('v-card',{staticClass:"cardSelected",attrs:{"elevation":"0"}},[_c('v-img',{attrs:{"height":_vm.type === 'favicon' ? '25' : '82',"width":_vm.type === 'favicon' ? '25' : '265',"contain":_vm.type === 'favicon' ? true : false,"src":_vm.type === 'media'
              ? _vm.section[_vm.type].content
              : _vm.type === 'model'
              ? _vm.model
              : _vm.section[_vm.type]}})],1),_c('v-row',{staticClass:"mt-2 mx-4",attrs:{"no-gutters":"","justify":"space-between"}},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"150px","height":"20px"}},[_vm._v(_vm._s(_vm.nameImage))]),_c('v-icon',{attrs:{"color":"#5e6b7e"},on:{"click":_vm.removeImage}},[_vm._v("la-trash")])],1)],1)],1):_vm._e(),(_vm.type === 'organization' ? true : false)?_c('div',[(
        (_vm.type === 'organization' ? true : false) &&
          _vm.section[_vm.type].logos.length > 0
      )?_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":"","justify":"space-between"}},[_c('b',[_vm._v("Logos")]),_c('v-btn',{staticClass:"mt-n1 small",attrs:{"fab":"","height":"10px","elevation":"0"},on:{"click":_vm.upload}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("la-plus")])],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","name":"photo","accept":"image/*"},on:{"change":_vm.onFileChanged}})],1):_vm._e(),_vm._l((_vm.section[_vm.type].logos),function(logo,i){return _c('v-card',{key:i,staticClass:"mt-2",attrs:{"height":"123","width":"267","elevation":"0"}},[_c('v-card',{staticClass:"cardSelected",attrs:{"elevation":"0","img":logo.image}}),_c('v-row',{staticClass:"mt-2 mx-4",attrs:{"no-gutters":"","justify":"end"}},[_c('v-icon',{attrs:{"color":"#5e6b7e"},on:{"click":function($event){return _vm.removeImage(i)}}},[_vm._v("la-trash")])],1)],1)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }