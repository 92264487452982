var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"card",class:_vm.$route.name === 'BodyEdit' ? 'editCard' : '',staticStyle:{"width":"100vw"},attrs:{"tile":"","color":_vm.settings['background-type'] === 'color'
        ? _vm.settings['background-color']
        : '',"img":_vm.settings['background-type'] === 'image'
        ? _vm.settings['background-image']
        : '',"elevation":"0","min-height":_vm.settings.height ? _vm.settings.height : '408',"src":""},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.$route.name === 'BodyEdit' && _vm.hover)?_c('menu-edit',{attrs:{"sectionId":_vm.settings.id}}):_vm._e(),_c('v-container',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'px-12' : '',staticStyle:{"max-width":"1340px"}},[_c('div',{staticClass:"row"},[(
            _vm.settings.title.content ||
              _vm.settings.text.content ||
              _vm.settings.button.caption
          )?_c('v-col',{class:_vm.settings['text-position'] !== 'left' &&
            _vm.$vuetify.breakpoint.mdAndUp
              ? _vm.settings['text-position'] === 'center'
                ? ''
                : 'pr-12'
              : _vm.settings['text-position'] === 'left' &&
                _vm.$vuetify.breakpoint.mdAndUp
              ? 'pl-12'
              : '',attrs:{"order":_vm.settings['text-position'] !== 'left' ? '1' : '2',"cols":_vm.$vuetify.breakpoint.mdAndUp &&
            _vm.settings['text-position'] !== 'center' &&
            ((_vm.settings.media && _vm.settings.media.content) ||
              (_vm.settings.media && _vm.settings.media.videoid))
              ? '6'
              : '10',"align":"start"}},[_c('v-row',{attrs:{"justify":((_vm.settings.media && _vm.settings.media.content) ||
                (_vm.settings.media && _vm.settings.media.videoid)) &&
              _vm.$vuetify.breakpoint.mdAndUp
                ? 'start'
                : 'center',"no-gutters":""}},[_c('v-col',[_c('h1',{staticClass:"my-5",style:(("font-family: " + (_vm.settings.title.font ? _vm.settings.title.font : '') + "; font-size: " + (_vm.settings.title['font-size']
                      ? _vm.settings.title['font-size']
                      : _vm.infos.layout.title['font-size']) + "; color: " + (_vm.settings.title.color) + "; text-align: " + (_vm.settings.text['text-align'])))},[_vm._v(" "+_vm._s(_vm.settings.title.content)+" ")]),_c('div',{style:(("text-align: " + (_vm.settings.text['text-align']) + ";"))},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatedText)}})]),_c('br'),_c('div',{style:(("text-align: " + (_vm.settings.text['text-align']) + ";"))},[(_vm.settings.button.caption)?_c('v-btn',{staticClass:"mt-5",style:(("background-color: " + (_vm.settings['button-color'] ? _vm.settings['button-color'] : '') + " !important")),attrs:{"href":_vm.settings.button.action &&
                    _vm.settings.button.action.charAt(0) === '#'
                      ? ''
                      : _vm.settings.button.action === 'Abrir Checkout'
                      ? ''
                      : _vm.settings.button.action,"target":"_blank","elevation":"0","height":"44px","loading":_vm.oneCheckoutOpen},on:{"click":_vm.actionButton}},[_c('b',{style:(("color: " + (_vm.settings['text-button-color']
                          ? _vm.settings['text-button-color']
                          : '') + " !important"))},[_vm._v(_vm._s(_vm.settings.button.caption))])]):_vm._e()],1)])],1)],1):_vm._e(),(
            (_vm.settings.media &&
              _vm.settings.media.type === 'image' &&
              _vm.settings.media.content) ||
              (_vm.settings.media &&
                _vm.settings.media.type === 'youtube' &&
                _vm.settings.media.videoid)
          )?_c('v-col',{class:_vm.settings['text-position'] !== 'left' &&
            _vm.$vuetify.breakpoint.mdAndUp
              ? _vm.settings['text-position'] === 'center'
                ? ''
                : 'pl-12'
              : _vm.settings['text-position'] === 'left' &&
                _vm.$vuetify.breakpoint.mdAndUp
              ? 'pr-12'
              : '',attrs:{"order":_vm.settings['text-position'] !== 'left' ? '2' : '1',"cols":_vm.$vuetify.breakpoint.mdAndUp &&
            _vm.settings['text-position'] !== 'center'
              ? '6'
              : '12',"align":"center"}},[_c('v-row',{class:_vm.settings['text-position'] === 'center' ? 'pb-10' : '',attrs:{"justify":"center","no-gutters":""}},[(
                !_vm.settings.title.content &&
                  !_vm.settings.text.content &&
                  !_vm.settings.button.caption
              )?_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[(_vm.settings.media.type === 'image')?_c('v-img',{attrs:{"contain":"","eager":"","max-width":_vm.settings.media.height,"src":_vm.settings.media.content}}):_vm._e()],1):_vm._e(),(
                (_vm.settings.media.type === 'image' && _vm.settings.title.content) ||
                  _vm.settings.text.content ||
                  _vm.settings.button.caption
              )?_c('v-img',{attrs:{"max-width":_vm.settings.media.height,"eager":"","src":_vm.settings.media.content}}):_vm._e(),(_vm.settings.media.type === 'youtube')?_c('iframe',{staticStyle:{"border":"none"},attrs:{"height":_vm.$vuetify.breakpoint.mdAndUp ? '316px' : '100%',"width":_vm.$vuetify.breakpoint.mdAndUp ? '534px' : '100%',"src":_vm.formatYoutube(_vm.settings.media.videoid)}}):_vm._e()],1)],1):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }