<template>
  <div>
    <b
      v-if="
        (type === 'organization' ? true : false) && !section[type].logos.length
      "
      >Logos</b
    >
    <div
      v-if="
        type !== 'organization'
          ? type === 'media'
            ? !section[type].content
            : type === 'model'
            ? !model
            : !section[type]
          : !section[type].logos.length
      "
      class="cardImage mt-2"
    >
      <v-row no-gutters align="center">
        <v-card color="#eceeef" elevation="0" class="cardIcon">
          <v-icon large>la-image</v-icon>
        </v-card>
        <b class="ml-2" style="font-family: Inter">Adicionar</b>
        <v-spacer></v-spacer>
        <v-btn @click="upload" fab rounded elevation="0">
          <v-icon>la-plus</v-icon>
        </v-btn>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          name="photo"
          accept="image/*"
          @change="onFileChanged"
        />
      </v-row>
    </div>
    <div
      v-if="
        type !== 'organization'
          ? type === 'media'
            ? section[type].content
            : type === 'model'
            ? model
            : section[type]
          : false
      "
    >
      <v-card height="123" width="267" elevation="0">
        <v-card elevation="0" class="cardSelected">
          <v-img
            :height="type === 'favicon' ? '25' : '82'"
            :width="type === 'favicon' ? '25' : '265'"
            :contain="type === 'favicon' ? true : false"
            :src="
              type === 'media'
                ? section[type].content
                : type === 'model'
                ? model
                : section[type]
            "
          ></v-img>
        </v-card>

        <v-row class="mt-2 mx-4" no-gutters justify="space-between">
          <span class="text-truncate" style="max-width: 150px; height: 20px">{{
            nameImage
          }}</span>
          <v-icon color="#5e6b7e" @click="removeImage">la-trash</v-icon>
        </v-row>
      </v-card>
    </div>
    <div v-if="type === 'organization' ? true : false">
      <v-row
        v-if="
          (type === 'organization' ? true : false) &&
            section[type].logos.length > 0
        "
        class="mb-3"
        no-gutters
        justify="space-between"
      >
        <b>Logos</b>
        <v-btn
          @click="upload"
          class="mt-n1 small"
          fab
          height="10px"
          elevation="0"
          ><v-icon small>la-plus</v-icon></v-btn
        >
        <input
          ref="uploader"
          class="d-none"
          type="file"
          name="photo"
          accept="image/*"
          @change="onFileChanged"
        />
      </v-row>
      <v-card
        v-for="(logo, i) in section[type].logos"
        :key="i"
        class="mt-2"
        height="123"
        width="267"
        elevation="0"
      >
        <v-card elevation="0" :img="logo.image" class="cardSelected"></v-card>
        <v-row class="mt-2 mx-4" no-gutters justify="end">
          <v-icon color="#5e6b7e" @click="removeImage(i)">la-trash</v-icon>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    section: { type: Object },
    type: { type: String },
    model: { type: String },
  },

  data() {
    return {
      selectedFile: null,
      imageSrc: null,
      nameImage: "",
    };
  },

  computed: {
    ...mapGetters(["infos", "image", "saveConfig"]),
  },

  methods: {
    upload() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },

    removeImage(i) {
      if (this.type === "model") this.$emit("removeMedia");
      else if (this.type === "organization")
        this.section[this.type].logos.splice(i, 1);
      else if (this.type === "media") this.section[this.type].content = "";
      else this.section[this.type] = "";
    },

    async onFileChanged() {
      var file = document.querySelector("input[type=file]").files[0];
      const formData = new FormData();
      formData.append("media", file);
      formData.append("hotsiteId", this.infos["organizer-id"]);
      await this.$store.dispatch("setImage", {
        formData: formData,
        token: this.$route.params.token,
      });

      if (this.type === "model") this.$emit("updateMedia", this.image.url);
      else if (this.type === "organization")
        this.section[this.type].logos.push({ image: this.image.url });
      else if (this.type === "media")
        this.section[this.type].content = this.image.url;
      else this.section[this.type] = this.image.url;
      if (this.type === "favicon") this.saveConfig();
    },
  },
};
</script>

<style scoped>
.cardImage {
  width: 267px;
  height: 64px;
  background-color: #ffffff;
  border: 1px solid #c8ccd3;
  box-sizing: border-box;
  border-radius: 4px;
}

.cardSelected {
  height: 82px;
  width: 265.9886474609375px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardIcon {
  width: 55px;
  height: 56px;
  color: #eceeef;
  border-radius: 4px;
  margin-top: 3px;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

span {
  height: 15px;
  width: 151px;
  left: 8px;
  top: 95px;
  color: #5e6b7e;
}

b {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #192b46;
}

.v-btn {
  width: 44px !important;
  height: 44px !important;
  border-radius: 30px !important;
  background-color: #1976d2 !important;
  margin-top: 3px;
  margin-right: 4px;
  color: white !important;
}

.small {
  width: 30px !important;
  height: 30px !important;
  border-radius: 30px !important;
  background-color: #1976d2 !important;
  margin-top: 3px;
  margin-right: 4px;
  color: white !important;
}
</style>
