export const event = (state) => {
  return state.event
}

export const oneCheckoutOpen = (state) => {
  return state.oneCheckoutOpen
}

export const eventPlaces = (state) => {
  return state.eventPlaces
}