<template>
  <div>
    <v-card
      tile
      elevation="0"
      :min-height="settings.height ? settings.height : '700px'"
      :color="
        settings['background-type'] === 'color'
          ? settings['background-color']
          : ''
      "
      :img="
        settings['background-type'] === 'image'
          ? settings['background-image']
          : ''
      "
      :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <menu-edit
        :sectionId="settings.id"
        v-if="$route.name === 'BodyEdit' && hover"
      />
      <title-component
        v-if="alignCenter || !$vuetify.breakpoint.mdAndUp"
        :settings="settingsTitle"
      />
      <v-container style="max-width: 1340px">
        <v-row
          v-if="!$vuetify.breakpoint.mdAndUp || alignCenter"
          justify="center"
          :class="$vuetify.breakpoint.mdAndUp ? 'pb-6' : 'pb-6 px-6'"
          :style="$vuetify.breakpoint.mdAndUp ? 'padding: 0px 150px' : ''"
        >
          <span
            :style="
              `font-family: ${
                settings.text.font ? settings.text.font : ''
              }; font-size: ${
                settings.text['font-size']
                  ? settings.text['font-size']
                  : infos.layout.text['font-size']
              }; color: ${settings.text.color}`
            "
            v-html="settings.text.content"
          ></span>
        </v-row>
        <v-row
          :class="
            $vuetify.breakpoint.mdAndUp ? (alignCenter ? 'pa-2' : 'pa-12') : ''
          "
          justify="center"
          no-gutters
        >
          <v-col
            v-if="!alignCenter"
            :class="$vuetify.breakpoint.mdAndUp ? 'pl-12' : ''"
            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
          >
            <v-row v-if="$vuetify.breakpoint.mdAndUp" justify="start">
              <h1
                :style="
                  `font-family: ${
                    settings.title.font ? settings.title.font : ''
                  }; font-size: ${
                    settings.title['font-size']
                      ? settings.title['font-size']
                      : infos.layout.title['font-size']
                  }; color: ${settings.title.color}`
                "
              >
                {{ settings.title.content }}
              </h1>
            </v-row>
            <v-row
              :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
              :class="
                $vuetify.breakpoint.mdAndUp ? 'mt-10 pr-12' : 'pb-12 px-8'
              "
            >
              <span
                :style="
                  `font-family: ${
                    settings.text.font ? settings.text.font : ''
                  }; font-size: ${
                    settings.text['font-size']
                      ? settings.text['font-size']
                      : infos.layout.text['font-size']
                  }; color: ${settings.text.color}`
                "
                v-html="settings.text.content"
              ></span>
            </v-row>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
            <v-card
              :color="settings['layer-color']"
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'pa-12 rounded-xl'
                  : 'pa-7 py-10 rounded-xl'
              "
              elevation="0"
            >
              <v-form ref="form">
                <v-row
                  v-for="(item, i) in settings.items"
                  :key="i"
                  justify="center"
                  align="center"
                >
                  <div v-if="item.type === 'text'" style="width: 100%">
                    <span
                      class="item-title mb-4"
                      :style="`color: ${settings['text-color']}`"
                      >{{ item.caption }}</span
                    >
                    <v-text-field
                      outlined
                      :rules="[rules.required]"
                      color="grey"
                      background-color="white"
                      v-model="name"
                    ></v-text-field>
                  </div>
                  <div v-if="item.type === 'email'" style="width: 100%">
                    <span
                      class="item-title mb-4"
                      :style="`color: ${settings['text-color']}`"
                      >{{ item.caption }}</span
                    >
                    <v-text-field
                      :rules="[rules.email, rules.required]"
                      outlined
                      color="grey"
                      background-color="white"
                      v-model="email"
                    ></v-text-field>
                  </div>
                  <div v-if="item.type === 'phone'" style="width: 100%">
                    <span
                      class="item-title mb-4"
                      :style="`color: ${settings['text-color']}`"
                      >{{ item.caption }}</span
                    >
                    <v-text-field
                      outlined
                      color="grey"
                      :rules="[rules.required]"
                      background-color="white"
                      v-mask="phone.length <= 14 ? maskPhone : maskCellPhone"
                      v-model="phone"
                    ></v-text-field>
                  </div>
                  <v-btn
                    elevation="0"
                    dark
                    :style="
                      `background-color: ${
                        settings['button-color']
                          ? `${settings['button-color']} !important`
                          : ''
                      }`
                    "
                    :loading="loading"
                    v-if="item.type === 'submit'"
                    width="100%"
                    @click="submitContact"
                    ><b
                      :style="
                        `color: ${
                          settings['text-button-color']
                            ? settings['text-button-color']
                            : ''
                        } !important`
                      "
                      >Cadastrar</b
                    ></v-btn
                  >
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import TitleComponent from "../title/TitleComponent.vue";
import MenuEdit from "../edit/MenuEdit";
export default {
  components: { TitleComponent, MenuEdit },
  directives: {
    mask,
  },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      maskPhone: "(##) ####-####",
      maskCellPhone: "(##) #####-####",
      settingsTitle: null,
      dialogMessage: false,
      name: "",
      message: "",
      hover: false,
      email: "",
      phone: "",
      loading: false,
      rules: {
        required: (value) => !!value || "Este campo é obrigatório.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "E-mail Inválido.";
        },
      },
    };
  },

  computed: {
    ...mapGetters(["infos"]),
    alignCenter() {
      if (this.settings["text-position"] === "center") return true;
      else false;
    },
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },

  methods: {
    async submitContact() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        await this.$store
          .dispatch("registerCampaign", {
            event: this.infos["event-id"],
            name: this.name,
            email: this.email,
            phone: this.phone,
            token: this.$route?.query?.c
              ? this.$route?.query?.c
              : this.settings.token,
          })
          .then((resp) => {
            this.loading = false;
            this.clearForm();
            this.$refs.form.resetValidation();
          })
          .catch((e) => {
            this.loading = false;
            this.clearForm();
            this.$refs.form.resetValidation();
          });
        this.clearForm();
        this.$refs.form.resetValidation();
        this.loading = false;
      } else {
        this.$store.commit("SET_SNACKBAR", {
          open: true,
          message: "Preencha todos os campos.",
          color: "red",
        });
        this.loading = false;
      }
    },

    closeDialog() {
      this.dialogMessage = false;
    },

    clearForm() {
      this.name = "";
      this.email = "";
      this.phone = "";
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
}

span {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.v-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
}

.item-title {
  font-size: 16px;
}
</style>
