<template>
  <div>
    <v-card
      tile
      elevation="0"
      :min-height="settings.height ? settings.height : '700px'"
      :color="
        settings['background-type'] === 'color'
          ? settings['background-color']
          : ''
      "
      :img="
        settings['background-type'] === 'image'
          ? settings['background-image']
          : ''
      "
      :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <menu-edit :sectionId="settings.id" v-if="$route.name === 'BodyEdit' && hover" />
      <title-component
        v-if="alignCenter || !$vuetify.breakpoint.mdAndUp"
        :settings="settingsTitle"
      />
      <v-container style="max-width: 1340px">
        <v-row class="pa-12" justify="center" no-gutters>
          <v-col v-if="!alignCenter && $vuetify.breakpoint.mdAndUp" class="pl-12" cols="6">
            <v-row justify="start">
              <h1
                :style="`font-family: ${
                  settings.title.font ? settings.title.font : ''
                }; font-size: ${
                  settings.title['font-size']
                    ? settings.title['font-size']
                    : infos.layout.title['font-size']
                }; color: ${settings.title.color}`"
              >{{ settings.title.content }}</h1>
            </v-row>
            <v-row justify="start" class="mt-10 pr-12">
              <span
                :style="`font-family: ${
                  settings.text.font ? settings.text.font : ''
                }; font-size: ${
                  settings.text['font-size']
                    ? settings.text['font-size']
                    : infos.layout.text['font-size']
                }; color: ${settings.text.color}`"
                v-html="settings.text.content"
              ></span>
            </v-row>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
            <v-card :color="settings['layer-color']" class="pa-12 rounded-xl" elevation="0">
              <v-row v-for="(item, i) in settings.items" :key="i" justify="center" align="center">
                <div v-if="item.type === 'text'" style="width: 100%">
                  <span
                    class="item-title mb-4"
                    :style="`color: ${settings['text-color']}`"
                  >{{item.caption}}</span>
                  <v-text-field outlined color="grey" background-color="white" v-model="name"></v-text-field>
                </div>
                <div v-if="item.type === 'email'" style="width: 100%">
                  <span
                    class="item-title mb-4"
                    :style="`color: ${settings['text-color']}`"
                  >{{item.caption}}</span>
                  <v-text-field
                    :rules="[rules.email]"
                    outlined
                    color="grey"
                    background-color="white"
                    v-model="email"
                  ></v-text-field>
                </div>
                <div v-if="item.type === 'phone'" style="width: 100%">
                  <span
                    class="item-title mb-4"
                    :style="`color: ${settings['text-color']}`"
                  >{{item.caption}}</span>
                  <v-text-field
                    outlined
                    color="grey"
                    background-color="white"
                    v-mask="phone.length <= 14 ? maskPhone : maskCellPhone"
                    v-model="phone"
                  ></v-text-field>
                </div>
                <div v-if="item.type === 'long-text'" style="width: 100%">
                  <span
                    class="item-title mb-4"
                    :style="`color: ${settings['text-color']}`"
                  >{{item.caption}}</span>
                  <v-textarea
                    outlined
                    auto-grow
                    color="grey"
                    background-color="white"
                    v-model="message"
                  ></v-textarea>
                </div>
                <v-btn
                  elevation="0"
                  dark
                  :style="`background-color: ${settings['button-color']? `${settings['button-color']} !important` : ''}`"
                  v-if="item.type === 'submit'"
                  width="100%"
                  @click="submitContact"
                ><b :style="`color: ${settings['text-button-color'] ?settings['text-button-color']  : '' } !important`">{{ item.caption }}</b></v-btn>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-model="dialogMessage" width="600px" persistent>
      <dialog-message v-if="dialogMessage" @close="closeDialog" />
    </v-dialog>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import TitleComponent from "../title/TitleComponent.vue";
import DialogMessage from "./DialogMessage";
import MenuEdit from "../edit/MenuEdit";
export default {
  components: { TitleComponent, DialogMessage, MenuEdit },
  directives: {
    mask,
  },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      maskPhone: "(##) ####-####",
      maskCellPhone: "(##) #####-####",
      settingsTitle: null,
      dialogMessage: false,
      name: "",
      message: "",
      hover: false,
      email: "",
      phone: "",

      rules: {
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "E-mail Inválido.";
        },
      },
    };
  },

  computed: {
    ...mapGetters(["infos"]),
    alignCenter() {
      if (this.settings["text-position"] === "center") return true;
      else false;
    },
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },

  methods: {
    async submitContact() {
      await this.$store.dispatch("postMessage", {
        id: this.infos["organizer-id"],
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message,
      });
      this.dialogMessage = true;
      this.clearForm();
    },

    closeDialog() {
      this.dialogMessage = false;
    },

    clearForm() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.message = "";
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
}

span {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.v-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
}

.item-title {
  font-size: 16px;
}
</style>