var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pb-4",class:_vm.$route.name === 'BodyEdit' ? 'editCard' : '',attrs:{"tile":"","elevation":"0","min-height":_vm.settings.height ? _vm.settings.height : '500px',"color":_vm.settings['background-type'] === 'color'
      ? _vm.settings['background-color']
      : '',"img":_vm.settings['background-type'] === 'image'
      ? _vm.settings['background-image']
      : ''},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.$route.name === 'BodyEdit' && _vm.hover)?_c('menu-edit',{attrs:{"sectionId":_vm.settings.id}}):_vm._e(),_c('title-component',{attrs:{"settings":_vm.settingsTitle}}),_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows":(_vm.$vuetify.breakpoint.mdAndUp &&
        _vm.materialForCarousel &&
        _vm.materialForCarousel.length &&
        _vm.materialForCarousel.length > 1) ||
        (!_vm.$vuetify.breakpoint.mdAndUp &&
          _vm.materialForCarousel &&
          _vm.materialForCarousel.length &&
          _vm.materialForCarousel.length > 1),"cycle":!_vm.$vuetify.breakpoint.mdAndUp}},_vm._l((_vm.materialForCarousel),function(carousel,index){return _c('v-carousel-item',{key:index},[_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":"","justify":"space-around","align":"center"}},_vm._l((_vm.materialForCarousel[index]),function(item,i){return _c('v-col',{key:i,staticClass:"px-6",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? '4' : '12',"open":"","align":"center"}},[_c('v-card',{staticStyle:{"border-radius":"10px"},style:(item.link ? 'cursor: pointer' : ''),attrs:{"min-height":"400","max-width":!_vm.$vuetify.breakpoint.mdAndUp ? 270 : 300,"color":_vm.settings['item-color'] || '#fff',"flat":""},on:{"click":function($event){return _vm.handleOpenNewPage(item)}}},[_c('v-img',{staticStyle:{"border-radius":"10px"},attrs:{"max-height":"150","eager":"","src":item.media}}),_c('v-row',{staticClass:"px-6 pt-6",attrs:{"justify":"start","no-gutters":""}},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('v-row',{staticClass:"px-6 pt-4",attrs:{"justify":"start","no-gutters":""}},[_c('b',[_vm._v(_vm._s(item.description))])])],1)],1)}),1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }