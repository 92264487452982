<template>
  <v-app>
    <v-dialog v-model="dialogSection.open" width="700">
      <add-section v-if="dialogSection.open" />
    </v-dialog>
    <v-main>
      <router-view />
    </v-main>
    <one-checkout
      v-if="infos && String(infos['event-id'])"
      ref="oneCheckout"
      :evento="String(infos['event-id'])"
    />
    <v-overlay :value="loading" color="white">
      <v-progress-circular
        indeterminate
        size="65"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <img
      v-if="infos && idFacebook"
      height="1"
      width="1"
      style="display:none"
      :src="
        `https://www.facebook.com/tr?id=${idFacebook}&ev=PageView&noscript=1`
      "
    />
    <v-snackbar
      :timeout="3000"
      width="100"
      :color="snackbar.color"
      v-model="snackbar.open"
    >
      <v-row justify="center" no-gutters>
        <h4>{{ snackbar.message }}</h4>
      </v-row>
    </v-snackbar>
    <v-footer
      v-if="!cookiesAccepted && $route.name !== 'BodyEdit'"
      fixed
      color="rgba(25, 43, 70, 0.85)"
      :height="$vuetify.breakpoint.mdAndUp ? '100px' : '250px'"
      class="px-16"
    >
      <v-row no-gutters align="center" justify="center">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'">
          <span style="font-size: 14px !important" class="white--text"
            >Utilizamos cookies para oferecer melhor experiência, melhorar o
            desempenho, analisar como você interage em nosso site e personalizar
            conteúdo. Ao utilizar este site, você concorda com o uso de
            cookies.</span
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-2'"
          align="center"
          :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        >
          <v-btn rounded depressed color="primary" @click="acceptCookies">
            OK
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import AddSection from "./components/edit/dialogs/AddSection";
import { mapGetters } from "vuex";
import OneCheckout from "./components/one-checkout/one-checkout.vue";
import axios from "axios";

export default {
  components: { OneCheckout, AddSection },
  name: "App",

  data() {
    return {
      idFacebook: "",
      cookiesAccepted: true,
      intervalToGetVisits: null,
    };
  },

  computed: {
    ...mapGetters(["infos", "dialogSection", "hotsite", "loading", "snackbar"]),
  },

  created() {
    this.getHotsite();
    this.getSaveConfig();
  },

  mounted() {
    if (this.$route.name !== "BodyEdit") this.cookiesVerification();
  },

  destroyed() {
    clearInterval(this.intervalToGetVisits);
  },

  methods: {
    getHt() {
      const uuidv1 = require("uuid/v1");
      const htsetted = localStorage.getItem("ht");
      if (!htsetted) localStorage.setItem("ht", uuidv1().substring(0, 6));
    },

    visits(timer) {
      const ht = localStorage.getItem("ht");
      this.$store.dispatch("handleAnalytics", {
        event: this.infos["event-id"],
        ht: ht,
        url: timer ? document.URL + `!time=${timer}` : document.URL,
        device: window.navigator.userAgent,
        referer: document.referrer,
      });
    },

    startTimerToGetVisits() {
      let timer = 0;

      this.intervalToGetVisits = setInterval(() => {
        timer++;

        if (timer === 15) this.visits(timer);
        if (timer === 30) this.visits(timer);
        if (timer === 60) this.visits(timer);
      }, 1000);
    },

    cookiesVerification() {
      if (!this.$cookies.get("cookies-accepted")) {
        this.cookiesAccepted = false;
      }
    },
    acceptCookies() {
      this.$cookies.set("cookies-accepted", true, {
        path: "/",
        expires: 0,
      });
      this.cookiesAccepted = true;
    },

    async getHotsite() {
      let domain = "";
      localStorage.getItem("currentUserToken");
      if (
        document.URL.search("localhost") !== -1 ||
        document.URL.search("blueticket.com.br") !== -1 ||
        document.URL.search("btservers.com.br") !== -1 ||
        document.URL.search("cloudfront.net") !== -1
      )
        domain = null;
      else domain = document.URL.split("/")[2];
      await this.$store.dispatch("getHotsite", {
        slug: this.$route.params.slug,
        version: this.$route.params.version,
        domain: domain,
      });
      let version = null;

      if (
        this.hotsite.versao_publicada &&
        this.hotsite.versao_publicada.configuracoes &&
        this.$route.params.version === undefined
      )
        version = this.hotsite.versao_publicada.configuracoes;
      if (
        this.hotsite.versao_especifica &&
        this.hotsite.versao_especifica.configuracoes &&
        this.$route.params.version !== undefined
      )
        version = this.hotsite.versao_especifica.configuracoes;
      await this.$store.dispatch("setInfos", JSON.parse(version));
      this.getHt();
      this.getInfos();
      this.getTickets();
      this.visits();
      this.startTimerToGetVisits();
    },

    getTickets() {
      this.$store.dispatch("getTickets", this.infos["event-id"]);
    },

    getSaveConfig() {
      this.$store.dispatch("setSaveConfig", this.getInfos.bind(this));
    },

    getIdFacebook() {
      if (this.infos)
        this.idFacebook = this.infos.integrations["facebook-pixel"];
    },

    getInfos() {
      if (this.infos) {
        if (!this.$route.path.includes("/edit/")) this.getIntegrations();
        document.title = this.infos.title;
        document.getElementById("favicon").href = this.infos.favicon;
        let bodyStyle = document.body.style,
          defaultFont = this.infos.layout.default.font,
          defaultColor = this.infos.layout.default.color;

        bodyStyle.setProperty(
          "--font-family-text",
          this.infos.layout.text.font
            ? this.infos.layout.text.font
            : defaultFont
        );
        bodyStyle.setProperty(
          "--font-family-b",
          this.infos.layout.subtitle.font
            ? this.infos.layout.subtitle.font
            : defaultFont
        );
        bodyStyle.setProperty(
          "--font-family-h1",
          this.infos.layout.title.font
            ? this.infos.layout.title.font
            : defaultFont
        );

        bodyStyle.setProperty(
          "--color-text",
          this.infos.layout.text.color
            ? this.infos.layout.text.color
            : defaultColor
        );
        bodyStyle.setProperty(
          "--color-b",
          this.infos.layout.subtitle.color
            ? this.infos.layout.subtitle.color
            : defaultColor
        );
        bodyStyle.setProperty(
          "--color-h1",
          this.infos.layout.title.color
            ? this.infos.layout.title.color
            : defaultColor
        );

        bodyStyle.setProperty(
          "--rounded-radius",
          this.infos.layout.button["border-radius"]
        );
        bodyStyle.setProperty(
          "--background-color-button",
          this.infos.layout.button["background-color"]
        );
        if (this.infos.layout.button["font"])
          bodyStyle.setProperty(
            "--background-color-button",
            this.infos.layout.button["font"]
          );
        bodyStyle.setProperty(
          "--color-button",
          this.infos.layout.button["color"]
        );
        bodyStyle.setProperty("--font-family-default", defaultFont);
        this.$store.dispatch("getSpeakers", this.infos["event-id"]);
        this.$store.dispatch("getProgram", this.infos["event-id"]);
        this.$store.dispatch("getProducts", this.infos["event-id"]);
        this.$store.dispatch("getLogos", this.infos["event-id"]);
        this.$store.dispatch("getSponsors", this.infos["event-id"]);
        this.$store.dispatch("getFaq", this.infos["event-id"]);
        if (this.$route.name === "BodyEdit")
          this.$store.dispatch("getCampaigns", {
            event: this.infos["event-id"],
            token: this.$route.params.token,
          });
      }
    },

    getIntegrations() {
      this.idFacebook = "";
      let facebookPixel = this.infos.integrations["facebook-pixel"];
      let googleTag = this.infos.integrations["google-tag-manager"];
      let googleAnalytics = this.infos.integrations["google-analytics"];
      this.getIdFacebook();
      document.getElementById("facebook").innerHTML = !(function(
        f,
        b,
        e,
        v,
        n,
        t,
        s
      ) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", facebookPixel);
      document.getElementById("google-tag").innerHTML = (function(
        w,
        d,
        s,
        l,
        i
      ) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", googleTag);
      document.getElementById("google-analytics").innerHTML = (function(
        i,
        s,
        o,
        g,
        r,
        a,
        m
      ) {
        i["GoogleAnalyticsObject"] = r;
        (i[r] =
          i[r] ||
          function() {
            (i[r].q = i[r].q || []).push(arguments);
          }),
          (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(
        window,
        document,
        "script",
        "https://www.google-analytics.com/analytics.js",
        "ga"
      );

      ga("create", googleAnalytics, "auto");
      ga("send", "pageview");
    },
  },
};
</script>

<style>
:root {
  --font-family-h1: "";
  --font-family-b: "";
  --font-family-text: "";
  --color-h1: "black";
  --color-b: "black";
  --color-text: "black";

  --rounded-radius: 5px;
  --background-color-button: white;
  --color-button: black;

  --font-family-button: "";
  --font-family-default: Inter;
}

* {
  margin: 0;
  font-family: var(--font-family-default), sans-serif;
}

.v-btn {
  background-color: var(--background-color-button) !important;
  color: var(--color-button) !important;
  border-radius: var(--rounded-radius) !important;
  font-family: var(--font-family-button) !important;
}

h1 {
  font-size: var(--font-size-h1);
  font-family: var(--font-family-h1);
  color: var(--color-h1);
}

b {
  font-family: var(--font-family-b);
  color: var(--color-b);
}

span {
  font-family: var(--font-family-text);
  color: var(--color-text);
}

.pointer {
  cursor: pointer;
}

.editCard:hover {
  border: 3px solid #0067af !important;
}

.rowEdit {
  z-index: 1 !important;
  position: absolute !important;
  left: 50% !important;
  transform: translate(-50%) !important;
  display: unset !important;
  flex-direction: none !important;
  margin: 0;
}

.cardMenu {
  border: 1px solid #1976d2;
  background: #e3eef9;
  height: 48px;
  width: 226px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btnEdit {
  background-color: transparent !important;
  margin: 0px 3px !important;
  width: 38px !important;
  color: white !important;
}

.v-text-field label,
.v-text-field input {
  font-family: Inter, sans-serif !important;
}

.v-textarea label,
.v-textarea input {
  font-family: Inter, sans-serif !important;
}
</style>
