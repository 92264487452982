<template>
  <div v-if="infos && infos.sections">
    <app-bar-edit v-if="$route.name === 'BodyEdit'" class="appBarEdit" />
    <nav-bar-edit v-if="navbar.open" />

    <div v-for="(item, i) in infos.sections" :key="i">
      <app-bar
        v-if="item.type === 'menu'"
        class="appBar"
        @handleScrollTo="handleScrollTo"
      />

      <cover
        v-if="item.type === 'cover'"
        class="elevate"
        :id="item.id"
        :settings="item"
        @handleScrollTo="handleScrollTo"
      />

      <title-component v-if="item.type === 'title'" :settings="item" />

      <about v-if="item.type === 'content'" :id="item.id" :settings="item" />

      <speakers
        v-if="item.type === 'speakers'"
        :id="item.id"
        :settings="item"
      />

      <materials
        v-if="item.type === 'materials'"
        :id="item.id"
        :settings="item"
      />

      <Campaign
        v-if="item.type === 'campaign'"
        :id="item.id"
        :settings="item"
      />

      <sponsors
        v-if="item.type === 'sponsors'"
        :id="item.id"
        :settings="item"
      />

      <logos v-if="item.type === 'logos'" :id="item.id" :settings="item" />

      <gallery v-if="item.type === 'gallery'" :id="item.id" :settings="item" />

      <numbers v-if="item.type === 'numbers'" :id="item.id" :settings="item" />

      <lecture v-if="item.type === 'schedule'" :id="item.id" :settings="item" />

      <tickets v-if="item.type === 'tickets'" :id="item.id" :settings="item" />

      <testimonials
        v-if="item.type === 'testimonials'"
        :id="item.id"
        :settings="item"
      />

      <newsletter
        v-if="item.type === 'newsletter'"
        :id="item.id"
        :settings="item"
      />

      <personalized-faq
        v-if="item.type === 'personalizedFaq'"
        :id="item.id"
        :settings="item"
      />

      <contact v-if="item.type === 'contact'" :id="item.id" :settings="item" />

      <footerComponent
        v-if="item.type === 'footer'"
        :id="item.id"
        :settings="item"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppBarEdit from "../components/edit/AppBarEdit";
import About from "../components/about/About.vue";
import Cover from "../components/cover/Cover";
import Speakers from "../components/speakers/Speakers.vue";
import TitleComponent from "../components/title/TitleComponent.vue";
import Lecture from "../components/lecture/Lecture";
import Tickets from "../components/tickets/Tickets.vue";
import Gallery from "../components/gallery/Gallery.vue";
import Logos from "../components/logos/Logos.vue";
import Faq from "../components/faq/Faq.vue";
import Numbers from "../components/numbers/Numbers.vue";
import Contact from "../components/contact/Contact.vue";
import Newsletter from "../components/newsletter/Newsletter.vue";
import Testimonials from "../components/testimonials/Testimonials.vue";
import FooterComponent from "../components/footer/Footer.vue";
import AppBar from "../components/appBar/AppBar.vue";
import NavBarEdit from "../components/edit/NavBarEdit.vue";
import Campaign from "../components/campaign/Campaign.vue";
import Materials from "../components/materials/Materials.vue";
import Sponsors from "../components/sponsors/Sponsors.vue";
import PersonalizedFaq from "../components/faq/personalizedFaq.vue";

export default {
  name: "Body",
  components: {
    Cover,
    TitleComponent,
    About,
    Speakers,
    Lecture,
    Tickets,
    Gallery,
    Logos,
    Faq,
    Numbers,
    Contact,
    Newsletter,
    Testimonials,
    FooterComponent,
    AppBar,
    NavBarEdit,
    AppBarEdit,
    Campaign,
    Materials,
    Sponsors,
    PersonalizedFaq,
  },

  computed: {
    ...mapGetters(["infos", "navbar"]),
  },

  watch: {
    infos() {
      this.infos.sections = this.infos.sections.map((section) => {
        let sectionObj = {
          ...section,
          button: section.button
            ? section.button
            : section.type === "speakers"
            ? {
                caption: "Ver mais",
                action: "",
                "background-color": "",
                color: "",
              }
            : null,
          "max-speakers": section["max-speakers"]
            ? section["max-speakers"]
            : "24",
          "button-color": section["button-color"]
            ? section["button-color"]
            : null,
          "text-button-color": section["text-button-color"]
            ? section["text-button-color"]
            : null,
          "text-color": section["text-color"] ? section["text-color"] : null,
          "layer-color": section["layer-color"] ? section["layer-color"] : null,
          typeShow: section.typeShow ? section.typeShow : null,
          text: section["text"]
            ? section["text"]
            : {
                content: "",
                font: "",
                "font-size": "",
                color: "",
                "text-align": "",
              },
        };

        if (section.type === "speakers") {
          if (!sectionObj.shadow) sectionObj.shadow = "";
          if (!sectionObj.distance) sectionObj.distance = "7";
        }

        return sectionObj;
      });
    },
  },

  created() {
    if (this.$route.name === "BodyEdit")
      this.$store.dispatch("setNav", { open: true, sectionId: "config" });
  },

  methods: {
    handleScrollTo(element) {
      document.getElementById(element.split("#")[1]).scrollIntoView(false);
    },
  },
};
</script>

<style scoped>
.appBar {
  z-index: 2;
}
.appBarEdit {
  z-index: 3;
}
.elevate {
  z-index: 0;
}
</style>
