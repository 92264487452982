<template>
  <div>
    <div class="box">
      <v-row class="mb-4 pt-4 pl-2" justify="start" no-gutters>
        <b v-if="!section.items.length">Nenhuma Imagem Adicionada</b>
        <v-col v-else v-for="(item, i) in section.items" :key="i" cols="2">
          <v-card color="transparent" class="mb-3" elevation="0">
            <v-img height="48" width="48" contain :src="item" eager></v-img>
            <!-- <v-row class="mt-1" justify="center" no-gutters>
              <v-icon @click="remove(i)" color="#5e6b7e">la-trash</v-icon>
            </v-row> -->
          </v-card>
        </v-col>
      </v-row>

      <!-- <v-divider></v-divider> -->
    </div>
    <v-dialog width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          elevation="0"
          class="buttonAdd"
          height="37"
          width="100%"
        >
          <v-row justify="center" no-gutters>
            <v-icon>la-plus</v-icon>
          </v-row>
        </v-btn>
      </template>
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="white" elevation="0">
            <v-row justify="space-between" no-gutters>
              <h1 class="mt-2">Adicionar Imagem</h1>
              <v-btn
                v-if="images.length"
                class="buttonDialogUpload"
                @click="upload"
                outlined
                >Selecionar imagens do dispositivo</v-btn
              >
              <input
                v-if="images.length"
                ref="uploader"
                class="d-none"
                type="file"
                name="photo"
                accept="image/*"
                @change="onFileChanged"
              />
            </v-row>
          </v-toolbar>
          <v-card-text :class="images.length ? 'cardText' : 'cardText display'">
            <v-row
              justify="start"
              align="start"
              v-if="images.length"
              no-gutters
            >
              <v-col
                class="mt-2 mr-8"
                v-for="(item, i) in images"
                :key="i"
                cols="2"
              >
                <v-card elevation="0" height="150" width="150">
                  <v-img
                    class="mt-2"
                    height="120"
                    contain
                    :src="item"
                    eager
                  ></v-img>
                  <v-row class="mt-1" justify="center" no-gutters>
                    <v-icon @click="remove(i)" color="#5e6b7e">la-trash</v-icon>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-btn
              v-if="!images.length"
              class="buttonDialogUpload my-12"
              @click="upload"
              outlined
              >Selecionar imagens do dispositivo</v-btn
            >
            <input
              v-if="!images.length"
              ref="uploader"
              class="d-none"
              type="file"
              name="photo"
              accept="image/*"
              @change="onFileChanged"
            />
          </v-card-text>
          <v-card-actions class="justify-end py-5">
            <v-btn class="buttonDialogClose" outlined @click="cancel(dialog)"
              >Cancelar</v-btn
            >
            <v-btn class="buttonDialogAdd" outlined @click="addImages(dialog)"
              >Adicionar</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    section: {
      type: Object,
    },
  },
  data() {
    return {
      images: [],
    };
  },

  computed: {
    ...mapGetters(["infos", "image"]),
  },

  methods: {
    upload() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },

    remove(i) {
      this.images.splice(i, 1);
    },

    cancel(dialog) {
      dialog.value = false;
      this.images = [];
    },

    addImages(dialog) {
      this.section.items = this.images;
      this.cancel(dialog);
    },

    async onFileChanged() {
      var file = document.querySelector("input[type=file]").files[0];
      const formData = new FormData();
      formData.append("media", file);
      formData.append("hotsiteId", this.infos["organizer-id"]);
      await this.$store.dispatch("setImage", {
        formData: formData,
        token: this.$route.params.token,
      });
      this.images.push(this.image.url);
    },
  },
};
</script>

<style scoped>
.box {
  border: 1px solid #c8ccd3;
  box-sizing: border-box;
  border-radius: 4px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

b {
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #5e6b7e;
}

h1 {
  color: #192b46;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.buttonDialogUpload {
  text-transform: none;
  background-color: #0067af !important;
  height: 40px !important;
  width: 330px !important;
  border-radius: 56px !important;
  padding: 11px, 32px, 11px, 32px !important;
}

.buttonDialogAdd {
  text-transform: none;
  background-color: #0067af !important;
  height: 40px !important;
  width: 128px !important;
  border-radius: 56px !important;
  padding: 11px, 32px, 11px, 32px !important;
}

.buttonDialogClose {
  text-transform: none;
  color: #0067af !important;
  background-color: transparent !important;
  height: 40px !important;
  width: 128px !important;
  border-radius: 56px !important;
  padding: 11px, 32px, 11px, 32px !important;
}

.buttonAdd {
  border: 2px solid #c8ccd3 !important;
  background-color: transparent !important;
  border-radius: 4px !important;
  border-top: none !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.v-icon {
  color: #192b46 !important;
}

.cardText {
  background-color: #f1f2f3;
}

.display {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
