import axios from "axios";

export const setOneCheckoutOpen = ({ commit }, value) => {
  commit("SET_ONE_CHECKOUT_OPEN", value);
};

export const handleAnalytics = ({ commit }, params) => {
  return axios.post(`analytics/visits`, {
    codigo_evento: params.event,
    terminal: params.ht,
    url_pagina: params.url,
    tipo_device: params.device,
    origem_referrer: params.referer,
  });
};

export const getGlobalPlaces = async ({ commit }, query) => {
  try {
    const response = await axios.get(`v2/city/search?query=${query}`);
    let results = [];
    if (response && response.data) {
      response.data.forEach((l) => {
        let result = {
          description: `${l.name} - ${l.state} - Brasil`,
        };
        results.push(result);
        commit("SET_ALL_EVENT_PLACES", [...results]);
      });
    }
  } catch (error) {
    dispatch("subtractLoading");
    console.error("Failed retrieving event data", error);
    throw error;
  }
};

export const registerCampaign = async ({ commit }, params) => {
  try {
    const response = await axios.post(`event/campaign/${params.event}`, params);
    if (response && response?.status === 200) {
      commit("SET_SNACKBAR", {
        open: true,
        message: response.data.message,
        color: "green",
      });
    } else {
      commit("SET_SNACKBAR", {
        open: true,
        message: "Erro inesperado",
        color: "red",
      });
    }
    return response;
  } catch (error) {
    if (error?.response?.data?.message) {
      commit("SET_SNACKBAR", {
        open: true,
        message: error?.response?.data?.message,
        color: "red",
      });
    } else {
      commit("SET_SNACKBAR", {
        open: true,
        message: "Erro inesperado",
        color: "red",
      });
    }
    dispatch("subtractLoading");
    throw error;
  }
};
