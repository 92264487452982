
import axios from "axios";


export const postMessage = ({ commit }, params) => {
  return axios.post(`/v2/organizers/customer/message/send`,{
      codigo_organizador: params.id,
      nome: params.name,
      email: params.email,
      mensagem: `Nome: ${params.name} Email: ${params.email} Telefone: ${params.phone} Mensagem: ${params.message}`
  }).then(response => {
    commit("SET_MESSAGE", response.data);
  });
};