<template>
  <v-card
    class="pb-4"
    tile
    elevation="0"
    :min-height="settings.height ? settings.height : '500px'"
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit
      :sectionId="settings.id"
      v-if="$route.name === 'BodyEdit' && hover"
    />

    <title-component :settings="settingsTitle" />
    <v-carousel
      hide-delimiters
      :show-arrows="
        ($vuetify.breakpoint.mdAndUp &&
          materialForCarousel &&
          materialForCarousel.length &&
          materialForCarousel.length > 1) ||
          (!$vuetify.breakpoint.mdAndUp &&
            materialForCarousel &&
            materialForCarousel.length &&
            materialForCarousel.length > 1)
      "
      :cycle="!$vuetify.breakpoint.mdAndUp"
    >
      <v-carousel-item
        v-for="(carousel, index) in materialForCarousel"
        :key="index"
      >
        <v-row no-gutters justify="space-around" align="center" class="mt-6">
          <v-col
            v-for="(item, i) in materialForCarousel[index]"
            :key="i"
            :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
            open
            class="px-6"
            align="center"
          >
            <v-card
              min-height="400"
              :max-width="!$vuetify.breakpoint.mdAndUp ? 270 : 300"
              style="border-radius: 10px;"
              :color="settings['item-color'] || '#fff'"
              :style="item.link ? 'cursor: pointer' : ''"
              flat
              @click="handleOpenNewPage(item)"
            >
              <v-img
                max-height="150"
                eager
                style="border-radius: 10px;"
                :src="item.media"
              ></v-img>

              <v-row class="px-6 pt-6" justify="start" no-gutters>
                <span>{{ item.title }}</span>
              </v-row>
              <v-row justify="start" class="px-6 pt-4" no-gutters>
                <b>{{ item.description }}</b>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
import TitleComponent from "../title/TitleComponent.vue";
import MenuEdit from "../edit/MenuEdit";
export default {
  components: { TitleComponent, MenuEdit },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      settingsTitle: null,
      hover: false,
    };
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },

  computed: {
    materialForCarousel() {
      var res = [],
        mobileOrNo = this.$vuetify.breakpoint.mdAndUp ? 3 : 1;

      for (var i = 0; i < this.settings.items.length; i = i + mobileOrNo) {
        res.push(this.settings.items.slice(i, i + mobileOrNo));
      }

      return res;
    },
  },

  methods: {
    handleOpenNewPage(item) {
      let link = "";

      if (item.link.includes("https://")) link = item.link;
      else link = "https://" + item.link;

      window.open(link, "_target");
    },
  },
};
</script>

<style scoped>
h3,
b,
span {
  text-align: start;
}

.spanContent {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.25px;
}

b {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
}

span {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
}
</style>
