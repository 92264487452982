<template>
  <div>
    <v-row no-gutters class="mt-2">
      <v-select
        label="Layout"
        outlined
        background-color="white"
        :items="layouts"
        hide-details
        v-model="section.layout"
      ></v-select>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-checkbox
        v-model="section.expanded"
        label="Exibir grupos expandidos"
      ></v-checkbox>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["section"],

  data() {
    return {
      layouts: ["Layout 1", "Layout 2"],
    };
  },
};
</script>
