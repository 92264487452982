var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.settings)?_c('v-card',{class:_vm.$route.name === 'BodyEdit' ? 'editCard' : '',attrs:{"tile":"","elevation":"0","height":_vm.settings.height ? _vm.settings.height : 'calc(100vh - 80px)',"color":_vm.settings['background-type'] !== 'color'
      ? ''
      : _vm.settings['background-color'],"img":_vm.settings['background-type'] !== 'image'
      ? ''
      : _vm.settings['background-image']},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.$route.name === 'BodyEdit' && _vm.hover)?_c('menu-edit',{staticClass:"edit",attrs:{"sectionId":_vm.settings.id}}):_vm._e(),(
      _vm.settings['background-video'] && _vm.settings['background-type'] === 'video'
    )?_c('video',{attrs:{"autoplay":"","muted":"","loop":"","id":"myVideo"},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.settings['background-type'] === 'video'
          ? _vm.settings['background-video']
          : '',"type":"video/mp4"}})]):_vm._e(),_c('v-overlay',{class:_vm.settings['text-position'] === 'center' || !_vm.$vuetify.breakpoint.mdAndUp
        ? ''
        : 'overlay',attrs:{"absolute":"","color":"transparent"}},[_c('div',{class:_vm.settings['text-position'] === 'center' || !_vm.$vuetify.breakpoint.mdAndUp
          ? 'center'
          : 'left'},[_c('v-row',{staticClass:"mb-10",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"align":_vm.settings['text-position'] === 'center' ||
            !_vm.$vuetify.breakpoint.mdAndUp
              ? 'center'
              : 'start'}},[(_vm.settings.logo)?_c('v-img',{staticClass:"mb-6",attrs:{"contain":"","eager":"","max-width":"100vw","height":_vm.settings.logo_height,"src":_vm.settings.logo}}):_vm._e(),_c('h1',{staticClass:"mb-3",style:(("font-family: " + (_vm.settings.title['font'] ? _vm.settings.title['font'] : '') + "; font-size: " + (_vm.settings.title['font-size']
                  ? _vm.settings.title['font-size']
                  : _vm.infos.layout.title['font-size']) + "; color: " + (_vm.settings.title.color) + "; line-height: " + (_vm.settings.title['font-size']
                  ? _vm.settings.title['font-size']
                  : _vm.infos.layout.title['font-size'])))},[_vm._v(" "+_vm._s(_vm.settings.title.content)+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatedText)}}),(_vm.settings && _vm.settings.button)?_c('v-row',{staticClass:"mt-2",attrs:{"justify":_vm.settings['text-position'] === 'center' ||
              !_vm.$vuetify.breakpoint.mdAndUp
                ? 'center'
                : 'start',"no-gutters":""}},[(_vm.settings.button.caption)?_c('v-btn',{style:(("background-color: " + (_vm.settings['button-color'] ? _vm.settings['button-color'] : '') + " !important")),attrs:{"href":_vm.settings.button.action.charAt(0) === '#'
                  ? ''
                  : _vm.settings.button.action === 'Abrir Checkout'
                  ? ''
                  : _vm.settings.button.action,"target":"_blank","elevation":"0","height":"44px","loading":_vm.oneCheckoutOpen},on:{"click":_vm.actionButton}},[_c('b',{style:(("color: " + (_vm.settings['text-button-color']
                      ? _vm.settings['text-button-color']
                      : '') + " !important"))},[_vm._v(_vm._s(_vm.settings.button.caption))])]):_vm._e()],1):_vm._e()],1)],1)],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }