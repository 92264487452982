<template>
  <div>
    <v-card
      class="card"
      tile
      :color="
        settings['background-type'] === 'color'
          ? settings['background-color']
          : ''
      "
      :img="
        settings['background-type'] === 'image'
          ? settings['background-image']
          : ''
      "
      style="width: 100vw"
      elevation="0"
      :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
      :min-height="settings.height ? settings.height : '408'"
      src
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <menu-edit
        :sectionId="settings.id"
        v-if="$route.name === 'BodyEdit' && hover"
      />

      <v-container
        style="max-width: 1340px"
        :class="$vuetify.breakpoint.mdAndUp ? 'px-12' : ''"
      >
        <div class="row">
          <v-col
            v-if="
              settings.title.content ||
                settings.text.content ||
                settings.button.caption
            "
            :order="settings['text-position'] !== 'left' ? '1' : '2'"
            :cols="
              $vuetify.breakpoint.mdAndUp &&
              settings['text-position'] !== 'center' &&
              ((settings.media && settings.media.content) ||
                (settings.media && settings.media.videoid))
                ? '6'
                : '10'
            "
            align="start"
            :class="
              settings['text-position'] !== 'left' &&
              $vuetify.breakpoint.mdAndUp
                ? settings['text-position'] === 'center'
                  ? ''
                  : 'pr-12'
                : settings['text-position'] === 'left' &&
                  $vuetify.breakpoint.mdAndUp
                ? 'pl-12'
                : ''
            "
          >
            <v-row
              :justify="
                ((settings.media && settings.media.content) ||
                  (settings.media && settings.media.videoid)) &&
                $vuetify.breakpoint.mdAndUp
                  ? 'start'
                  : 'center'
              "
              no-gutters
            >
              <v-col>
                <h1
                  :style="
                    `font-family: ${
                      settings.title.font ? settings.title.font : ''
                    }; font-size: ${
                      settings.title['font-size']
                        ? settings.title['font-size']
                        : infos.layout.title['font-size']
                    }; color: ${settings.title.color}; text-align: ${
                      settings.text['text-align']
                    }`
                  "
                  class="my-5"
                >
                  {{ settings.title.content }}
                </h1>
                <div :style="`text-align: ${settings.text['text-align']};`">
                  <span v-html="formatedText"></span>
                </div>
                <br />
                <div :style="`text-align: ${settings.text['text-align']};`">
                  <v-btn
                    v-if="settings.button.caption"
                    :href="
                      settings.button.action &&
                      settings.button.action.charAt(0) === '#'
                        ? ''
                        : settings.button.action === 'Abrir Checkout'
                        ? ''
                        : settings.button.action
                    "
                    target="_blank"
                    class="mt-5"
                    elevation="0"
                    height="44px"
                    :style="
                      `background-color: ${
                        settings['button-color'] ? settings['button-color'] : ''
                      } !important`
                    "
                    :loading="oneCheckoutOpen"
                    @click="actionButton"
                    ><b
                      :style="
                        `color: ${
                          settings['text-button-color']
                            ? settings['text-button-color']
                            : ''
                        } !important`
                      "
                      >{{ settings.button.caption }}</b
                    ></v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="
              (settings.media &&
                settings.media.type === 'image' &&
                settings.media.content) ||
                (settings.media &&
                  settings.media.type === 'youtube' &&
                  settings.media.videoid)
            "
            :order="settings['text-position'] !== 'left' ? '2' : '1'"
            :cols="
              $vuetify.breakpoint.mdAndUp &&
              settings['text-position'] !== 'center'
                ? '6'
                : '12'
            "
            align="center"
            :class="
              settings['text-position'] !== 'left' &&
              $vuetify.breakpoint.mdAndUp
                ? settings['text-position'] === 'center'
                  ? ''
                  : 'pl-12'
                : settings['text-position'] === 'left' &&
                  $vuetify.breakpoint.mdAndUp
                ? 'pr-12'
                : ''
            "
          >
            <v-row
              :class="settings['text-position'] === 'center' ? 'pb-10' : ''"
              justify="center"
              no-gutters
            >
              <v-row
                v-if="
                  !settings.title.content &&
                    !settings.text.content &&
                    !settings.button.caption
                "
                justify="center"
                no-gutters
              >
                <v-img
                  contain
                  eager
                  :max-width="settings.media.height"
                  v-if="settings.media.type === 'image'"
                  :src="settings.media.content"
                ></v-img>
              </v-row>
              <v-img
                :max-width="settings.media.height"
                v-if="
                  (settings.media.type === 'image' && settings.title.content) ||
                    settings.text.content ||
                    settings.button.caption
                "
                eager
                :src="settings.media.content"
              ></v-img>
              <iframe
                :height="$vuetify.breakpoint.mdAndUp ? '316px' : '100%'"
                :width="$vuetify.breakpoint.mdAndUp ? '534px' : '100%'"
                v-if="settings.media.type === 'youtube'"
                style="border: none"
                :src="formatYoutube(settings.media.videoid)"
              ></iframe>
            </v-row>
          </v-col>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import MenuEdit from "../edit/MenuEdit";
import { mapGetters } from "vuex";
export default {
  components: { MenuEdit },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      alignCenter: true,
      height: "650px",
      hover: false,
    };
  },

  computed: {
    ...mapGetters(["infos", "oneCheckoutOpen"]),
    formatedText() {
      let p = "</p>";
      if (this.settings.text.content)
        return this.settings.text.content
          .replaceAll(
            /<p>/g,
            `<span style="font-family: ${
              this.settings.text.font ? this.settings.text.font : ""
            } !important; font-size: ${
              this.settings.text["font-size"]
                ? this.settings.text["font-size"]
                : this.infos.layout.text["font-size"]
            }; color: ${this.settings.text.color}">`
          )
          .replace(/`${p}`/g, "</span>");
      else return "";
    },
  },

  methods: {
    formatYoutube(code) {
      return `https://www.youtube.com/embed/${code}`;
    },

    actionButton() {
      if (
        this.settings.button.action &&
        this.settings.button.action.charAt(0) === "#"
      )
        this.$vuetify.goTo(this.settings.button.action);
      else if (this.settings.button.action === "Abrir Checkout")
        this.openCheckout();
    },
    openCheckout() {
      this.$store.dispatch("setOneCheckoutOpen", true);
    },
  },
};
</script>

<style scoped>
.v-card {
  font-family: Inter, sans-serif !important;
}

.column {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.divBoxImage {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row {
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 37px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0px;
}

span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
}

.v-btn {
  font-weight: 700;
}
</style>
