import axios from "axios";

export const getProgram = ({ commit }, event) => {
  return axios.get(`/event/schedule/${event}`).then((response) => {
    const eventMap = new Map(),
      setTabs = new Set();
    response.data.forEach((event) => {
      const startDate = new Date(event.horario_inicio.replace(" ", "T"));
      if (!eventMap.has(startDate.getFullYear()))
        eventMap.set(startDate.getFullYear(), new Map());
      const eventMonths = eventMap.get(startDate.getFullYear());
      if (!eventMonths.has(startDate.getMonth()))
        eventMonths.set(startDate.getMonth(), new Map());
      const eventDays = eventMonths.get(startDate.getMonth());
      if (!eventDays.has(startDate.getDate()))
        eventDays.set(startDate.getDate(), []);
      const eventDay = eventDays.get(startDate.getDate());
      eventDay.push({ ...event, timestamp: startDate.getTime() });
      const day = startDate.getDate(),
        month = startDate.getMonth(),
        year = startDate.getFullYear();
      setTabs.add(new Date(year, month, day).getTime());
    });
    const events = [];
    for (let year of eventMap.values()) {
      for (let month of year.values()) {
        for (let day of month.values()) {
          events.push(day);
        }
      }
    }
    events.sort((a, b) => {
      return a[0].timestamp - b[0].timestamp;
    });
    events.forEach((eventsDay) => {
      eventsDay.sort((a, b) => {
        return a.timestamp - b.timestamp;
      });
    });

    commit("SET_PROGRAMS", {
      events,
      tabs: [...setTabs].sort().map((tab) => {
        const date = new Date(tab),
          day = date.getDate(),
          month = date.getMonth() + 1,
          year = date.getFullYear();
        return `${day < 10 ? `0${day}` : day}/${
          month < 10 ? `0${month}` : month
        }/${year}`;
      }),
    });
  });
};

export const getProducts = ({ commit }, event) => {
  return axios
    .get(`/pdv/v3/products/simple/${event}?cupom=`, {
      headers: { PDV: 100, POS: 100 },
    })
    .then((response) => {
      commit("SET_PRODUCTS", response.data);
    });
};

export const getSpeakers = ({ commit }, event) => {
  return axios.get(`/event/speakers/${event}`).then((response) => {
    response.data.forEach((item) => {
      item.show = true;
    });
    commit("SET_SPEAKERS", response.data);
  });
};

export const getLogos = ({ commit }, event) => {
  return axios.get(`/event/logos/groups/${event}`).then((response) => {
    response.data.map((item) => {
      item.show = true;
      item.settings = {
        title: {
          color: "",
          content: item.nome,
          font: "",
          "font-size": "",
        },
        subtitle: "",
        "background-color": "transparent",
      };
    });
    commit("SET_LOGOS", response.data);
    return response.data;
  });
};

export const getSponsors = ({ commit }, event) => {
  return axios.get(`/event/sponsors/groups/${event}`).then((response) => {
    commit("SET_SPONSORS", response.data);
    return response.data;
  });
};

export const getFaq = ({ commit }, event) => {
  return axios.get(`/event/faq/${event}`).then((response) => {
    commit("SET_FAQ", response.data);
    return response.data;
  });
};

export const getCampaigns = ({ commit }, params) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${params.token}`;
  return axios
    .get(`panel/campaigns?codigo_evento=${params.event}`)
    .then((response) => {
      commit("SET_CAMPAINGS", response.data.data);
      return response.data;
    });
};

export const setImage = ({ commit }, event) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${event.token}`;
  return axios
    .post(`/panel/media/store/hotsite`, event.formData)
    .then((response) => {
      if (response.status === 200) {
        commit("SET_IMAGE", response.data);
        commit("SET_SNACKBAR", {
          open: true,
          message: "Importação realizada com sucesso",
          color: "green",
        });
      } else
        commit("SET_SNACKBAR", {
          open: true,
          message: "Ocorreu um erro. Por favor notifique o suporte",
          color: "red",
        });
    });
};

export const setInfos = ({ commit }, infos) => {
  commit("SET_INFOS", infos);
};

export const setSaveConfig = ({ commit }, saveConfig) => {
  commit("SET_SAVE_CONFIG", saveConfig);
};

export const getHotsite = ({ commit }, params) => {
  commit("SET_LOADING", true);
  return axios
    .get(
      `/hotsite/${params.domain ? params.domain : params.slug}/${
        params.version
      }`
    )
    .then((response) => {
      commit("SET_HOTSITE", response.data);
      commit("SET_LOADING", false);
    });
};

export const saveHotsite = ({ commit }, params) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${params.token}`;
  return axios
    .post(`/panel/hotsites/store`, {
      codigo: params.code,
      codigo_hotsite: params.code_hotsite,
      codigo_organizador: params.code_organizer,
      dominio: params.domain,
      nome: params.name,
      slug: params.slug,
      versao: {
        codigo: params.code_version,
        status: params.status,
        configuracoes: params.config,
      },
    })
    .then((response) => {
      if (response.status === 200 && params.status !== "P")
        commit("SET_SNACKBAR", {
          open: true,
          message: "Salvo com sucesso",
          color: "green",
        });
      else if (response.status === 200 && params.status === "P")
        commit("SET_SNACKBAR", {
          open: true,
          message: "Publicado com sucesso",
          color: "green",
        });
      else
        commit("SET_SNACKBAR", {
          open: true,
          message: "Ocorreu um erro. Por favor notifique o suporte",
          color: "red",
        });
      return response;
    });
};

export const getTickets = ({ commit }, event) => {
  if (process.env.NODE_ENV === "development")
    return axios
      .get(
        `https://soulapi.dev03.btservers.com.br/api/pdv/event/simple/${event}`
      )
      .then((response) => {
        commit("SET_TICKETS", response.data);
      });
  else
    return axios.get(`/pdv/event/simple/${event}`).then((response) => {
      commit("SET_TICKETS", response.data);
    });
};

export const saveTicket = ({ commit }, params) => {
  if (process.env.NODE_ENV === "development")
    return axios
      .post(
        `https://soulapi.dev03.btservers.com.br/api/orders/create/subscription`,
        params,
        {
          headers: { PDV: 104, POS: 100 },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          commit("SET_SNACKBAR", {
            open: true,
            message: "Inscrição feita com sucesso",
            color: "green",
          });
        } else
          commit("SET_SNACKBAR", {
            open: true,
            message: "Ocorreu um erro. Por favor notifique o suporte",
            color: "red",
          });
        return response;
      });
  else
    return axios
      .post(`/orders/create/subscription`, params, {
        headers: { PDV: 104, POS: 100 },
      })
      .then((response) => {
        if (response.status === 200)
          commit("SET_SNACKBAR", {
            open: true,
            message: "Inscrição feita com sucesso",
            color: "green",
          });
        else
          commit("SET_SNACKBAR", {
            open: true,
            message: "Ocorreu um erro. Por favor notifique o suporte",
            color: "red",
          });
        return response;
      });
};

export const uploadArchiveAndImage = ({ commit }, params) => {
  return axios
    .post(
      `https://soulapi.dev03.btservers.com.br/api/v2/tickets/assign/upload`,
      params
    )
    .then((response) => {});
};
