<template>
  <v-card
    tile
    :min-height="settings.height ? settings.height : '578px'"
    elevation="0"
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit
      :sectionId="settings.id"
      v-if="$route.name === 'BodyEdit' && hover"
    />
    <title-component :settings="settingsTitle" />
    <div class="div-style-width">
      <v-row
        v-if="
          (products &&
            products.products &&
            settings.layout === 'vertical' &&
            $vuetify.breakpoint.mdAndUp) ||
            (products &&
              products.products &&
              settings.layout === 'horizontal') ||
            (!$vuetify.breakpoint.mdAndUp && settings.layout !== 'form')
        "
        justify="center"
        no-gutters
        style="max-width: 1340px !important"
      >
        <!-- VERTICAL -->

        <v-carousel
          v-if="
            products &&
              products.products &&
              settings.layout === 'vertical' &&
              $vuetify.breakpoint.mdAndUp
          "
          hide-delimiters
          :show-arrows="
            $vuetify.breakpoint.mdAndUp &&
              splitArray &&
              splitArray.length &&
              splitArray.length > 1
          "
          :cycle="!$vuetify.breakpoint.mdAndUp"
        >
          <v-carousel-item v-for="(carousel, index) in splitArray" :key="index">
            <v-container style="overflow: auto" fill-height>
              <v-row no-gutters justify="center" align="center">
                <v-col
                  v-for="(item, i) in splitArray[index]"
                  :key="i"
                  :class="settings['text-color']"
                  :cols="settings['max-coloumns']"
                  align="center"
                >
                  <v-card
                    elevation="0"
                    max-width="
                    255
                  "
                    height="100%"
                    max-height="100%"
                    class="mt-3 mx-2"
                    :color="settings['item-background-color']"
                  >
                    <v-row no-gutters justify="start" align="start">
                      <v-col align="center">
                        <v-row
                          style="height: 100%; min-height: 100px; display: flex; justify-content: center; align-items: center"
                          justify="center"
                          class="pt-2 px-8 "
                          no-gutters
                        >
                          <b class="ticketName black--text"
                            >{{ item.nome }} - {{ item.nome_tipo }}</b
                          >
                        </v-row>

                        <!-- <v-list-item
                            style="height: 196px; max-height: 196px"
                            class="my-4 ml-4"
                          > -->
                        <v-card
                          class="mx-9 mb-5 mt-5"
                          elevation="0"
                          color="transparent"
                          height="200px"
                        >
                          <v-row no-gutters justify="center">
                            <v-col align="start">
                              <span
                                style="white-space: pre-line; text-align: left !important"
                                class="description black--text"
                              >
                                {{ descriptionVertical(item) }}
                              </span>
                              <dialog-ticket
                                class="mr-3 py-2"
                                v-if="
                                  item.observacoes &&
                                    item.observacoes.length >= 150
                                "
                                :item="item"
                              />
                            </v-col>
                          </v-row>
                        </v-card>
                        <!-- </v-list-item> -->
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-row
                        justify="center"
                        class="py-5"
                        no-gutters
                        align="center"
                      >
                        <v-col>
                          <v-row class="mb-1" justify="center">
                            <b v-show="!item.encerrado" class="black--text">
                              {{
                                item.valor + item.valor_conveniencia
                                  ? `R$ ${(item.valor + item.valor_conveniencia)
                                      .toFixed(2)
                                      .replace(".", ",")}`
                                  : "Grátis"
                              }}
                            </b>
                          </v-row>
                          <v-btn
                            dark
                            elevation="0"
                            @click="openCheckout(item)"
                            :style="
                              `background-color: ${
                                settings['button-color']
                                  ? `${settings['button-color']} !important`
                                  : ''
                              }; ${
                                item.encerrado
                                  ? 'cursor: default !important'
                                  : ''
                              }`
                            "
                            :readonly="!!item.encerrado"
                            ><b
                              :style="
                                `color: ${
                                  settings['text-button-color']
                                    ? settings['text-button-color']
                                    : ''
                                } !important`
                              "
                              >{{
                                item.encerrado
                                  ? "Encerrado"
                                  : item.valor + item.valor_conveniencia
                                  ? "Comprar"
                                  : "Fazer Inscrição"
                              }}</b
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>

        <!-- HORIZONTAL E MOBILE -->

        <v-container
          v-if="
            (products &&
              products.products &&
              settings.layout === 'horizontal') ||
              (!$vuetify.breakpoint.mdAndUp &&
                settings.layout !== 'form' &&
                settings.layout !== 'custom')
          "
          class="mb-12"
          style="overflow: auto"
          fill-height
        >
          <v-row no-gutters justify="center" align="center">
            <v-col
              v-for="(item, i) in products.products"
              :key="i"
              :class="settings['text-color']"
              cols="12
              "
              align="center"
            >
              <v-card
                elevation="0"
                :max-width="$vuetify.breakpoint.mdAndUp ? '900' : ''"
                :max-height="$vuetify.breakpoint.mdAndUp ? '400' : ''"
                :min-height="$vuetify.breakpoint.mdAndUp ? '105px' : ''"
                class="mt-3 mx-2"
                :color="settings['item-background-color']"
              >
                <v-row
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? item.observacoes
                        ? 'pt-7'
                        : 'pt-8'
                      : 'pt-5'
                  "
                  align="center"
                  no-gutters
                >
                  <v-col
                    class="px-6"
                    align="start"
                    :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'"
                    ><b class="black--text"
                      >{{ item.nome }} - {{ item.nome_tipo }}</b
                    >
                    <br />
                    <v-row align="center" no-gutters>
                      <b v-if="$vuetify.breakpoint.mdAndUp" class="description"
                        >{{
                          item.observacoes
                            ? item.observacoes.length >= 50
                              ? `${item.observacoes.slice(0, 50)}...`
                              : item.observacoes
                            : ""
                        }}
                      </b>
                      <b v-else class="description">{{
                        item.observacoes ? item.observacoes : ""
                      }}</b>
                      <dialog-ticket
                        class="ml-2"
                        v-if="
                          $vuetify.breakpoint.mdAndUp &&
                            item.observacoes &&
                            item.observacoes.length >= 50
                        "
                        :item="item"
                      />
                    </v-row>
                  </v-col>
                  <v-col
                    :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-3 pb-5'"
                    align="end"
                    :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                  >
                    <v-row
                      no-gutters
                      :justify="
                        $vuetify.breakpoint.mdAndUp ? 'end' : 'space-between'
                      "
                      :class="$vuetify.breakpoint.mdAndUp ? '' : 'pl-6'"
                      align="center"
                    >
                      <b
                        v-show="!item.encerrado"
                        class="black--text"
                        :class="
                          alignHorizontal || !$vuetify.breakpoint.mdAndUp
                            ? 'mr-4 price'
                            : 'price'
                        "
                      >
                        {{
                          item.valor + item.valor_conveniencia
                            ? `R$ ${(item.valor + item.valor_conveniencia)
                                .toFixed(2)
                                .replace(".", ",")}`
                            : "Grátis"
                        }}
                      </b>
                      <v-btn
                        dark
                        elevation="0"
                        :style="
                          `background-color: ${
                            settings['button-color']
                              ? `${settings['button-color']} !important`
                              : ''
                          }; ${
                            item.encerrado ? 'cursor: default !important' : ''
                          }`
                        "
                        class="mr-6"
                        @click="openCheckout(item)"
                        :readonly="!!item.encerrado"
                      >
                        <b
                          :style="
                            `color: ${
                              settings['text-button-color']
                                ? settings['text-button-color']
                                : ''
                            } !important`
                          "
                          >{{
                            item.encerrado
                              ? "Encerrado"
                              : item.valor + item.valor_conveniencia
                              ? "Comprar"
                              : "Fazer Inscrição"
                          }}</b
                        >
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-row>

      <!-- FORM -->

      <div
        v-if="settings.layout === 'form' && settings['type-ticket']"
        :style="$vuetify.breakpoint.mdAndUp ? 'width:1340px' : ''"
      >
        <v-row
          :style="
            $vuetify.breakpoint.mdAndUp
              ? 'max-width: 1340px; width:100%'
              : 'width: 100vw'
          "
          no-gutters
          justify="center"
        >
          <v-col
            v-if="formatedText"
            class="mt-10 px-12"
            :cols="$vuetify.breakpoint.mdAndUp ? '5' : '12'"
          >
            <div :style="`text-align: ${settings.text['text-align']};`">
              <span v-html="formatedText"></span>
            </div>
          </v-col>
          <v-col
            :class="$vuetify.breakpoint.mdAndUp ? 'pa-12' : 'pa-4'"
            :cols="!$vuetify.breakpoint.mdAndUp ? '12' : '7'"
          >
            <v-card
              :color="settings['layer-color']"
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'pa-10 rounded-xl'
                  : 'pa-7 rounded-xl'
              "
              elevation="0"
            >
              <v-form ref="form" v-model="valid">
                <form-tickets
                  v-for="(form, i) in settings['type-ticket'].extradata"
                  :key="i"
                  :option="form"
                  :section="settings"
                />
              </v-form>

              <v-row
                class="mb-n3 mt-3"
                no-gutters
                justify="center"
                align="center"
              >
                <v-btn
                  class="submit-registration"
                  :disabled="!valid"
                  @click="saveTicket"
                  :style="
                    `background-color: ${
                      settings['button-color']
                        ? `${settings['button-color']} !important`
                        : ''
                    }`
                  "
                  elevation="0"
                  :loading="loading"
                  rounded
                  ><b
                    :style="
                      `color: ${
                        settings['text-button-color']
                          ? settings['text-button-color']
                          : ''
                      } !important`
                    "
                    >Inscrever-se</b
                  >
                </v-btn>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!-- PERSONALIZADO -->

      <v-carousel
        v-if="settings.layout === 'custom'"
        hide-delimiters
        :show-arrows="
          ($vuetify.breakpoint.mdAndUp &&
            splitArray &&
            splitArray.length &&
            splitArray.length > 1) ||
            !$vuetify.breakpoint.mdAndUp
        "
        class="mb-12"
        :cycle="!$vuetify.breakpoint.mdAndUp"
      >
        <v-carousel-item v-for="(carousel, index) in splitArray" :key="index">
          <v-container style="overflow: auto" fill-height>
            <v-row no-gutters justify="center" align="center">
              <v-col
                v-for="(item, i) in splitArray[index]"
                :key="i"
                :class="settings['text-color']"
                :cols="settings['max-coloumns']"
                align="center"
              >
                <v-card
                  elevation="0"
                  max-width="
                    255
                  "
                  height="100%"
                  max-height="100%"
                  class="mt-3 mx-2"
                  style="border-radius: 15px"
                  :color="item.backgroundColor || 'grey'"
                >
                  <v-row no-gutters justify="start" align="start">
                    <v-col align="center">
                      <v-row
                        style="height: 100%; min-height: 100px; display: flex; align-items: center"
                        class="pt-2 px-8 "
                        no-gutters
                      >
                        <b class="ticketName black--text">{{ item.title }}</b>
                      </v-row>

                      <v-card
                        class="mx-9 mb-5 mt-5"
                        elevation="0"
                        color="transparent"
                        height="200px"
                      >
                        <v-row no-gutters justify="center">
                          <v-col align="start">
                            <span
                              style="white-space: pre-line; text-align: left !important"
                              class="description black--text"
                            >
                              {{ item.description }}
                            </span>
                            <dialog-ticket
                              class="mr-3 py-2"
                              v-if="
                                item.observacoes &&
                                  item.observacoes.length >= 150
                              "
                              :item="item"
                            />
                          </v-col>
                        </v-row>
                      </v-card>
                      <!-- </v-list-item> -->
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-row
                      justify="center"
                      class="py-5"
                      no-gutters
                      align="center"
                    >
                      <v-col>
                        <v-row class="mb-2" justify="center">
                          <b v-show="!item.encerrado" class="black--text">
                            {{ item.price ? `R$ ${item.price}` : "Grátis" }}
                          </b>
                        </v-row>
                        <v-btn
                          dark
                          elevation="0"
                          @click="handleOpenExternalLink(item)"
                          :style="
                            `background-color: ${item.buttonColor ||
                              'black'} !important;`
                          "
                          ><b
                            :style="
                              `color: ${
                                item.buttonTextColor
                                  ? item.buttonTextColor
                                  : 'white'
                              } !important`
                            "
                            >{{ item.buttonText || "Comprar" }}</b
                          >
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-carousel-item>
      </v-carousel>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import TitleComponent from "../title/TitleComponent.vue";
import MenuEdit from "../edit/MenuEdit";
import FormTickets from "./formTickets/FormTickets";
import DialogTicket from "./DialogTicket.vue";
export default {
  components: { TitleComponent, MenuEdit, FormTickets, DialogTicket },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      valid: true,
      alignHorizontal: true,
      tabs: 0,
      settingsTitle: null,
      hover: false,
      eventOnline: null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["products", "oneCheckoutOpen", "infos", "tickets"]),
    splitArray() {
      var res = [],
        mobileOrNo = this.$vuetify.breakpoint.mdAndUp
          ? this.settings["max-columns"]
          : 2;

      let products = this.products.products;

      if (this.settings.layout === "custom") {
        products = this.settings.items;
        if (!this.$vuetify.breakpoint.mdAndUp) mobileOrNo = 1;
      }

      for (var i = 0; i < products.length; i = i + mobileOrNo) {
        res.push(products.slice(i, i + mobileOrNo));
      }
      return res;
    },
    formatedText() {
      let p = "</p>";
      if (this.settings.text.content)
        return this.settings.text.content
          .replace(
            /<p>/g,
            `<span style="font-family: ${
              this.settings.text.font ? this.settings.text.font : ""
            } !important; font-size: ${
              this.settings.text["font-size"]
                ? this.settings.text["font-size"]
                : this.infos.layout.text["font-size"]
            }; color: ${this.settings.text.color}">`
          )
          .replace(/`${p}`/g, "</span");
      else return "";
    },
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },

  methods: {
    openCheckout(item) {
      if (!item.encerrado) this.$store.dispatch("setOneCheckoutOpen", true);
    },

    returnTextButton() {},
    descriptionVertical(item) {
      if (item.observacoes) {
        if (item.observacoes.length >= 80 && item.observacoes.includes("\n")) {
          return `${item.observacoes.slice(0, 80)}...`;
        } else if (
          item.observacoes.length >= 135 &&
          !item.observacoes.includes("\n")
        ) {
          return `${item.observacoes.slice(0, 135)}...`;
        } else return item.observacoes;
      } else return "";
    },
    showMore(item) {
      if (item.observacoes) {
        if (item.observacoes.length >= 80 && item.observacoes.includes("\n")) {
          return true;
        } else if (
          item.observacoes.length >= 135 &&
          !item.observacoes.includes("\n")
        ) {
          return true;
        } else return false;
      } else return false;
    },
    resetForm() {
      this.$refs.form.reset();
    },

    handleOpenExternalLink(item) {
      let link = "";

      if (item.link.includes("https://")) link = item.link;
      else link = "https://" + item.link;

      window.open(link, "_target");
    },

    async saveTicket() {
      // TODO: ARQUIVO E IMAGENS TEM Q FAZER AINDA
      this.loading = true;
      let name,
        email = "";
      let extradata = [];
      this.settings["type-ticket"].extradata.forEach((data) => {
        if (data.tipo === "nome") name = data.valor;
        if (data.tipo === "email") email = data.valor;
        if (data.tipo !== "upload_imagem" && data.tipo !== "upload_arquivo")
          extradata.push({ codigo: data.codigo, valor: data.valor });
        if (data.tipo === "upload_imagem") {
          extradata.push({ codigo: data.codigo, valor: data.image });
        }
        if (data.tipo === "upload_arquivo") {
          extradata.push({ codigo: data.codigo, valor: data.file });
        }
      });
      const ht = localStorage.getItem("ht");
      await this.$store
        .dispatch("saveTicket", {
          codigo_evento: this.infos["event-id"],
          codigo_produto: this.settings["type-ticket"].codigo_produto,
          codigo_tipo: this.settings["type-ticket"].codigo_tipo,
          nome_cliente: name,
          email_cliente: email,
          extradata: extradata,
          codigo: this.tickets.codigo,
          valor: this.settings["type-ticket"].valor,
          hash_terminal: ht,
        })
        .then((r) => {
          if (r.status === 200) {
            this.eventOnline = r.data;
            this.resetForm();
            if (this.infos.integrations["facebook-pixel"]) {
              var mf = document.createElement("img");
              mf.src = `https://www.facebook.com/tr?id=${this.infos.integrations["facebook-pixel"]}&ev=Purchase&cd[currency]=BRL&cd[value]=0`;
              mf.style = "display:none;";
              mf.height = 1;
              mf.width = 1;
              document.getElementsByTagName("head")[0].appendChild(mf);
            }
          } else this.eventOnline = null;
        });
      this.loading = false;
      if (this.eventOnline && this.eventOnline.evento_liberado)
        window.location.href = this.eventOnline.url_acesso_evento;
    },

    formatText(text) {
      text = text.replace(/(?:\r\n|\r|\n)/g, "<br>");
      return text;
    },
  },
};
</script>

<style scoped>
.ticketName {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.zone {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.75px;
  text-align: left;
  color: #00015e;
}

.description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  /* text-align: left; */
}

.ticket-container {
  border: 1px solid #c8ccd3;
  box-sizing: border-box;
  border-color: #c8ccd3 !important;
  border-radius: 4px;
  width: 80%;
  margin: 30px 30px;
  padding: 50px;
}

.ticket-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: #192b46;
  margin-bottom: 20px;
}

.price {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.25px;
  text-align: right;
}

.v-carousel {
  width: 85vw;
}

.div-style-width {
  width: 100%;
  display: flex;
  justify-content: center;
}

.v-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
}

.spacer {
  margin-bottom: 150px;
}
</style>
