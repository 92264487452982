import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'

const state = {
  event: null,
  oneCheckoutOpen: false,
  eventPlaces: []
}

export default {
  state,
  actions,
  mutations,
  getters,
}
