export const infos = (state) => {
  return state.infos;
};

export const programs = (state) => {
  return state.programs;
};

export const products = (state) => {
  return state.products;
};

export const speakers = (state) => {
  return state.speakers;
};

export const logos = (state) => {
  return state.logos;
};

export const saveConfig = (state) => {
  return state.saveConfig;
};

export const campaings = (state) => {
  return state.campaings;
};

export const image = (state) => {
  return state.image;
};

export const hotsite = (state) => {
  return state.hotsite;
};

export const loading = (state) => {
  return state.loading;
};

export const snackbar = (state) => {
  return state.snackbar;
};

export const tickets = (state) => {
  return state.tickets;
};

export const sponsors = (state) => {
  return state.sponsors;
};

export const faq = (state) => {
  return state.faq;
};
