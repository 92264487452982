<template>
  <v-card
    class="py-12"
    tile
    elevation="0"
    :min-height="settings.height ? settings.height : '300px'"
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit :sectionId="settings.id" v-if="$route.name === 'BodyEdit' && hover" />

    <title-component :settings="settingsTitle" />
    <v-container style="max-width: 1340px">
      <v-row class="my-12" no-gutters justify="center" align="center">
        <v-col
          class="px-2"
          v-for="(item, i) in settings.items"
          :cols="
            $vuetify.breakpoint.mdAndUp
              ? item.type !== 'submit'
                ? '4'
                : '2'
              : '12'
          "
          :key="i"
        >
          <div v-if="item.type === 'text'">
            <span class="item-title mb-4" :style="`color: white`">{{item.caption}}</span>
            <v-text-field
              :rules="[rules.required]"
              outlined
              color="grey"
              background-color="white"
              @input="updateInput($event, item.field)"
            ></v-text-field>
          </div>
          <div v-if="item.type === 'email'">
            <span class="item-title mb-4" :style="`color: white`">{{item.caption}}</span>
            <v-text-field
              :rules="[rules.required, rules.email]"
              outlined
              color="grey"
              background-color="white"
              @input="updateInput($event, item.field)"
            ></v-text-field>
          </div>
          <v-btn
            :class="$vuetify.breakpoint.mdAndUp ? 'mt-n2' : ''"
            elevation="0"
            dark
            v-if="item.type === 'submit'"
            width="100%"
            height="56"
            @click="submitNewsletter"
          >{{ item.caption }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import TitleComponent from "../title/TitleComponent.vue";
import MenuEdit from "../edit/MenuEdit";
export default {
  components: { TitleComponent, MenuEdit },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      newsletter: {},
      settingsTitle: null,
      rules: {
        required: (value) => !!value || "Campo obrigatório",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "E-mail Inválido.";
        },
      },
      hover: false,
    };
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },

  methods: {
    submitNewsletter() {},

    updateInput($event, type) {
      this.newsletter[type] = $event;
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: center;
}

b {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
}

.v-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
}
</style>