<template>
  <div>
    <v-row class="mt-5" no-gutters>
      <v-select
        background-color="white"
        outlined
        label="Ingresso"
        :item-text="formatedItemTicket"
        return-object
        :items="tickets.produtos"
        v-model="section['type-ticket']"
      ></v-select>
    </v-row>

    <b class="titlePanel">Texto</b>
    <form-text :form="section.text" :section="section" formType="text" />
    <color-picker
      class="mb-5"
      :colorSelected="section"
      type="layer-color"
      title="Cor de fundo do formulário"
    />
    <color-picker class="mb-5" :colorSelected="section" type="text-color" title="Cor de texto" />
    <color-picker class="mb-5" :colorSelected="section" type="button-color" title="Cor do botão" />
  </div>
</template>

<script>

import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";
import ColorPicker from '../media/ColorPicker.vue';
import FormText from "./FormText.vue";
export default {
  props: ["section"],
  components: { VueEditor, FormText, ColorPicker },
  data() {
    return {
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"],
      ],
      aligns: [
        { text: "Esquerda", value: "start" },
        { text: "Centralizado", value: "center" },
        { text: "Direita", value: "end" },
        { text: "Justificado", value: "justify" },
      ],
      editorSettings: {},
    };
  },
  computed: {
    ...mapGetters(["tickets"]),
  },
  methods: {
    formatedItemTicket: (item) => item.nome + " - " + item.nome_tipo,
  },
};
</script>

<style>
.text-message {
  background-color: white !important;
}
</style>