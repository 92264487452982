<template>
  <v-card
    tile
    v-if="settings"
    :color="settings['background-color']"
    elevation="0"
    class="py-6"
    height="100%"
    min-height="70px"
  >
    <v-row class="px-2" justify="center" align="center" no-gutters>
      <v-col align="center">
        <h1
          :style="`font-family: ${
            settings.title.font ? settings.title.font : ''
          }; font-size: ${
            settings.title['font-size']
              ? settings.title['font-size']
              : infos.layout.title['font-size']
          }; color: ${settings.title.color}`"
        >{{ settings.title ? settings.title.content : "" }}</h1>
        <b
          :style="
            settings.subtitle
              ? `font-family: ${
                  settings.subtitle.font ? settings.subtitle.font : ''
                }; font-size: ${
                  settings.subtitle['font-size']
                    ? settings.subtitle['font-size']
                    : infos.layout.subtitle['font-size']
                }; color: ${settings.subtitle.color}`
              : ''
          "
        >{{ settings.subtitle ? settings.subtitle.content : "" }}</b>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters(["infos"]),
  },
};
</script>

<style scoped>
div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: center;
}

b {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
}
</style>