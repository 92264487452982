<template>
  <div>
    <v-select
      outlined
      class="mt-3"
      background-color="white"
      type="number"
      label="Alinhamento do Texto"
      :items="aligns"
      v-model="section['text-align']"
    ></v-select>
  </div>
</template>

<script>
import SetImage from "../../media/SetImage.vue";
export default {
  components: { SetImage },
  props: {
    section: {
      type: Object,
    },
  },
  data() {
    return {
      aligns: [
        { text: "Esquerda", value: "start" },
        { text: "Centralizado", value: "center" },
        { text: "Direita", value: "end" },
        { text: "Justificado", value: "justify" },
      ],
    };
  },
  methods: {
    setMediaType(type) {
      this.section.media.type = type;
    },
  },
};
</script>

<style scoped>
.buttonIcon {
  width: 32px !important;
  height: 32px !important;
  color: white !important;
  border: 1px solid #dadde1 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}
</style>