<template>
  <div>
    <v-row v-if="selectedSection.type === 'speakers'" no-gutters>
      <v-text-field
        background-color="white"
        label="Quantidade Exibida"
        outlined
        hide-details
        type="number"
        v-model="selectedSection['max-speakers']"
      ></v-text-field>
    </v-row>
    <v-row>
      <v-col
        v-if="
          selectedSection.type === 'logos' ||
            (selectedSection.type === 'speakers' &&
              !selectedSection.overlappingName)
        "
      >
        <v-select
          background-color="white"
          outlined
          label="Tamanho da Imagem"
          item-text="name"
          item-value="value"
          hide-details
          :items="sizesImage"
          v-model="selectedSection['item-size']"
        ></v-select>
      </v-col>
      <v-col v-if="selectedSection.type === 'speakers'">
        <v-select
          background-color="white"
          outlined
          label="Arredondamento da Borda"
          item-text="name"
          hide-details
          item-value="value"
          :items="typesBorder"
          v-model="selectedSection['item-border']"
        ></v-select>
      </v-col>
    </v-row>

    <div
      v-if="
        selectedSection.type === 'speakers' && !selectedSection.overlappingName
      "
    >
      <v-row class="mt-6" no-gutters>
        <span class="titlePanel">Sombra</span>
      </v-row>

      <v-row class="mt-6" no-gutters>
        <v-text-field
          type="number"
          hide-details
          label="Distância"
          outlined
          background-color="white"
          v-model="selectedSection['distance']"
        ></v-text-field>
      </v-row>

      <color-picker
        class="mt-4"
        title="Cor"
        :colorSelected="selectedSection"
        type="shadow"
      />
    </div>

    <v-row v-if="selectedSection['layout']" class="mt-5" no-gutters>
      <v-select
        background-color="white"
        outlined
        label="Layout"
        item-text="name"
        item-value="value"
        :items="typesLayout"
        v-model="selectedSection['layout']"
        @change="changeTypeLayout"
      ></v-select>
    </v-row>

    <div v-if="selectedSection['layout'] === 'form'">
      <form-config-ticket :section="selectedSection" />
    </div>
    <v-row
      v-if="
        (selectedSection['max-columns'] &&
          selectedSection.type !== 'tickets') ||
          (selectedSection.type === 'tickets' &&
            selectedSection['layout'] === 'vertical')
      "
      class="mt-5"
      no-gutters
    >
      <v-select
        background-color="white"
        outlined
        label="Máximo de Colunas"
        item-text="name"
        item-value="value"
        :items="typesColumn"
        v-model="selectedSection['max-columns']"
      ></v-select>
    </v-row>

    <color-picker
      v-if="
        (selectedSection.type === 'tickets' &&
          selectedSection['layout'] !== 'form') ||
          selectedSection.type === 'schedule'
      "
      :class="selectedSection.type === 'schedule' ? 'mt-5' : 'mt-n3'"
      :title="
        selectedSection.type === 'schedule'
          ? 'Cor da Atividade'
          : 'Cor do ingresso'
      "
      :colorSelected="selectedSection"
      type="item-background-color"
    />
    <color-picker
      v-if="
        selectedSection.type === 'tickets' &&
          selectedSection['layout'] !== 'form'
      "
      class="mt-5"
      title="Cor do Botão"
      :colorSelected="selectedSection"
      type="button-color"
    />
    <color-picker
      v-if="
        selectedSection.type === 'numbers' ||
          selectedSection.type === 'schedule'
      "
      :class="selectedSection.type === 'schedule' ? 'mt-5' : 'mt-n5'"
      :title="
        selectedSection.type === 'schedule'
          ? 'Cor da Tab/Caixa de Seleção'
          : 'Cor do Número'
      "
      :colorSelected="selectedSection"
      type="accent-color"
    />

    <color-picker
      v-if="selectedSection.type === 'tickets'"
      class="mt-5"
      title="Cor de Texto do Botão"
      :colorSelected="selectedSection"
      type="text-button-color"
    />

    <v-row no-gutters class="mt-2" v-if="selectedSection.type === 'speakers'">
      <v-checkbox
        v-model="selectedSection.overlappingName"
        label="Nome sobreposto"
      ></v-checkbox>
    </v-row>

    <custom-tickets
      v-if="selectedSection['layout'] === 'custom'"
      :section="selectedSection"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ColorPicker from "../media/ColorPicker.vue";
import FormConfigTicket from "./FormConfigTicket.vue";
import CustomTickets from "./formsTickets/CustomTickets.vue";
export default {
  components: { ColorPicker, FormConfigTicket, CustomTickets },
  props: ["selectedSection"],

  data() {
    return {
      typesColumn: [2, 3, 4],
      typesLayout: [
        {
          name: "Horizontal",
          value: "horizontal",
        },
        {
          name: "Vertical",
          value: "vertical",
        },
        {
          name: "Formulário de Inscrição",
          value: "form",
        },
        {
          name: "Personalizado",
          value: "custom",
        },
      ],
      sizesImage: [
        { name: "Grande", value: "L" },
        { name: "Médio", value: "M" },
        { name: "Pequeno", value: "S" },
      ],
      typesBorder: [
        { name: "Circular", value: "circle" },
        { name: "Arredondada", value: "round" },
        { name: "Quadrada", value: "square" },
      ],
    };
  },

  computed: {
    ...mapGetters(["tickets"]),
  },

  methods: {
    changeTypeLayout() {
      if (this.selectedSection["layout"] === "form")
        this.selectedSection["type-ticket"] = this.tickets.produtos[0];
    },
  },
};
</script>

<style scoped>
.titlePanel {
  font-family: Inter !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #192b46;
}
</style>
