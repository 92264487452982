var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{class:_vm.$route.name === 'BodyEdit' ? 'editCard ' : 'app-bar-style',staticStyle:{"width":"100%"},attrs:{"color":_vm.settings['background-color'],"height":_vm.hover && _vm.$route.name === 'BodyEdit' ? '100px' : '80px',"elevation":"0","app":_vm.$route.name === 'BodyEdit' ? false : true},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.$route.name === 'BodyEdit' && _vm.hover)?_c('menu-edit',{staticClass:"menuEdit",attrs:{"sectionId":_vm.settings.id}}):_vm._e(),_c('div',{staticClass:"div-style"},[_c('v-row',{style:(("max-width: " + (_vm.$vuetify.breakpoint.mdAndUp ? '1340px' : '') + "; width: " + (_vm.$vuetify.breakpoint.mdAndUp ? '100%' : '100vw') + " !important")),attrs:{"justify":"space-around","align":"center"}},[_c('v-col',{attrs:{"cols":"2","align":_vm.$vuetify.breakpoint.mdAndUp ? '' : ''}},[_c('v-img',{attrs:{"width":_vm.$vuetify.breakpoint.mdAndUp ? '' : '200',"max-width":"100vw","height":_vm.settings.logo_height,"contain":"","eager":"","src":_vm.settings.logo}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"pr-12",attrs:{"cols":"10"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end","align":"center"}},_vm._l((_vm.settings.options),function(item,index){return _c('div',{key:index},[(
                  item.style !== 'button' &&
                    item.destination === 'Link Externo'
                )?_c('a',{staticClass:"mr-10 pointer black--text",staticStyle:{"text-decoration":"none"},attrs:{"href":item.destination === 'Link Externo' ? item.action : '',"target":"_blank"}},[_c('b',[_vm._v(_vm._s(item.caption))])]):_vm._e(),(
                  item.style !== 'button' &&
                    item.destination !== 'Link Externo'
                )?_c('b',{staticClass:"mr-10 pointer black--text",on:{"click":function($event){return _vm.actionButton(item)}}},[_vm._v(_vm._s(item.caption))]):_vm._e(),(item.style === 'button')?_c('v-btn',{staticClass:"mx-2",style:(("background-color: " + (item.color ? item.color : '') + " !important")),attrs:{"href":item.destination === 'Link Externo' ? item.action : '',"elevation":"0","min-width":"155px","target":"_blank","height":"44px","loading":_vm.oneCheckoutOpen},on:{"click":function($event){return _vm.actionButton(item)}}},[_c('b',{style:(("color: " + (item.textColor ? item.textColor : '') + " !important"))},[_vm._v(_vm._s(item.caption))])]):_vm._e()],1)}),0)],1):_vm._e(),(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"mr-2",attrs:{"cols":"2","align":"end"}},[_c('v-icon',{attrs:{"color":"blackIcon"},on:{"click":function($event){_vm.drawer = true}}},[_vm._v("las la-bars")])],1):_vm._e()],1)],1)],1),(_vm.drawer)?_c('v-navigation-drawer',{attrs:{"color":_vm.settings['background-color'],"right":"","fixed":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-icon',{staticClass:"mt-4 mr-4",attrs:{"color":"blackIcon"},on:{"click":function($event){_vm.drawer = false}}},[_vm._v("las la-close")])],1),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[(_vm.settings)?_c('v-list',_vm._l((_vm.settings.options),function(item,index){return _c('v-list-item',{key:index},[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[(
                item.style !== 'button' && item.destination === 'Link Externo'
              )?_c('a',{staticClass:"mr-10 pointer black--text",staticStyle:{"text-decoration":"none"},attrs:{"href":item.destination === 'Link Externo' ? item.action : '',"target":"_blank"}},[_c('b',[_vm._v(_vm._s(item.caption))])]):(
                item.style !== 'button' && item.destination !== 'Link Externo'
              )?_c('b',{staticClass:"pointer black--text",on:{"click":function($event){return _vm.actionButton(item)}}},[_vm._v(_vm._s(item.caption))]):_c('v-btn',{staticClass:"mt-5 ml-3",style:(("background-color: " + (item.color ? item.color : '') + " !important")),attrs:{"href":item.destination === 'Link Externo' ? item.action : '',"elevation":"0","width":"90%","height":"44px","dark":"","loading":_vm.oneCheckoutOpen},on:{"click":function($event){return _vm.actionButton(item)}}},[_c('b',{style:(("color: " + (item.textColor ? item.textColor : '') + " !important"))},[_vm._v(_vm._s(item.caption))])])],1)],1)}),1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }