<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <span v-on="on" style="cursor: pointer" class="primary--text"
          >Ver mais</span
        >
      </template>

      <v-card>
        <v-row justify="end" no-gutters>
          <v-btn class="mr-2 mt-2 mb-n11"
              style="background-color: white !important"
              icon
              @click="dialog = false"
              ><v-icon color="black">mdi-close</v-icon>
            </v-btn>
        </v-row>
        <v-card-title class="text-h5 mb-4">
          <v-row class="pr-6" no-gutters justify="space-between" align="center">
            <span>{{ item.nome }} - {{ item.nome_tipo }}</span>
          </v-row>
        </v-card-title>

        <v-card-text>
          <span style="white-space: pre-line">
            {{ item.observacoes ? item.observacoes : "" }}
          </span>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
