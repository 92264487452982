<template>
  <v-card>
    <v-toolbar elevation="0">
      <v-toolbar-title class="ml-2">
        <h1>Nova Área</h1>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-list nav dense>
        <v-list-item-group v-model="sectionSelected" color="primary">
          <v-row no-gutters>
            <v-col
              v-for="(item, i) in sectionsFiltered"
              :key="i"
              cols="6"
              class="pr-12"
            >
              <v-list-item
                :value="item.object"
                class="listItem py-2"
                color="#192B46"
              >
                <v-list-item-icon>
                  <v-icon color="#192B46">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    <b>{{ item.name }}</b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn class="buttonDialogClose" outlined @click="close">Cancelar</v-btn>
      <v-btn
        :disabled="!sectionSelected"
        class="buttonDialogAdd"
        outlined
        dark
        @click="addSection"
        >Adicionar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      sectionSelected: null,
      sections: [
        {
          name: "Menu",
          icon: "la-ellipsis-h",
          object: {
            "background-color": null,
            "background-type": "color",
            id: "menu",
            logo:
              "https://s3.amazonaws.com/blueticket-cdn/events/hotsites/1/SqCtBnMJRr8jqw91aA6QyD4JawTZ2oaLc0nSFrnp.png",
            options: [
              {
                caption: "Sobre",
                action: "#content-1",
                style: "text",
                destination: "Seção",
              },
              {
                caption: "Contato",
                action: "#contact",
                style: "text",
                destination: "Seção",
              },
              {
                caption: "Ingressos",
                action: "#tickets",
                style: "button",
                destination: "Seção",
              },
            ],
            type: "menu",
          },
        },
        {
          name: "Capa",
          icon: "la-stop",
          object: {
            "background-color": "#8F1111",
            "background-image":
              "https://s3.amazonaws.com/blueticket-cdn/events/hotsites/1/ptW874a5kb1rYPOjrUM8Nou2XFgHvni6dl558KlU.jpeg",
            "background-type": "image",
            "background-video":
              "https://cdn.blueticket.com.br/assets/institucional.mp4",
            button: { caption: "Inscreva-se agora!", action: "#tickets" },
            height: "600",
            id: "cover",
            logo: null,
            text: {
              content: "Data do evento",
              font: null,
              "font-size": null,
              color: null,
            },
            "text-position": "center",
            title: {
              content: "Nome do Evento",
              font: null,
              "font-size": null,
              color: null,
            },
            type: "cover",
          },
        },
        {
          name: "Conteúdo",
          icon: "la-align-left",
          object: {
            id: "content",
            type: "content",
            "button-color": null,
            "text-button-color": null,
            "background-type": "color",
            "background-color": "",
            "text-position": "",
            "responsive-priority": "",
            title: {
              content: "Conteúdo",
              font: "",
              "font-size": "",
              color: "",
            },
            text: { content: "", font: "", "font-size": "", color: "" },
            button: {
              caption: "",
              action: "",
              "background-color": "",
              color: "",
            },
            media: {
              type: "image",
              content: "",
            },
          },
        },
        {
          name: "Programação",
          icon: "la-calendar",
          object: {
            id: "schedule",
            type: "schedule",
            "background-type": "color",
            "background-image": "",
            "background-color": "",
            title: {
              content: "Programação",
              font: "",
              "font-size": "",
              color: "",
            },
            subtitle: { content: "", font: "", "font-size": "", color: "" },
            items: [],
            "item-background-color": "",
            "accent-color": "",
            external: true,
          },
        },
        {
          name: "Palestrantes",
          icon: "la-user-circle",
          object: {
            id: "speakers",
            type: "speakers",
            typeShow: "Todos",
            "background-type": "color",
            "background-color": "",
            "max-speakers": "24",
            "item-size": "M",
            shadow: "",
            "item-border": "round",
            title: {
              content: "Palestrantes",
              font: "",
              "font-size": "",
              color: "",
            },
            subtitle: { content: "", font: "", "font-size": "", color: "" },
            button: {
              caption: "Ver mais",
              action: "",
              "background-color": "",
              color: "",
            },
            items: [],
          },
        },
        {
          name: "Números",
          icon: "la-sort-numeric-down",
          object: {
            id: "numbers",
            type: "numbers",
            "background-type": "color",
            "background-image": "",
            "background-color": "",
            title: { content: "Números", font: "", "font-size": "", color: "" },
            subtitle: { content: "", font: "", "font-size": "", color: "" },
            "accent-color": "",
            "max-columns": 4,
            items: [],
          },
        },
        {
          name: "Depoimentos",
          icon: "la-id-card",
          object: {
            id: "testimonials",
            type: "testimonials",
            "background-type": "color",
            "background-image": "",
            "background-color": "",
            "max-columns": "",
            title: {
              content: "Depoimentos",
              font: "",
              "font-size": "",
              color: "",
            },
            subtitle: { content: "", font: "", "font-size": "", color: "" },
            items: [],
          },
        },
        {
          name: "Contato",
          icon: "la-sms",
          object: {
            id: "contact",
            type: "contact",
            "button-color": null,
            "text-button-color": null,
            "background-type": "color",
            "background-image": "",
            "background-color": "",
            "text-color": "black",
            title: { content: "Contato", font: "", "font-size": "", color: "" },
            subtitle: { content: "", font: "", "font-size": "", color: "" },
            text: { content: "", font: "", "font-size": "", color: "" },
            url: "",
            items: [
              { field: "nome", type: "text", caption: "Nome" },
              { field: "email", type: "email", caption: "E-mail" },
              { field: "phone", type: "phone", caption: "Telefone" },
              { field: "message", type: "long-text", caption: "Mensagem" },
              { field: "", type: "submit", caption: "Enviar" },
            ],
          },
        },
        {
          name: "Galeria de Imagens",
          icon: "la-image",
          object: {
            id: "gallery",
            type: "gallery",
            "background-type": "color",
            "background-image": "",
            "background-color": "",
            "text-color": "black",
            "max-columns": 1,
            title: {
              content: "Galeria de Imagens",
              font: "",
              "font-size": "",
              color: "",
            },
            items: [],
          },
        },
        {
          name: "Logomarcas",
          icon: "la-icons",
          object: {
            "background-color": null,
            "background-image": null,
            "background-type": "color",
            external: true,
            id: "logos",
            items: [],
            show_groups: [],
            title: {
              content: "Logomarcas",
              font: "",
              "font-size": "",
              color: "",
            },
            subtitle: { content: "", font: "", "font-size": "", color: "" },
            type: "logos",
          },
        },

        // Deixar comentado para casos especiais

        // {
        //   name: "Perguntas Frenquentes",
        //   icon: "la-question-circle",
        //   object: {
        //     id: "faq",
        //     type: "faq",
        //     "background-type": "color",
        //     "background-image": "",
        //     "background-color": "",
        //     title: {
        //       content: "Perguntas Frenquentes",
        //       font: "",
        //       "font-size": "",
        //       color: "",
        //     },
        //     subtitle: { content: "", font: "", "font-size": "", color: "" },
        //     items: [],
        //   },
        // },

        {
          name: "Perguntas Frenquentes",
          icon: "la-question-circle",
          object: {
            id: "personalizedFaq",
            type: "personalizedFaq",
            "background-type": "color",
            "background-image": "",
            "background-color": "",
            "item-color": "",
            "text-color": "",
            expanded: false,
            title: {
              content: "Perguntas Frenquentes",
              font: "",
              "font-size": "",
              color: "",
            },
            subtitle: { content: "", font: "", "font-size": "", color: "" },
            items: [],
          },
        },

        {
          name: "Ingressos",
          icon: "la-ticket-alt",
          object: {
            "background-color": null,
            "background-image": null,
            "background-type": "color",
            external: true,
            id: "tickets",
            "item-background-color": "#f5f5f5",
            items: [],
            "button-color": null,
            "text-button-color": null,
            "text-color": null,
            "layer-color": null,
            layout: "horizontal",
            "max-columns": 3,
            title: {
              content: "Ingressos",
              font: "",
              "font-size": "",
              color: "",
            },
            subtitle: { content: "", font: "", "font-size": "", color: "" },
            text: {
              content: "",
              font: "",
              "font-size": "",
              color: "",
              "text-align": "",
            },
            type: "tickets",
            "type-ticket": null,
          },
        },
        {
          name: "Cadastro em Campanha",
          icon: "la-wpforms",
          object: {
            id: "campaign",
            type: "campaign",
            "button-color": null,
            "text-button-color": null,
            "background-type": "color",
            "background-image": "",
            "background-color": "",
            "text-color": "black",
            title: {
              content: "Cadastro em Campanha",
              font: "",
              "font-size": "",
              color: "",
            },
            subtitle: { content: "", font: "", "font-size": "", color: "" },
            text: { content: "", font: "", "font-size": "", color: "" },
            url: "",
            items: [
              { field: "nome", type: "text", caption: "Nome" },
              { field: "email", type: "email", caption: "E-mail" },
              { field: "phone", type: "phone", caption: "Telefone" },
              { field: "", type: "submit", caption: "Enviar" },
            ],
          },
        },

        {
          name: "Materiais",
          icon: "la-newspaper",
          object: {
            id: "materials",
            type: "materials",
            "background-type": "color",
            "background-image": "",
            "background-color": "",
            "max-columns": "",
            "item-color": "",
            title: {
              content: "Materiais",
              font: "",
              "font-size": "",
              color: "",
            },
            subtitle: { content: "", font: "", "font-size": "", color: "" },
            items: [],
          },
        },

        {
          name: "Patrocinadores",
          icon: "la-users",
          object: {
            "background-color": null,
            "background-image": null,
            "background-type": "color",
            external: true,
            id: "sponsors",
            items: [],
            show_groups: [],
            title: {
              content: "Patrocinadores",
              font: "",
              "font-size": "",
              color: "",
            },
            subtitle: { content: "", font: "", "font-size": "", color: "" },
            type: "sponsors",
          },
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["infos", "dialogSection", "speakers"]),
    sectionsFiltered() {
      let hasMenu = this.infos.sections.find(
        (section) => section.type === "menu"
      );
      return this.sections.filter((section) =>
        hasMenu ? section.object.type !== "menu" : true
      );
    },
  },

  methods: {
    close() {
      this.$store.dispatch("setDialogSection", {
        open: false,
        selectedSection: null,
      });
    },

    async addSection() {
      if (this.sectionSelected.type === "speakers")
        this.sectionSelected.items = JSON.parse(JSON.stringify(this.speakers));

      this.sectionSelected.id = this.sectionSelected.id + `-${this.guid()}`;

      await this.formatLogosGroup();

      this.infos.sections.push(this.sectionSelected);

      this.moveElement();

      this.$store.dispatch("setDialogSection", {
        open: false,
        selectedSection: null,
      });

      this.$store.dispatch("setNav", {
        open: true,
        sectionId: this.sectionSelected.id,
      });
    },

    formatLogosGroup() {
      setTimeout(() => {
        if (this.sectionSelected.type === "logos") {
          this.sectionSelected.items.forEach((group) => {
            this.sectionSelected.show_groups.push(group.codigo);
          });
        }
      }, 0);
    },
    guid() {
      return (
        this.s4() +
        this.s4() +
        "-" +
        this.s4() +
        "-" +
        this.s4() +
        "-" +
        this.s4() +
        "-" +
        this.s4() +
        this.s4() +
        this.s4()
      );
    },
    s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    },
    moveElement() {
      let indexOld,
        indexNew,
        indexSection = 0;
      if (this.dialogSection && this.dialogSection.selectedSection)
        this.infos.sections.forEach((section, i) => {
          if (this.dialogSection.selectedSection === section.id)
            indexSection = i;
          indexOld = i;
        });
      indexNew = indexSection++;
      this.infos.sections.splice(
        indexNew,
        0,
        this.infos.sections.splice(indexOld, 1)[0]
      );
    },
  },
};
</script>

<style scoped>
.v-list-item--active {
  background-color: #e3eef9;
}

.buttonDialogAdd {
  text-transform: none;
  background-color: #0067af !important;
  height: 40px !important;
  width: 128px !important;
  border-radius: 56px !important;
  padding: 11px, 32px, 11px, 32px !important;
}

b {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #192b46;
}

h1 {
  font-size: 20px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #192b46;
}

.buttonDialogClose {
  text-transform: none;
  color: #0067af !important;
  background-color: transparent !important;
  height: 40px !important;
  width: 128px !important;
  border-radius: 56px !important;
  padding: 11px, 32px, 11px, 32px !important;
}
</style>
