<template>
  <v-navigation-drawer
    right
    fixed
    clipped
    permanent
    app
    width="400"
    overlay-color="transparent"
  >
    <v-toolbar height="56px" color="#3C4B62">
      <v-row justify="space-between" no-gutters align="center">
        <b class="titleToolbar white--text">
          {{ types[selectedSection.type] }}
        </b>
        <v-icon @click="closeNav" color="white">las la-close</v-icon>
      </v-row>
    </v-toolbar>

    <v-expansion-panels accordion>
      <!-- Configurações/Detalhes -->

      <v-expansion-panel v-if="selectedSection.type === 'config'">
        <v-expansion-panel-header>
          <b class="titlePanel">Detalhes</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-others :section="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Configurações/Layout -->

      <v-expansion-panel v-if="selectedSection.type === 'config'">
        <v-expansion-panel-header>
          <b class="titlePanel">Layout</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-layout :layout="selectedSection.layout" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Configurações/Integrations -->

      <v-expansion-panel v-if="selectedSection.type === 'config'">
        <v-expansion-panel-header>
          <b class="titlePanel">Integrações</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-integrations :integrations="selectedSection.integrations" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Logos/Items-->

      <v-expansion-panel v-if="selectedSection.type === 'logos'">
        <v-expansion-panel-header>
          <b class="titlePanel">Itens</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-items :section="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- FOOTER/ORGANIZAÇÃO -->

      <v-expansion-panel v-if="selectedSection.type === 'footer'">
        <v-expansion-panel-header>
          <b class="titlePanel">Organização</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <v-text-field
            class="mt-4"
            background-color="white"
            outlined
            label="Título"
            v-model="selectedSection.organization.title"
          ></v-text-field>
          <set-image type="organization" :section="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- FOOTER/CONTATOS -->

      <v-expansion-panel v-if="selectedSection.type === 'footer'">
        <v-expansion-panel-header>
          <b class="titlePanel">Contatos</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <v-text-field
            class="mt-4"
            background-color="white"
            outlined
            label="Título"
            v-model="selectedSection.contact.title"
          ></v-text-field>
          <v-text-field
            background-color="white"
            outlined
            label="Telefone"
            v-model="selectedSection.contact.phone"
          ></v-text-field>
          <v-text-field
            background-color="white"
            outlined
            label="Email"
            v-model="selectedSection.contact.email"
          ></v-text-field>
          <v-text-field
            background-color="white"
            outlined
            label="Site"
            v-model="selectedSection.contact.site"
          ></v-text-field>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- FOOTER/SOCIAL -->

      <v-expansion-panel v-if="selectedSection.type === 'footer'">
        <v-expansion-panel-header>
          <b class="titlePanel">Social</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <v-text-field
            class="mt-4"
            background-color="white"
            outlined
            label="Título"
            v-model="selectedSection.social.title"
          ></v-text-field>
          <v-text-field
            background-color="white"
            outlined
            label="Facebook"
            v-model="selectedSection.social.facebook"
          ></v-text-field>
          <v-text-field
            background-color="white"
            outlined
            label="Youtube"
            v-model="selectedSection.social.youtube"
          ></v-text-field>
          <v-text-field
            background-color="white"
            outlined
            label="Instagram"
            v-model="selectedSection.social.instagram"
          ></v-text-field>
          <color-picker :colorSelected="selectedSection" type="item-color" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- FOOTER/TEXT -->

      <v-expansion-panel v-if="selectedSection.type === 'footer'">
        <v-expansion-panel-header>
          <b class="titlePanel">Texto</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <color-picker :colorSelected="selectedSection" type="text-color" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Menu -->

      <v-expansion-panel v-if="selectedSection.type === 'menu'">
        <v-expansion-panel-header>
          <b class="titlePanel">Opções de Menu</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-menu
            :navigations="selectedSection.options"
            :sectionsId="sectionsId"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- TITULO -->

      <v-expansion-panel
        v-if="
          selectedSection.title &&
            selectedSection.type !== 'config' &&
            selectedSection.type !== 'logos'
        "
      >
        <v-expansion-panel-header>
          <b class="titlePanel">Título</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-text
            :form="selectedSection.title"
            :section="selectedSection"
            formType="title"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- SUBTITULO -->

      <v-expansion-panel
        v-if="
          selectedSection.subtitle &&
            selectedSection.type !== 'logos' &&
            selectedSection.type !== 'campaign'
        "
      >
        <v-expansion-panel-header>
          <b class="titlePanel">Subtitulo</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-text
            :form="selectedSection.subtitle"
            :section="selectedSection"
            formType="subtitle"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- TEXT -->

      <v-expansion-panel
        v-if="
          selectedSection.text &&
            selectedSection.type !== 'tickets' &&
            selectedSection.type !== 'speakers' &&
            selectedSection.type !== 'materials' &&
            selectedSection.type !== 'personalizedFaq' &&
            selectedSection.type !== 'sponsors'
        "
      >
        <v-expansion-panel-header>
          <b class="titlePanel">Texto</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-text
            :form="selectedSection.text"
            :section="selectedSection"
            formType="text"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- BOTAO  -->

      <v-expansion-panel v-if="selectedSection.button">
        <v-expansion-panel-header>
          <b class="titlePanel">Botão</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <v-text-field
            background-color="white"
            outlined
            label="Texto"
            v-model="selectedSection.button.caption"
          ></v-text-field>
          <v-row no-gutters>
            <color-picker
              class="mb-6 mt-n6 mr-6"
              :colorSelected="selectedSection"
              type="button-color"
            />
            <color-picker
              class="mb-6 mt-n6"
              :colorSelected="selectedSection"
              title="Cor de Texto"
              type="text-button-color"
            />
          </v-row>
          <v-select
            v-if="selectedSection.type !== 'speakers'"
            background-color="white"
            outlined
            type="number"
            label="Destino"
            :items="destinations"
            v-model="destination"
            @change="changeDestination"
          ></v-select>
          <v-select
            v-if="
              destination === 'Seção' && selectedSection.type !== 'speakers'
            "
            background-color="white"
            outlined
            label="Seção"
            item-text="title"
            item-value="id"
            :items="sectionsId"
            v-model="selectedSection.button.action"
          ></v-select>
          <v-text-field
            v-if="destination === 'Link Externo'"
            background-color="white"
            outlined
            label="Url"
            v-model="selectedSection.button.action"
          ></v-text-field>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- FUNDO -->

      <v-expansion-panel v-if="selectedSection.type !== 'config'">
        <v-expansion-panel-header>
          <b class="titlePanel">Fundo</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <v-row>
            <v-col v-if="selectedSection.type !== 'menu'">
              <v-text-field
                background-color="white"
                outlined
                type="number"
                label="Altura"
                v-model="selectedSection.height"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="
                selectedSection.type === 'cover' ||
                  selectedSection.type === 'content' ||
                  selectedSection.type === 'contact' ||
                  selectedSection.type === 'campaign'
              "
            >
              <v-select
                background-color="white"
                outlined
                type="number"
                label="Posição"
                item-text="name"
                item-value="value"
                :items="positions"
                v-model="selectedSection['text-position']"
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="selectedSection.type !== 'menu'" no-gutters>
            <v-btn
              @click="setBackground('color')"
              elevation="0"
              fab
              icon
              class="buttonIcon mr-4"
              color="red"
              :style="
                `background-color: ${
                  selectedSection['background-type'] !== 'color'
                    ? 'white'
                    : '#1976D2'
                } !important`
              "
            >
              <v-icon
                :color="
                  selectedSection['background-type'] === 'color'
                    ? 'white'
                    : 'darkBlue'
                "
                >la-brush</v-icon
              >
            </v-btn>
            <v-btn
              @click="setBackground('image')"
              elevation="0"
              fab
              class="buttonIcon mr-4"
              :style="
                `background-color: ${
                  selectedSection['background-type'] !== 'image'
                    ? 'white'
                    : '#1976D2'
                } !important`
              "
            >
              <v-icon
                :color="
                  selectedSection['background-type'] === 'image'
                    ? 'white'
                    : 'darkBlue'
                "
                >la-image</v-icon
              >
            </v-btn>
            <v-btn
              @click="setBackground('video')"
              elevation="0"
              fab
              v-if="selectedSection.type === 'cover'"
              :style="
                `background-color: ${
                  selectedSection['background-type'] !== 'video'
                    ? 'white'
                    : '#1976D2'
                } !important`
              "
              class="buttonIcon"
            >
              <v-icon
                :color="
                  selectedSection['background-type'] === 'video'
                    ? 'white'
                    : 'darkBlue'
                "
                >la-youtube</v-icon
              >
            </v-btn>
          </v-row>
          <color-picker
            v-if="selectedSection['background-type'] === 'color'"
            :class="selectedSection.type !== 'menu' ? '' : 'mt-5'"
            :colorSelected="selectedSection"
            type="background-color"
          />

          <v-text-field
            v-if="
              selectedSection['background-type'] === 'video' &&
                selectedSection.type === 'cover'
            "
            class="mt-4"
            background-color="white"
            outlined
            label="Url"
            v-model="selectedSection['background-video']"
          ></v-text-field>

          <set-image
            class="mt-4"
            v-if="selectedSection['background-type'] === 'image'"
            :section="selectedSection"
            type="background-image"
          />

          <color-picker
            v-if="
              selectedSection.type === 'materials' ||
                selectedSection.type === 'faq' ||
                selectedSection.type === 'personalizedFaq'
            "
            title="
              Cor dos itens
            "
            :class="'mt-5'"
            :colorSelected="selectedSection"
            type="item-color"
          />

          <color-picker
            v-if="
              selectedSection.type === 'faq' ||
                selectedSection.type === 'personalizedFaq'
            "
            title="
              Cor do texto
            "
            :class="'mt-5'"
            :colorSelected="selectedSection"
            type="text-color"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- ITEMS -->

      <v-expansion-panel
        v-if="
          selectedSection.type === 'speakers' ||
            selectedSection.type === 'numbers' ||
            selectedSection.type === 'tickets' ||
            selectedSection.type === 'schedule'
        "
      >
        <v-expansion-panel-header>
          <b class="titlePanel">{{
            selectedSection.type === "tickets" ? "Configuração" : "Itens"
          }}</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-config :selectedSection="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- FAQ Personalizado -->

      <v-expansion-panel v-if="selectedSection.type === 'personalizedFaq'">
        <v-expansion-panel-header>
          <b class="titlePanel">Layout</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <FormPersonalizedFAQ :section="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Sobre -->

      <v-expansion-panel v-if="selectedSection.type === 'content'">
        <v-expansion-panel-header>
          <b class="titlePanel">Mídia</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-about :section="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Logo -->

      <v-expansion-panel
        v-if="
          selectedSection.type === 'cover' || selectedSection.type === 'menu'
        "
      >
        <v-expansion-panel-header>
          <b class="titlePanel">Logo</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <set-image type="logo" :section="selectedSection" />
          <v-row no-gutters>
            <v-text-field
              outlined
              class="mt-3"
              background-color="white"
              type="number"
              label="Altura da Logo"
              v-model="selectedSection.logo_height"
            ></v-text-field>
          </v-row>
          <!-- v-model="selectedSection" -->
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- SPEAKERS -->

      <v-expansion-panel v-if="selectedSection.type === 'speakers'">
        <v-expansion-panel-header>
          <b class="titlePanel">Palestrantes</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-speakers :section="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- GALERIA -->

      <v-expansion-panel v-if="selectedSection.type === 'gallery'">
        <v-expansion-panel-header>
          <b class="titlePanel">Galeria de Imagens</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-gallery :section="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- FORMULARIO/CONTATO -->

      <v-expansion-panel
        v-if="
          selectedSection.type === 'contact' ||
            selectedSection.type === 'campaign'
        "
      >
        <v-expansion-panel-header>
          <b class="titlePanel">Formulário</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-contact-layer :section="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Depoimentos -->

      <v-expansion-panel v-if="selectedSection.type === 'testimonials'">
        <v-expansion-panel-header>
          <b class="titlePanel">Depoimentos</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-testionials :testionails="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Materiais -->

      <v-expansion-panel v-if="selectedSection.type === 'materials'">
        <v-expansion-panel-header>
          <b class="titlePanel">Materiais</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-materials :section="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- FAQ -->

      <v-expansion-panel v-if="selectedSection.type === 'faq'">
        <v-expansion-panel-header>
          <b class="titlePanel">Perguntas</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-faq :faqs="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Campanhas -->

      <v-expansion-panel v-if="selectedSection.type === 'campaign'">
        <v-expansion-panel-header>
          <b class="titlePanel">Campanhas</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-campaign :section="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Numeros -->

      <v-expansion-panel v-if="selectedSection.type === 'numbers'">
        <v-expansion-panel-header>
          <b class="titlePanel">Números</b>
          <template v-slot:actions>
            <v-icon color="darkBlue">la-angle-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="lightBlue">
          <form-numbers :numbers="selectedSection" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import FormText from "./forms/FormText.vue";
import ColorPicker from "./media/ColorPicker";
import SetImage from "./media/SetImage";
import FormSpeakers from "./forms/FormSpeakers";
import FormGallery from "./forms/FormGallery";
import FormTestionials from "./forms/FormTestionials";
import FormFaq from "./forms/FormFaq";
import FormNumbers from "./forms/FormNumbers.vue";
import FormMenu from "./forms/FormMenu";
import FormLayout from "./forms/formsDefault/FormLayout";
import FormIntegrations from "./forms/formsDefault/FormIntegrations.vue";
import FormOthers from "./forms/formsDefault/FormOthers.vue";
import FormItems from "./forms/formsLogos/FormItems.vue";
import FormAbout from "./forms/formsContent/FormAbout.vue";
import FormAlignText from "./forms/formsContent/FormAlignText.vue";
import FormConfigTicket from "./forms/FormConfigTicket.vue";
import FormContactLayer from "./forms/FormContactLayer.vue";
import FormCampaign from "./forms/FormCampaign.vue";
import FormConfig from "./forms/FormConfig.vue";
import FormMaterials from "./forms/FormMaterials.vue";
import FormPersonalizedFAQ from "./forms/FormPersonalizedFAQ.vue";

export default {
  components: {
    FormText,
    ColorPicker,
    SetImage,
    FormGallery,
    FormSpeakers,
    FormTestionials,
    FormFaq,
    FormNumbers,
    FormMenu,
    FormLayout,
    FormIntegrations,
    FormOthers,
    FormItems,
    FormAbout,
    FormAlignText,
    FormConfigTicket,
    FormContactLayer,
    FormCampaign,
    FormConfig,
    FormMaterials,
    FormPersonalizedFAQ,
  },
  data() {
    return {
      index: null,
      typeBackground: "color",
      types: {
        menu: "Menu",
        cover: "Capa",
        content: "Conteúdo",
        speakers: "Palestrantes",
        logos: "Logos",
        gallery: "Galeria",
        numbers: "Números",
        schedule: "Programação",
        tickets: "Ingressos",
        testimonials: "Depoimentos",
        newsletter: "Newsletter",
        faq: "Faq",
        contact: "Contato",
        footer: "Rodapé",
        config: "Configurações",
        campaign: "Cadastro em Campanha",
        sponsors: "Patrocinadores",
        materials: "Materiais",
        personalizedFaq: "Faq",
      },
      personTitle: false,
      selectedSection: false,
      fontsType: ["Inter", "Montserrat", "Roboto", "Work Sans"],
      positions: [],
      type: "hex",
      hex: "#ffffff",

      destinations: ["Seção", "Link Externo", "Abrir Checkout"],
      destination: "Seção",
    };
  },

  computed: {
    ...mapGetters(["navbar", "infos", "saveConfig", "tickets"]),
    color: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },
    showColor() {
      if (typeof this.color === "string") return this.color;

      return JSON.stringify(
        Object.keys(this.color).reduce((color, key) => {
          color[key] = Number(this.color[key].toFixed(2));
          return color;
        }, {}),
        null,
        2
      );
    },
    sectionsId() {
      let ids = [];
      this.infos.sections.forEach((section) => {
        ids.push({
          id: "#" + section.id,
          title: section.title ? section.title.content : section.type,
        });
      });
      return ids;
    },
  },

  created() {
    this.getSection();
  },

  watch: {
    navbar() {
      this.getSection();
    },
  },

  methods: {
    getSection() {
      if (this.navbar.sectionId === "config")
        this.selectedSection = {
          type: "config",
          title: this.infos.title,
          description: this.infos.description,
          layout: this.infos.layout,
          favicon: this.infos.favicon,
          integrations: this.infos.integrations,
        };
      else {
        this.infos.sections.forEach((section) => {
          if (section.id === this.navbar.sectionId)
            this.selectedSection = section;
        });
        if (
          this.selectedSection.type === "cover" ||
          this.selectedSection.type === "contact" ||
          this.selectedSection.type === "campaign"
        )
          this.positions = [
            { name: "Centro", value: "center" },
            { name: "Esquerda", value: "left" },
          ];
        if (this.selectedSection.type === "content")
          this.positions = [
            { name: "Centro", value: "center" },
            { name: "Direita", value: "right" },
            { name: "Esquerda", value: "left" },
          ];
        if (this.selectedSection["background-color"])
          this.typeBackground = "color";
        if (this.selectedSection["background-image"])
          this.typeBackground = "image";
        if (this.selectedSection["background-video"])
          this.typeBackground = "video";
      }
    },

    changeDestination() {
      if (this.destination === "Abrir Checkout")
        this.selectedSection.button.action = "Abrir Checkout";
      else this.selectedSection.button.action = "";
    },

    closeNav() {
      this.$store.dispatch("setNav", { open: false, sectionId: "" });
    },

    setBackground(type) {
      this.selectedSection["background-type"] = type;
    },
  },
};
</script>

<style scoped>
.font {
  font-family: Inter !important;
}

.titlePanel {
  font-family: Inter !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #192b46;
}

.titleToolbar {
  font-family: Inter !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: white;
}

.v-expansion-panel::before {
  box-shadow: none;
}

.cardColor {
  height: 40px;
  width: 130px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #a3aab5;
  box-sizing: border-box;
  border-radius: 4px;
}

.color {
  width: 32px;
  height: 32px;
  margin-left: 3px;
  margin-top: 3px;
  background: #ffffff;
  border: 1px solid #c8ccd3;
  box-sizing: border-box;
  border-radius: 4px;
}

.v-expansion-panel {
  margin-left: 2px;
}
.v-expansion-panel-header--active {
  background-color: #e3eef9;
}

.save {
  font-family: Inter !important;
  text-transform: none;
  padding: 11px 32px !important;
  width: 270px !important;
  height: 40px !important;
  border: 1px solid #1976d2 !important;
  box-sizing: border-box !important;
  border-radius: 100px !important;
  background-color: transparent !important;
  color: #1976d2 !important;
}

.buttonIcon {
  width: 32px !important;
  height: 32px !important;
  color: white !important;
  border: 1px solid #dadde1 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.text-message {
  background-color: white;
}

.textColor {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #192b46;
}
</style>
