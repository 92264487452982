<template>
  <div>
    <div v-for="(item, i) in faqs.items" :key="i">
      <v-row justify="center" no-gutters>
        <v-textarea
          background-color="white"
          outlined
          auto-grow
          label="Texto"
          v-model="item.question"
        ></v-textarea>
      </v-row>
      <v-row class="mt-n2" justify="center" no-gutters>
        <v-textarea
          background-color="white"
          outlined
          auto-grow
          label="Texto"
          v-model="item.answer"
        ></v-textarea>
      </v-row>
      <v-row class="mt-n6 mb-3" justify="center" no-gutters>
        <v-btn @click="remove(i)" class="remove" fab rounded elevation="0">
          <v-icon color="white">la-trash</v-icon>
        </v-btn>
      </v-row>
    </div>

    <v-row class="mt-4" justify="center" no-gutters outlined
      ><v-btn @click="add" class="add" elevation="0"
        >Nova Pergunta</v-btn
      ></v-row
    >
  </div>
</template>

<script>
export default {
  props: {
    faqs: {
      type: Object,
    },
  },

  methods: {
    remove(index) {
      this.faqs.items.splice(index, 1);
    },

    add() {
      this.faqs.items.push({
        question: "",
        answer: "",
      });
    },
  },
};
</script>

<style scoped>
.add {
  text-transform: none;
  padding: 11px 32px !important;
  width: 270px !important;
  height: 40px !important;
  border: 1px solid #1976d2 !important;
  box-sizing: border-box !important;
  border-radius: 100px !important;
  background-color: transparent !important;
  color: #1976d2 !important;
}

.remove {
  width: 44px !important;
  height: 44px !important;
  border-radius: 30px !important;
  background-color: #1976d2 !important;
  margin-top: 3px;
  color: white !important;
}
</style>
