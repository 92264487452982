<template>
  <div>
    <v-select
      background-color="white"
      outlined
      :items="campaings ? campaings.filter(c => c.tipo === 'V' || c.tipo === 'Y' || c.tipo === 'C') : []"
      item-text="nome"
      v-model="section.token"
      item-value="token"
      type="number"
      label="Campanhas"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ['section'],
  computed: {
    ...mapGetters(['campaings'])
  }
};
</script>
