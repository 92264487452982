<template>
  <div>
    <v-card
      :height="size[settings['item-size']] <= 150 ? '230' : '300'"
      width="250"
      color="transparent"
      elevation="0"
    >
      <v-card width="220" height="220" flat color="transparent" class="px-6">
        <div class="image-box">
          <v-img
            :style="
              `border-radius: ${radius[settings['item-border']]}; ${
                settings['shadow']
                  ? `box-shadow: ${settings['distance']}px ${settings['distance']}px 0 1px ${settings['shadow']};`
                  : ''
              }`
            "
            width="220"
            height="220"
            :src="item.url_avatar"
          >
            <div class="text-box" style="">
              <v-col>
                <v-row class="mt-3 pb-0" no-gutters justify="center">
                  <span class="titleCard">{{ item.nome }}</span>
                </v-row>

                <v-row class="mt-0" no-gutters justify="center">
                  <span class="subtitleCard"
                    >{{ item.cargo }} - {{ item.empresa }}</span
                  >
                </v-row>
              </v-col>
            </div>
          </v-img>
        </div>
      </v-card>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["item", "settings", "size", "radius"],
};
</script>

<style scoped>
.image-box {
  position: absolute;
  z-index: 2;
}

.text-box {
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    transparent,
    transparent 50%,
    rgba(0, 0, 0, 0.8)
  );
}
.titleCard {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.25px;
  text-align: center;
  color: white;
}

.subtitleCard {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-align: center;
  color: white;
}
</style>
