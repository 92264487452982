<template>
  <v-card
    tile
    v-if="settings"
    elevation="0"
    :height="settings.height ? settings.height : 'calc(100vh - 80px)'"
    :color="
      settings['background-type'] !== 'color'
        ? ''
        : settings['background-color']
    "
    :img="
      settings['background-type'] !== 'image'
        ? ''
        : settings['background-image']
    "
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit
      :sectionId="settings.id"
      class="edit"
      v-if="$route.name === 'BodyEdit' && hover"
    />
    <video
      v-if="
        settings['background-video'] && settings['background-type'] === 'video'
      "
      autoplay
      muted
      loop
      id="myVideo"
    >
      <source
        :src="
          settings['background-type'] === 'video'
            ? settings['background-video']
            : ''
        "
        type="video/mp4"
      />
    </video>
    <v-overlay
      :class="
        settings['text-position'] === 'center' || !$vuetify.breakpoint.mdAndUp
          ? ''
          : 'overlay'
      "
      absolute
      color="transparent"
    >
      <div
        :class="
          settings['text-position'] === 'center' || !$vuetify.breakpoint.mdAndUp
            ? 'center'
            : 'left'
        "
      >
        <v-row class="mb-10" no-gutters justify="center" align="center">
          <v-col
            :align="
              settings['text-position'] === 'center' ||
              !$vuetify.breakpoint.mdAndUp
                ? 'center'
                : 'start'
            "
          >
            <v-img
              v-if="settings.logo"
              class="mb-6"
              contain
              eager
              max-width="100vw"
              :height="settings.logo_height"
              :src="settings.logo"
            ></v-img>
            <h1
              class="mb-3"
              :style="
                `font-family: ${
                  settings.title['font'] ? settings.title['font'] : ''
                }; font-size: ${
                  settings.title['font-size']
                    ? settings.title['font-size']
                    : infos.layout.title['font-size']
                }; color: ${settings.title.color}; line-height: ${
                  settings.title['font-size']
                    ? settings.title['font-size']
                    : infos.layout.title['font-size']
                }`
              "
            >
              {{ settings.title.content }}
            </h1>
            <span v-html="formatedText"></span>
            <v-row
              class="mt-2"
              v-if="settings && settings.button"
              :justify="
                settings['text-position'] === 'center' ||
                !$vuetify.breakpoint.mdAndUp
                  ? 'center'
                  : 'start'
              "
              no-gutters
            >
              <v-btn
                v-if="settings.button.caption"
                :href="
                  settings.button.action.charAt(0) === '#'
                    ? ''
                    : settings.button.action === 'Abrir Checkout'
                    ? ''
                    : settings.button.action
                "
                target="_blank"
                elevation="0"
                height="44px"
                :style="
                  `background-color: ${
                    settings['button-color'] ? settings['button-color'] : ''
                  } !important`
                "
                :loading="oneCheckoutOpen"
                @click="actionButton"
                ><b
                  :style="
                    `color: ${
                      settings['text-button-color']
                        ? settings['text-button-color']
                        : ''
                    } !important`
                  "
                  >{{ settings.button.caption }}</b
                ></v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import MenuEdit from "../edit/MenuEdit";
export default {
  components: { MenuEdit },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      hover: false,
    };
  },

  computed: {
    ...mapGetters(["infos", "oneCheckoutOpen"]),
    formatedText() {
      let p = "</p>";
      if (this.settings.text.content)
        return this.settings.text.content
          .replaceAll(
            /<p>/g,
            `<span style="font-family: ${
              this.settings.text.font ? this.settings.text.font : ""
            } !important; font-size: ${
              this.settings.text["font-size"]
                ? this.settings.text["font-size"]
                : this.infos.layout.text["font-size"]
            }; color: ${this.settings.text.color}; line-height: ${
              this.settings.text["font-size"]
                ? this.settings.text["font-size"]
                : this.infos.layout.text["font-size"]
            };">`
          )
          .replace(/`${p}`/g, "</span>");
      else return "";
    },
  },

  methods: {
    emitScroll(item) {
      this.$emit("handleScrollTo", item.action);
    },

    actionButton() {
      if (
        this.settings.button.action &&
        this.settings.button.action.charAt(0) === "#"
      )
        this.$vuetify.goTo(this.settings.button.action);
      else if (this.settings.button.action === "Abrir Checkout")
        this.openCheckout();
    },
    openCheckout() {
      this.$store.dispatch("setOneCheckoutOpen", true);
    },
  },
};
</script>

<style scoped>
.center {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 100px;
}

span {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
}

h1 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0px;
}

.v-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
}

.edit {
  z-index: 999 !important;
}

.overlay {
  margin-right: 60vw;
}
.v-overlay {
  z-index: -1;
}
#myVideo {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
