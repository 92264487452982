var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.settings)?_c('v-card',{class:_vm.$route.name === 'BodyEdit' ? 'editCard' : '',attrs:{"tile":"","elevation":"0","min-height":_vm.settings.height ? _vm.settings.height : '300px',"color":_vm.settings['background-type'] === 'color'
      ? _vm.settings['background-color']
      : '',"img":_vm.settings['background-type'] === 'image'
      ? _vm.settings['background-image']
      : ''},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.$route.name === 'BodyEdit' && _vm.hover)?_c('menu-edit',{attrs:{"sectionId":_vm.settings.id}}):_vm._e(),_c('title-component',{attrs:{"settings":_vm.settingsTitle}}),_c('div',{staticClass:"cardList"},[_c('v-card',{attrs:{"elevation":"0","color":"transparent","max-width":"1340"}},[_c('v-row',{staticClass:"rowWidth my-12",attrs:{"no-gutters":"","justify":"center","align":"center"}},_vm._l((_vm.formattedSpeakers),function(item,i){return _c('v-list',{key:i,staticClass:"py-6",attrs:{"color":"transparent"}},[(
              !_vm.settings.overlappingName &&
                (_vm.settings.typeShow === 'Todos' || item.show)
            )?_c('v-card',{attrs:{"height":_vm.size[_vm.settings['item-size']] <= 150 ? '230' : '300',"width":"250","color":"transparent","elevation":"0"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-avatar',{style:(("border-radius: " + (_vm.radius[_vm.settings['item-border']]) + "; " + (_vm.settings['shadow']
                      ? ("box-shadow: " + (_vm.settings['distance']) + "px " + (_vm.settings['distance']) + "px 0 1px " + (_vm.settings['shadow']) + ";")
                      : ''))),attrs:{"width":_vm.size[_vm.settings['item-size']]
                    ? _vm.size[_vm.settings['item-size']]
                    : '150',"height":_vm.size[_vm.settings['item-size']]
                    ? _vm.size[_vm.settings['item-size']]
                    : '150'}},[_c('img',{attrs:{"src":item.url_avatar}})])],1),_c('v-row',{staticClass:"mt-3 pb-0",attrs:{"no-gutters":"","justify":"center"}},[_c('span',{staticClass:"titleCard",class:_vm.settings['text-color']},[_vm._v(_vm._s(item.nome))])]),_c('v-row',{staticClass:"mt-0 px-12",attrs:{"no-gutters":"","justify":"center"}},[_c('span',{staticClass:"subtitleCard",class:_vm.settings['text-color']},[_vm._v(_vm._s(item.cargo)+" - "+_vm._s(item.empresa))])])],1):_vm._e(),(
              _vm.settings.overlappingName &&
                (_vm.settings.typeShow === 'Todos' || item.show)
            )?_c('overlapping-name',{attrs:{"item":item,"settings":_vm.settings,"size":_vm.size,"radius":_vm.radius}}):_vm._e()],1)}),1),(_vm.speakers && _vm.speakers.length > _vm.showMoreQuantity)?_c('v-row',{staticClass:"mt-n10 mb-6",attrs:{"no-gutters":"","justify":"center"}},[_c('v-btn',{style:(("background-color: " + (_vm.settings['button-color'] ? _vm.settings['button-color'] : '') + " !important")),attrs:{"rounded":"","depressed":""},on:{"click":_vm.handleClickShowMore}},[_c('b',{style:(("color: " + (_vm.settings['text-button-color']
                  ? _vm.settings['text-button-color']
                  : '') + " !important"))},[_vm._v(_vm._s(_vm.settings.button.caption))])])],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }