
<template>
  <v-card
    class="pb-12"
    tile
    elevation="0"
    :min-height="settings.height ? settings.height : '300px'"
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit :sectionId="settings.id" v-if="$route.name === 'BodyEdit' && hover" />
    <title-component :settings="settingsTitle" />
    <div class="div-style">
      <v-row class="mx-12" no-gutters justify="center" align="center" style="max-width: 1340px">
        <v-col
          v-for="(item, i) in settings.items"
          :key="i"
          :cols="$vuetify.breakpoint.mdAndUp ? 12 / settings['max-columns'] : '6'"
          class="mt-12"
          align="center"
        >
          <v-row no-gutters justify="center">
            <h1 :style="`color: ${settings['accent-color']}`">{{ item.number }}</h1>
          </v-row>

          <v-row class="mt-1" no-gutters justify="center">
            <b :style="`color: ${settings.title.color}`">
              {{
              item.description
              }}
            </b>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import TitleComponent from "../title/TitleComponent.vue";
import MenuEdit from "../edit/MenuEdit";
export default {
  components: { TitleComponent, MenuEdit },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      settingsTitle: null,
      hover: false,
    };
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: center;
}

b {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
}

.div-style {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>