<template>
  <div>
    <v-row no-gutters>
      <v-text-field
        background-color="white"
        outlined
        label="Titúlo"
        v-model="infos.title"
        @input="save"
      ></v-text-field>
    </v-row>
    <v-row no-gutters>
      <v-textarea
        background-color="white"
        outlined
        label="Descrição"
        auto-grow
        v-model="infos.description"
      ></v-textarea>
    </v-row>
    <v-row no-gutters>
      <b>Favicon</b>
    </v-row>
    <v-row no-gutters>
      <set-image type="favicon" :section="infos" />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SetImage from "../../media/SetImage.vue";
export default {
  components: { SetImage },
  props: {
    section: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters(["infos", "saveConfig"]),
  },

  methods: {
    save() {
      this.saveConfig();
    },
  },
};
</script>

<style scoped>
* {
  font-family: Inter, sans-serif !important;
}
b {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #192b46;
}
</style>
