import Vue from "vue";
import Vuex from "vuex";


import Infos from "./modules/infos";
import Event from "./modules/event";
import Message from "./modules/message"
import Navbar from "./modules/navbar"
import DialogSection from "./modules/dialogSection"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Infos,
    Event,
    Message,
    Navbar,
    DialogSection
  }
});
