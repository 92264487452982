<template>
  <div>
    <v-row no-gutters>
      <span class="titleColor">Padrão</span>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-select
        background-color="white"
        outlined
        label="Fonte"
        :items="fontsType"
        v-model="layout.default.font"
        @change="save"
      ></v-select>
    </v-row>
    <v-row class="mt-n7 mb-5" no-gutters>
      <color-picker :colorSelected="layout.default" type="color" title="" />
    </v-row>
    <v-row no-gutters>
      <span class="titleColor">Título</span>
    </v-row>
    <v-row no-gutters
      ><v-row>
        <v-col cols="7">
          <v-select
            background-color="white"
            outlined
            label="Fonte"
            :items="fontsType"
            v-model="layout.title.font"
            @change="save"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            background-color="white"
            outlined
            type="number"
            label="Tamanho"
            @input="updateSize($event, layout.title)"
          ></v-text-field>
        </v-col> </v-row
    ></v-row>
    <v-row class="mt-n7 mb-5" no-gutters>
      <color-picker :colorSelected="layout.title" type="color" title="" />
    </v-row>
    <v-row no-gutters>
      <span class="titleColor">Subtitulo</span>
    </v-row>
    <v-row no-gutters
      ><v-row>
        <v-col cols="7">
          <v-select
            background-color="white"
            outlined
            label="Fonte"
            :items="fontsType"
            v-model="layout.subtitle.font"
            @change="save"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            background-color="white"
            outlined
            type="number"
            label="Tamanho"
            @input="updateSize($event, layout.subtitle)"
          ></v-text-field>
        </v-col> </v-row
    ></v-row>
    <v-row class="mt-n7 mb-5" no-gutters>
      <color-picker :colorSelected="layout.subtitle" type="color" title="" />
    </v-row>
    <v-row no-gutters>
      <span class="titleColor">Texto</span>
    </v-row>
    <v-row no-gutters
      ><v-row>
        <v-col cols="7">
          <v-select
            background-color="white"
            outlined
            label="Fonte"
            :items="fontsType"
            v-model="layout.text.font"
            @change="save"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            background-color="white"
            outlined
            type="number"
            label="Tamanho"
            @input="updateSize($event, layout.text)"
          ></v-text-field>
        </v-col> </v-row
    ></v-row>
    <v-row class="mt-n7 mb-5" no-gutters>
      <color-picker :colorSelected="layout.text" type="color" title="" />
    </v-row>
    <v-row no-gutters>
      <span class="titleColor">Botão</span>
    </v-row>
    <v-row no-gutters>
      <v-text-field
        background-color="white"
        outlined
        type="number"
        label="Arredondamento da Borda"
        v-model="borderRadius"
        @input="updateSize($event, layout.button, 'border')"
      ></v-text-field>
    </v-row>
    <v-row class="mt-n7 mb-5" no-gutters>
      <v-col>
        <color-picker
          :colorSelected="layout.button"
          type="color"
          title="Cor de Texto"
        />
      </v-col>
      <v-col>
        <color-picker
          :colorSelected="layout.button"
          type="background-color"
          title="Cor de Fundo"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ColorPicker from "../../media/ColorPicker.vue";
export default {
  components: { ColorPicker },
  props: {
    layout: {
      type: Object,
    },
  },

  data() {
    return {
      fontsType: ["Inter", "Montserrat", "Roboto", "Work Sans"],
      borderRadius: "",
    };
  },

  created() {
    this.formatBorderRadius();
  },

  computed: {
    ...mapGetters(["saveConfig"]),
  },

  methods: {
    formatBorderRadius() {
      this.borderRadius = this.layout.button["border-radius"].split("px")[0];
    },

    updateSize(event, type, border) {
      let model = "";
      model = event + "px";
      if (border === "border") type["border-radius"] = model;
      else type["font-size"] = model;
      this.save();
    },
    save() {
      this.saveConfig();
    },
  },
};
</script>

<style scoped>
* {
  font-family: Inter, sans-serif !important;
}

.titleColor {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #192b46;
}
</style>
