<template>
  <div>
    <v-row class="my-3" no-gutters>
      <span class="titleColor">{{ title }}</span>
    </v-row>
    <div class="cardColor">
      <v-row no-gutters align="center">
        <v-menu v-model="menu" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-bind="attrs"
              v-on="on"
              elevation="0"
              class="color"
              :color="getColor"
            ></v-card>
          </template>
          <v-color-picker
            mode="hexa"
            hide-mode-switch
            swatches-max-height="173"
            v-model="color"
            @input="changeColor($event)"
          ></v-color-picker>
        </v-menu>

        <span class="textColor darkBlue--text mt-1 ml-2">{{ getColor }}</span>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    colorSelected: { type: Object },
    model: { type: String, default: "" },
    type: { type: String },
    title: { type: String, default: "Cor" },
  },

  data() {
    return {
      hex: "#ffffff",
      menu: false,
    };
  },

  computed: {
    color: {
      get() {
        return this["hex"];
      },
      set(v) {
        this["hex"] = v;
      },
    },
    showColor() {
      if (typeof this.color === "string") return this.color;

      return JSON.stringify(
        Object.keys(this.color).reduce((color, key) => {
          color[key] = Number(this.color[key].toFixed(2));
          return color;
        }, {}),
        null,
        2
      );
    },
    getColor() {
      return this.type !== "model" ? this.colorSelected[this.type] : this.model;
    },

    ...mapGetters(["saveConfig"]),
  },

  watch: {
    menu() {
      if (this.menu && this.colorSelected[this.type]) {
        this.color = this.colorSelected[this.type];
      }
      if (!this.menu) this.save();
    },
  },

  mounted() {
    this.colorSelected[this.type] = this.colorSelected[this.type]
      ? JSON.parse(JSON.stringify(this.colorSelected[this.type]))
      : "";
  },

  methods: {
    changeColor(event) {
      if (this.type !== "model") this.colorSelected[this.type] = event;
      else this.$emit("changeColor", event);
    },

    save() {
      this.saveConfig();
    },
  },
};
</script>

<style scoped>
* {
  font-family: Roboto, sans-serif !important;
}
.titleColor {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #192b46 !important;
}

.cardColor {
  height: 40px;
  width: 130px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #a3aab5;
  box-sizing: border-box;
  border-radius: 4px;
}

.color {
  width: 32px;
  height: 32px;
  margin-left: 3px;
  margin-top: 3px;
  background: #ffffff;
  border: 1px solid #c8ccd3;
  box-sizing: border-box;
  border-radius: 4px;
}
</style>
