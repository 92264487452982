var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$route.name === 'BodyEdit' ? 'editCard' : '',attrs:{"min-height":_vm.settings.height ? _vm.settings.height : '300',"elevation":"0","tile":"","color":_vm.settings['background-type'] === 'color'
      ? _vm.settings['background-color']
      : '',"img":_vm.settings['background-type'] === 'image'
      ? _vm.settings['background-image']
      : ''},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.$route.name === 'BodyEdit' && _vm.hover)?_c('menu-edit',{attrs:{"sectionId":_vm.settings.id}}):_vm._e(),(_vm.settings.title && _vm.settings.title.content)?_c('title-component',{attrs:{"settings":_vm.settings}}):_vm._e(),_vm._l((_vm.groups),function(item,i){return _c('div',{key:i},[_c('div',[_c('title-component',{attrs:{"settings":item.settings}}),_c('v-container',{staticStyle:{"max-width":"1340px"}},[_c('v-row',{staticClass:"mx-12",attrs:{"justify":"center","align":"center"}},_vm._l((item.patrocinadores),function(sponsor,index){return _c('v-col',{key:index,class:_vm.$vuetify.breakpoint.mdAndUp ? 'pa-6' : '',attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp
                ? item.column
                  ? item.column
                  : '2'
                : item.sponsors && item.sponsors.length === 1
                ? '10'
                : '6',"align":"center"}},[_c('v-img',{attrs:{"height":"'50px'","width":"100","eager":"","contain":"","src":sponsor.url_logo}}),_c('v-row',{attrs:{"no-gutters":""}})],1)}),1)],1)],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }