export const SET_PRODUCTS = (state, products) => {
  state.products = products;
};

export const SET_PROGRAMS = (state, programs) => {
  state.programs = programs;
};

export const SET_INFOS = (state, infos) => {
  state.infos = infos;
};

export const SET_SPEAKERS = (state, speakers) => {
  state.speakers = speakers;
};

export const SET_LOGOS = (state, logos) => {
  state.logos = logos;
};

export const SET_SAVE_CONFIG = (state, saveConfig) => {
  state.saveConfig = saveConfig;
};

export const SET_IMAGE = (state, image) => {
  state.image = image;
};

export const SET_HOTSITE = (state, hotsite) => {
  state.hotsite = hotsite;
};

export const SET_SNACKBAR = (state, snackbar) => {
  state.snackbar = snackbar;
};

export const SET_CAMPAINGS = (state, campaings) => {
  state.campaings = campaings;
};

export const SET_LOADING = (state, loading) => {
  state.loading = loading;
};

export const SET_TICKETS = (state, tickets) => {
  state.tickets = tickets;
};

export const SET_SPONSORS = (state, sponsors) => {
  state.sponsors = sponsors;
};

export const SET_FAQ = (state, faq) => {
  state.faq = faq;
};
