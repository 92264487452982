<template>
  <v-card
    :min-height="settings.height ? settings.height : '500px'"
    elevation="0"
    tile
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    :class="$route.name === 'BodyEdit' ? 'editCard pb-12' : 'pb-12'"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit
      :sectionId="settings.id"
      v-if="$route.name === 'BodyEdit' && hover"
    />
    <title-component :settings="settingsTitle" />

    <layout-2
      v-if="settings.layout === 'Layout 2'"
      :formattedFaqs="formattedFaqs"
      :settings="settings"
    />

    <layout-1
      v-if="settings.layout === 'Layout 1'"
      :formattedFaqs="formattedFaqs"
      :settings="settings"
    />
  </v-card>
</template>

<script>
import TitleComponent from "../title/TitleComponent.vue";
import MenuEdit from "../edit/MenuEdit";
import { mapGetters } from "vuex";
import Layout2 from "./layouts/Layout2.vue";
import Layout1 from "./layouts/Layout1.vue";
export default {
  components: { TitleComponent, MenuEdit, Layout2, Layout1 },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      settingsTitle: null,
      hover: false,
      expandeds: [],
      formattedFaqs: [],
    };
  },

  computed: {
    ...mapGetters(["faq"]),
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },

  watch: {
    faq(v) {
      if (v) {
        this.formattedFaqs = this.faq.map((f) => {
          return { ...f, expandeds: [], groupExpandeds: [] };
        });
      }
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.25px;
  text-align: left;
}

span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}
</style>

<style>
.answer-html > p {
  margin: 0px !important;
  padding: 0px !important;
}
</style>
