<template>
  <div>
    <v-row v-for="(group, i) in section.items" :key="i" no-gutters>
      <v-checkbox
        :label="group.nome"
        v-model="group.show"
        :value="group.show"
        hide-details
        class="mt-2"
        @change="showGroup($event, group)"
      ></v-checkbox>
      <div class="mb-n4" v-if="group.show">
        <v-row class="mt-5" no-gutters>
          <v-col class="mr-2">
            <v-select
              background-color="white"
              outlined
              :label="`Font do título `"
              :items="fontsType"
              v-model="group.settings.title.font"
            ></v-select>
          </v-col>
          <v-col class="ml-2">
            <v-text-field
              background-color="white"
              outlined
              type="number"
              :label="`Tamanho do título`"
              :value="
                group.settings.title['font-size']
                  ? group.settings.title['font-size'].replace('px', '')
                  : ''
              "
              @input="updateSize($event, group.settings.title)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-n4" no-gutters>
          <v-col class="mr-2">
            <v-select
              background-color="white"
              outlined
              color="darkBlue"
              item-color="darkBlue"
              :label="`Tamanho da logo`"
              item-text="name"
              item-value="value"
              :items="sizesImage"
              v-model="group.tamanho"
            ></v-select>
          </v-col>
          <v-col class="ml-2">
            <v-select
              background-color="white"
              outlined
              color="darkBlue"
              item-color="darkBlue"
              label="Colunas"
              item-text="name"
              item-value="value"
              :items="columns"
              v-model="group.column"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    section: {
      type: Object,
    },
  },

  data() {
    return {
      fontsType: ["Inter", "Montserrat", "Roboto", "Work Sans"],
      sizesImage: [
        { name: "Grande", value: "G" },
        { name: "Médio", value: "M" },
        { name: "Pequeno", value: "P" },
      ],
      columns: [
        { name: 2, value: 6 },
        { name: 3, value: 4 },
        { name: 4, value: 3 },
        { name: 6, value: 2 },
      ],
    };
  },

  computed: {
    ...mapGetters(["logos"]),
  },

  mounted() {
    this.section.show_groups = [];
    this.section.items.forEach((group) => {
      if (group.show) this.section.show_groups.push(group.codigo);
    });
  },

  methods: {
    showGroup(event, group) {
      if (event) this.section.show_groups.push(group.codigo);
      else
        this.section.show_groups.forEach((code, i) => {
          if (code === group.codigo) this.section.show_groups.splice(i, 1);
        });
    },
    updateSize(event, item) {
      let model = "";
      model = event + "px";
      item["font-size"] = model;
    },
  },
};
</script>
