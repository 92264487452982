var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"py-12",class:_vm.$route.name === 'BodyEdit' ? 'editCard' : '',attrs:{"tile":"","elevation":"0","min-height":_vm.settings.height ? _vm.settings.height : '300px',"color":_vm.settings['background-type'] === 'color'
      ? _vm.settings['background-color']
      : '',"img":_vm.settings['background-type'] === 'image'
      ? _vm.settings['background-image']
      : ''},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.$route.name === 'BodyEdit' && _vm.hover)?_c('menu-edit',{attrs:{"sectionId":_vm.settings.id}}):_vm._e(),_c('title-component',{attrs:{"settings":_vm.settingsTitle}}),_c('v-container',{staticStyle:{"max-width":"1340px"}},[_c('v-row',{staticClass:"my-12",attrs:{"no-gutters":"","justify":"center","align":"center"}},_vm._l((_vm.settings.items),function(item,i){return _c('v-col',{key:i,staticClass:"px-2",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp
            ? item.type !== 'submit'
              ? '4'
              : '2'
            : '12'}},[(item.type === 'text')?_c('div',[_c('span',{staticClass:"item-title mb-4",style:("color: white")},[_vm._v(_vm._s(item.caption))]),_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"outlined":"","color":"grey","background-color":"white"},on:{"input":function($event){return _vm.updateInput($event, item.field)}}})],1):_vm._e(),(item.type === 'email')?_c('div',[_c('span',{staticClass:"item-title mb-4",style:("color: white")},[_vm._v(_vm._s(item.caption))]),_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.email],"outlined":"","color":"grey","background-color":"white"},on:{"input":function($event){return _vm.updateInput($event, item.field)}}})],1):_vm._e(),(item.type === 'submit')?_c('v-btn',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'mt-n2' : '',attrs:{"elevation":"0","dark":"","width":"100%","height":"56"},on:{"click":_vm.submitNewsletter}},[_vm._v(_vm._s(item.caption))]):_vm._e()],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }