<template>
  <v-card
    :min-height="settings.height ? settings.height : '300'"
    elevation="0"
    tile
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit
      :sectionId="settings.id"
      v-if="$route.name === 'BodyEdit' && hover"
    />
    <title-component
      v-if="settings.title && settings.title.content"
      :settings="settings"
    />

    <div v-for="(item, i) in groups" :key="i">
      <div>
        <title-component :settings="item.settings" />
        <v-container style="max-width: 1340px">
          <v-row class="mx-12" justify="center" align="center">
            <v-col
              v-for="(sponsor, index) in item.patrocinadores"
              :key="index"
              :cols="
                $vuetify.breakpoint.mdAndUp
                  ? item.column
                    ? item.column
                    : '2'
                  : item.sponsors && item.sponsors.length === 1
                  ? '10'
                  : '6'
              "
              align="center"
              :class="$vuetify.breakpoint.mdAndUp ? 'pa-6' : ''"
            >
              <v-img
                height="'50px'"
                width="100"
                eager
                contain
                :src="sponsor.url_logo"
              ></v-img>

              <v-row no-gutters> </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import MenuEdit from "../edit/MenuEdit";
import TitleComponent from "../title/TitleComponent.vue";
export default {
  components: { TitleComponent, MenuEdit },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      settingsTitle: null,
      sizeMdUp: {
        P: "35",
        M: "55",
        G: "85",
      },
      sizeMdDown: {
        P: "55",
        M: "85",
        G: "120",
      },
      hover: false,
    };
  },

  computed: {
    ...mapGetters(["sponsors"]),
    groups() {
      let mappedSponsors = [];

      if (this.sponsors)
        mappedSponsors = this.sponsors.map((s) => {
          return {
            ...s,
            settings: {
              title: {
                color: "",
                content: s.nome,
                font: "",
                "font-size": "22px",
              },
              subtitle: "",
              "background-color": "transparent",
            },
          };
        });

      return mappedSponsors;
    },
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },
};
</script>

<style scoped>
.div-style {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
