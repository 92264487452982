import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";

const state = {
  products: null,
  programs: null,
  speakers: null,
  logos: null,
  image: null,
  hotsite: null,
  loading: false,
  snackbar: {
    open: false,
    message: "",
    color: "white",
  },
  campaings: null,
  saveConfig: () => {},
  infos: null,
  tickets: null,
  sponsors: null,
  faq: null,
};

export default {
  state,
  actions,
  getters,
  mutations,
};
