<template>
  <div v-if="evento">
    <v-btn
      v-if="loaded"
      icon
      fixed
      class="close-checkout-button mt-2 mr-2"
      color="primaryText"
      @click="closeCheckout"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <iframe
      v-if="oneCheckoutOpen"
      id="one_checkout_iframe"
      :class="'fullscreen'"
      scrolling="no"
      :src="`${baseUrl}${evento}?ht=${getHt()}&c=${cupomCode}&fbc=${$cookies.get('_fbc')}&fbp=${$cookies.get('_fbp')}`"
      frameborder="0"
      height="100%"
      width="100%"
      @load="loadedIframe"
    ></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OneCheckout",
  props: {
    evento: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      baseUrl: "",
      ht: "",
    };
  },
  created() {
    this.baseUrl = process.env.VUE_APP_CHECKOUT;
  },
  computed: {
    ...mapGetters(["oneCheckoutOpen", "checkoutUrl", "infos"]),
    cupomCode() {
      if (new URL(window.location).searchParams.get('c')) return new URL(window.location).searchParams.get('c')
      return ''
    },
  },
  methods: {
    loadedIframe() {
      this.loaded = true;
    },
    closeCheckout() {
      this.$store.dispatch("setOneCheckoutOpen", false);
      this.loaded = false;
    },
    getHt(){
      let ht = localStorage.getItem("ht")
      return ht
    }
  },
};
</script>

<style>
#one_checkout_iframe {
  position: fixed;
  top: 0;
  width: 100%;
  height: 0%;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
#one_checkout_iframe.fullscreen {
  height: 100%;
}
#one_checkout_iframe.hidescreen {
  height: 0%;
}

.close-checkout-button {
  top: 0;
  right: 0;
  z-index: 10000;
}
</style>
