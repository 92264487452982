<template>
  <v-card
    v-if="settings"
    tile
    elevation="0"
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    :min-height="settings.height ? settings.height : '300px'"
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit
      :sectionId="settings.id"
      v-if="$route.name === 'BodyEdit' && hover"
    />
    <title-component :settings="settingsTitle" />
    <div class="cardList">
      <v-card elevation="0" color="transparent" max-width="1340">
        <v-row
          class="rowWidth my-12"
          no-gutters
          justify="center"
          align="center"
        >
          <v-list
            class="py-6"
            color="transparent"
            v-for="(item, i) in formattedSpeakers"
            :key="i"
          >
            <v-card
              v-if="
                !settings.overlappingName &&
                  (settings.typeShow === 'Todos' || item.show)
              "
              :height="size[settings['item-size']] <= 150 ? '230' : '300'"
              width="250"
              color="transparent"
              elevation="0"
            >
              <v-row no-gutters justify="center" align="center">
                <v-avatar
                  :width="
                    size[settings['item-size']]
                      ? size[settings['item-size']]
                      : '150'
                  "
                  :height="
                    size[settings['item-size']]
                      ? size[settings['item-size']]
                      : '150'
                  "
                  :style="
                    `border-radius: ${radius[settings['item-border']]}; ${
                      settings['shadow']
                        ? `box-shadow: ${settings['distance']}px ${settings['distance']}px 0 1px ${settings['shadow']};`
                        : ''
                    }`
                  "
                >
                  <img :src="item.url_avatar" />
                </v-avatar>
              </v-row>

              <v-row class="mt-3 pb-0" no-gutters justify="center">
                <span class="titleCard" :class="settings['text-color']">{{
                  item.nome
                }}</span>
              </v-row>

              <v-row class="mt-0 px-12" no-gutters justify="center">
                <span class="subtitleCard" :class="settings['text-color']"
                  >{{ item.cargo }} - {{ item.empresa }}</span
                >
              </v-row>
            </v-card>

            <overlapping-name
              v-if="
                settings.overlappingName &&
                  (settings.typeShow === 'Todos' || item.show)
              "
              :item="item"
              :settings="settings"
              :size="size"
              :radius="radius"
            />
          </v-list>
          <!-- </v-col> -->
        </v-row>
        <v-row
          v-if="speakers && speakers.length > showMoreQuantity"
          no-gutters
          justify="center"
          class="mt-n10 mb-6"
          ><v-btn
            :style="
              `background-color: ${
                settings['button-color'] ? settings['button-color'] : ''
              } !important`
            "
            rounded
            depressed
            @click="handleClickShowMore"
            ><b
              :style="
                `color: ${
                  settings['text-button-color']
                    ? settings['text-button-color']
                    : ''
                } !important`
              "
              >{{ settings.button.caption }}</b
            >
          </v-btn></v-row
        >
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import MenuEdit from "../edit/MenuEdit";
import TitleComponent from "../title/TitleComponent.vue";
import OverlappingName from "./layout/OverlappingName.vue";
export default {
  components: { TitleComponent, MenuEdit, OverlappingName },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      settingsTitle: null,
      radius: {
        circle: "50%",
        round: "10%",
        square: "0%",
      },
      size: {
        S: "100",
        M: "150",
        L: "200",
      },
      hover: false,
      showAll: false,
      showMoreQuantity: 0,
    };
  },

  computed: {
    ...mapGetters(["speakers", "infos"]),

    formattedSpeakers() {
      let speakers = [];

      if (
        !this.settings.typeShow ||
        this.settings.typeShow === "" ||
        this.settings.typeShow === "Todos"
      )
        speakers = JSON.parse(JSON.stringify(this.speakers));

      if (
        this.settings.items &&
        this.settings.typeShow &&
        this.settings.typeShow === "Específicos"
      ) {
        speakers = this.settings.items.filter((s) => {
          return s.show;
        });
      }

      if (Number(this.settings["max-speakers"])) {
        let quantityToSlice = Number(this.settings["max-speakers"]);

        if (this.showMoreQuantity) quantityToSlice = this.showMoreQuantity;

        return speakers.slice(0, quantityToSlice);
      }

      return speakers;
    },

    maxSpeakers() {
      return Number(this.settings["max-speakers"]);
    },
  },

  created() {
    this.showMoreQuantity = Number(this.settings["max-speakers"]);

    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },

  methods: {
    handleClickShowMore() {
      this.showMoreQuantity += Number(this.settings["max-speakers"]);
    },
  },

  watch: {
    speakers(v) {
      if (v) {
        let copyItems = JSON.parse(JSON.stringify(this.settings.items));
        this.settings.items = JSON.parse(JSON.stringify(this.speakers));
        copyItems.forEach((copy) => {
          this.settings.items.forEach((item) => {
            if (copy.codigo === item.codigo && !copy.show) item.show = false;
          });
        });
      }
    },

    maxSpeakers(v) {
      this.showMoreQuantity = v;
    },
  },
};
</script>

<style scoped>
.cardList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rowWidth {
  width: 100%;
}

.titleCard {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.25px;
  text-align: center;
}

.subtitleCard {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-align: center;
}
</style>
