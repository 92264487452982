<template>
  <v-card
    class="pb-12"
    tile
    elevation="0"
    :min-height="settings.height ? settings.height : '500px'"
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit :sectionId="settings.id" v-if="$route.name === 'BodyEdit' && hover" />

    <title-component :settings="settingsTitle" />
    <v-container style="max-width: 1340px">
      <v-row no-gutters justify="space-around" align="center">
        <v-col
          v-for="(item, i) in settings.items"
          :key="i"
          :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
          class="px-12 py-12"
          align="center"
        >
          <v-row justify="start" no-gutters>
            <span class="spanContent">{{ item.text }}</span>
          </v-row>
          <v-row justify="start" class="mb-1" no-gutters>
            <b>{{ item.name }}</b>
          </v-row>
          <v-row justify="start" class="mt-n1" no-gutters>
            <span>{{ item.company }}</span>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import TitleComponent from "../title/TitleComponent.vue";
import MenuEdit from "../edit/MenuEdit";
export default {
  components: { TitleComponent, MenuEdit },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      settingsTitle: null,
      hover: false,
    };
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },
};
</script>

<style scoped>
h3,
b,
span {
  text-align: start;
}

.spanContent {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.25px;
}

b {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
}

span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
}
</style>