<template>
  <v-card
    tile
    elevation="0"
    :min-height="$vuetify.breakpoint.mdAndUp ? '160px' : '400'"
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit
      :sectionId="settings.id"
      v-if="$route.name === 'BodyEdit' && hover"
    />
    <v-container style="max-width: 1340px">
      <v-row class="mx-12 my-12" no-gutters justify="center" align="center">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
          <v-row
            :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
            no-gutters
          >
            <h3 :style="`color: ${settings['text-color']}`">
              {{ settings.organization.title }}
            </h3>
          </v-row>
          <v-row
            :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
            no-gutters
          >
            <v-card
              elevation="0"
              tile
              color="transparent"
              v-for="(image, i) in settings.organization.logos"
              :key="i"
              class="mr-2"
            >
              <v-img
                eager
                max-width="125"
                contain
                :src="image.image"
              ></v-img>
            </v-card>
          </v-row>
        </v-col>
        <v-col
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-12'"
          :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'"
        >
          <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'">
            <h3 :style="`color: ${settings['text-color']}`">
              {{ settings.contact.title }}
            </h3>
          </v-row>
          <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'">
            <span :style="`color: ${settings['text-color']}`">
              {{ settings.contact.phone }}
            </span>
          </v-row>
          <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'">
            <span :style="`color: ${settings['text-color']}`">
              {{ settings.contact.email }}
            </span>
          </v-row>
          <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'">
            <span :style="`color: ${settings['text-color']}`">
              {{ settings.contact.site }}
            </span>
          </v-row>
        </v-col>
        <v-col
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-12'"
          :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'"
        >
          <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'">
            <h3 :style="`color: ${settings['text-color']}`">
              {{ settings.social.title }}
            </h3>
          </v-row>
          <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'">
            <a
              v-if="settings.social.facebook"
              :href="handleHref(settings.social.facebook, 'face')"
              target="_blank"
            >
              <v-icon class="mt-3 mr-2 pointer" :color="settings['item-color']"
                >las la-facebook</v-icon
              >
            </a>

            <a
              v-if="settings.social.instagram"
              target="_blank"
              :href="handleHref(settings.social.instagram, 'insta')"
            >
              <v-icon
                class="mt-2 mr-2 pointer"
                large
                :color="settings['item-color']"
                >las la-instagram</v-icon
              >
            </a>

            <a
              v-if="settings.social.youtube"
              target="_blank"
              :href="handleHref(settings.social.youtube, 'youtube')"
            >
              <v-icon
                class="mt-2 mr-2 pointer"
                large
                :color="settings['item-color']"
                >las la-youtube</v-icon
              >
            </a>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import MenuEdit from "../edit/MenuEdit";
export default {
  components: { MenuEdit },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      hover: false,
    };
  },

  methods: {
    handleHref(item, type) {
      if (type === "face") return `https://www.facebook.com/${item}/`;
      if (type === "insta") return `https://www.instagram.com/${item}/`;
      else return `https://www.youtube.com/${item}/`;
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.25px;
  text-align: left;
}

a {
  border: none;
  text-decoration: none;
}

span {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.25px;
  text-align: left;
}
</style>
