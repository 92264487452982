import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";

const state = {
  message: null
};

export default {
  state,
  actions,
  getters,
  mutations
};
