<template>
  <div>
    <v-row no-gutters>
      <v-text-field
        background-color="white"
        outlined
        label="Google Tag Manager"
        v-model="integrations['google-tag-manager']"
      ></v-text-field>
    </v-row>
    <v-row no-gutters>
      <v-text-field
        background-color="white"
        outlined
        label="Google Analytics"
        v-model="integrations['google-analytics']"
      ></v-text-field>
    </v-row>
    <v-row no-gutters>
      <v-text-field
        background-color="white"
        outlined
        label="Facebook Pixel"
        v-model="integrations['facebook-pixel']"
      ></v-text-field>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    integrations: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters(["infos", "saveConfig"]),
  },

  created() {
    this.integrations;
  },

  methods: {
    save() {
      this.saveConfig();
    },
  },
};
</script>

<style scoped>
* {
  font-family: Inter, sans-serif !important;
}
.titleColor {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #192b46;
}
</style>
