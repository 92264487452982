<template>
  <v-card class="pa-10">
    <v-row class="mb-3" justify="center" no-gutters
      ><h3>Mensagem Enviada</h3>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-6" justify="center" no-gutters>
      <v-col cols="10" align="center">
        <b>{{ message ? message.message : "Erro Inesperado" }}</b></v-col
      ></v-row
    >
    <v-row justify="center" no-gutters
      ><v-btn
        @click="closeDialog"
        class="mt-5"
        elevation="0"
        width="155px"
        height="44px"
        dark
        ><b>Fechar</b></v-btn
      ></v-row
    >
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    closeDialog() {
      this.$emit("close");
    },
  },

  computed: {
    ...mapGetters(["message"]),
  },
};
</script>