<template>
  <v-card
    :min-height="settings.height ? settings.height : '300'"
    elevation="0"
    tile
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit
      :sectionId="settings.id"
      v-if="$route.name === 'BodyEdit' && hover"
    />
    <div v-for="(item, i) in groups" :key="i">
      <div v-if="item.show">
        <title-component :settings="item.settings" />
        <v-container style="max-width: 1340px">
          <v-row class="mx-12 my-12" justify="center">
            <v-col
              v-for="(logo, index) in item.logos"
              :key="index"
              :cols="
                $vuetify.breakpoint.mdAndUp
                  ? item.column
                    ? item.column
                    : '2'
                  : item.logos && item.logos.length === 1
                  ? '10'
                  : '6'
              "
              align="center"
              :class="$vuetify.breakpoint.mdAndUp ? 'pa-6' : ''"
            >
              <v-img
                :height="
                  $vuetify.breakpoint.mdAndUp ? sizeMdUp[item.tamanho] : '100px'
                "
                eager
                contain
                :src="logo.url_imagem"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import MenuEdit from "../edit/MenuEdit";
import TitleComponent from "../title/TitleComponent.vue";
export default {
  components: { TitleComponent, MenuEdit },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      settingsTitle: null,
      sizeMdUp: {
        P: "35",
        M: "55",
        G: "85",
      },
      sizeMdDown: {
        P: "55",
        M: "85",
        G: "120",
      },
      hover: false,
    };
  },

  computed: {
    ...mapGetters(["logos"]),
    groups() {
      this.settings.items = this.settings.items.length
        ? this.settings.items
        : JSON.parse(JSON.stringify(this.logos));
      if (this.settings.items)
        this.settings.items.forEach((group) => {
          let have = false;
          if (!group.column) group.column = 2;
          this.settings.show_groups.forEach((show) => {
            if (show === group.codigo) have = true;
          });
          group.show = have;
        });
      return this.settings.items;
    },
  },

  mounted() {
    setTimeout(() => {
      if (this.settings.items && this.settings.items.length) {
        this.logos.forEach((item2) => {
          let have = false;
          this.settings.items.forEach((item) => {
            if (item2.codigo === item.codigo) {
              item.logos = item2.logos;
              have = true;
            }
          });
          if (!have) this.settings.items.push(item2);
        });
        this.settings.items.forEach((item, i) => {
          let have2 = false;
          this.logos.forEach((item2) => {
            if (item.codigo === item2.codigo) {
              have2 = true;
            }
          });
          if (!have2) this.settings.items.splice(i, 1);
        });
      }
    }, 1000);
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },
};
</script>

<style scoped>
.div-style {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
