<template>
  <div>
    <div v-for="(item, i) in navigations" :key="i">
      <v-row justify="center" no-gutters>
        <v-text-field
          background-color="white"
          outlined
          auto-grow
          label="Texto"
          v-model="item.caption"
        ></v-text-field>
      </v-row>
      <v-select
        background-color="white"
        outlined
        type="number"
        label="Destino"
        :items="destinations"
        v-model="item.destination"
        @change="clear(item)"
      ></v-select>
      <v-select
        v-if="item.destination === 'Seção'"
        background-color="white"
        outlined
        label="Seção"
        item-text="title"
        item-value="id"
        :items="filteredSections"
        v-model="item.action"
      ></v-select>
      <v-text-field
        v-if="item.destination === 'Link Externo'"
        background-color="white"
        outlined
        label="Url"
        v-model="item.action"
      ></v-text-field>
      <v-row class="mt-n2" justify="center" no-gutters>
        <v-select
          background-color="white"
          outlined
          label="Estilo"
          item-text="name"
          item-value="value"
          :items="styles"
          v-model="item.style"
        ></v-select>
      </v-row>
      <v-row v-if="item.style === 'button'" class="mt-n3 mb-10" no-gutters>
        <v-col>
          <span class="titleColor">Cor de fundo</span>
          <div class="cardColor mt-2">
            <v-row no-gutters align="center" class=" mb-10">
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    class="color"
                    :color="item.color"
                  ></v-card>
                </template>
                <v-color-picker
                  mode="hexa"
                  hide-mode-switch
                  swatches-max-height="173"
                  v-model="color"
                  @input="changeColor(item, $event)"
                ></v-color-picker>
              </v-menu>

              <span class="textColor mt-1 ml-2">{{ item.color }}</span>
            </v-row>
          </div>
        </v-col>
        <v-col>
          <span class="titleColor">Cor de Texto</span>
          <div class="cardColor mt-2">
            <v-row no-gutters align="center" class="mb-10">
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    class="color"
                    :color="item.textColor"
                  ></v-card>
                </template>
                <v-color-picker
                  mode="hexa"
                  hide-mode-switch
                  swatches-max-height="173"
                  v-model="color"
                  @input="changeTextColor(item, $event)"
                ></v-color-picker>
              </v-menu>

              <span class="textColor mt-1 ml-2">{{ item.textColor }}</span>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-n6 mb-3" justify="center" no-gutters>
        <v-btn @click="remove(i)" class="remove" fab rounded elevation="0">
          <v-icon color="white">la-trash</v-icon>
        </v-btn>
      </v-row>
    </div>

    <v-row class="mt-4" justify="center" no-gutters outlined>
      <v-btn @click="add" class="add" elevation="0">Novo Navegador</v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    navigations: {
      type: Array,
    },
    sectionsId: {
      type: Array,
    },
  },

  data() {
    return {
      styles: [
        { name: "Texto", value: "text" },
        { name: "Botão", value: "button" },
      ],
      hex: "#ffffff",
      type: "hex",
      destinations: ["Seção", "Link Externo", "Abrir Checkout"],
    };
  },

  created() {
    this.setDestination();
  },

  computed: {
    color: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },
    textColor: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },
    showColor() {
      if (typeof this.color === "string") return this.color;

      return JSON.stringify(
        Object.keys(this.color).reduce((color, key) => {
          color[key] = Number(this.color[key].toFixed(2));
          return color;
        }, {}),
        null,
        2
      );
    },

    filteredSections() {
      return this.sectionsId.filter(
        (s) => s.id !== "#menu" && s.id !== "#footer"
      );
    },
  },

  methods: {
    setDestination() {
      this.navigations.map((navigation) => {
        if (navigation.action && navigation.action.charAt(0) === "#")
          navigation.destination = "Seção";
        else navigation.destination = "Link Externo";
      });
    },

    remove(index) {
      this.navigations.splice(index, 1);
    },

    clear(item) {
      if (this.destination === "Abrir Checkout") item.action = "Abrir Checkout";
      else item.action = "";
    },

    add() {
      this.navigations.push({
        caption: "",
        action: "",
        style: "",
        color: "",
        textColor: "",
      });
    },

    changeColor(item, event) {
      item.color = event;
    },
    changeTextColor(item, event) {
      item.textColor = event;
    },
  },
};
</script>

<style scoped>
.add {
  text-transform: none;
  padding: 11px 32px !important;
  width: 270px !important;
  height: 40px !important;
  border: 1px solid #1976d2 !important;
  box-sizing: border-box !important;
  border-radius: 100px !important;
  background-color: transparent !important;
  color: #1976d2 !important;
}

.cardColor {
  height: 40px;
  width: 130px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #a3aab5;
  box-sizing: border-box;
  border-radius: 4px;
}

.titleColor {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #192b46 !important;
}

.remove {
  width: 44px !important;
  height: 44px !important;
  border-radius: 30px !important;
  background-color: #1976d2 !important;
  margin-top: 3px;
}

.color {
  width: 32px;
  height: 32px;
  margin-left: 3px;
  margin-top: 3px;
  background: #ffffff;
  border: 1px solid #c8ccd3;
  box-sizing: border-box;
  border-radius: 4px;
}
</style>
