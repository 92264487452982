export const SET_EVENT = (state, data) => {
  state.event = data
}

export const SET_ONE_CHECKOUT_OPEN = (state, value) => {
  state.oneCheckoutOpen = value
}

export const SET_ALL_EVENT_PLACES = (state, value) => {
  state.eventPlaces = value
}

