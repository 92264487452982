var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('div',[(
      (_vm.section.type === 'content' && _vm.formType === 'text') ||
        (_vm.section.type === 'tickets' && _vm.formType === 'text')
    )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-select',{staticClass:"my-2",attrs:{"outlined":"","background-color":"white","type":"number","label":"Alinhamento do Texto","items":_vm.aligns},model:{value:(_vm.form['text-align']),callback:function ($$v) {_vm.$set(_vm.form, 'text-align', $$v)},expression:"form['text-align']"}})],1):_vm._e(),(
      (_vm.section.type === 'content' && _vm.formType === 'text') ||
        (_vm.section.type === 'tickets' && _vm.formType === 'text') ||
        (_vm.section.type === 'cover' && _vm.formType === 'text')
    )?_c('v-card',{attrs:{"elevation":"0"}},[_c('vue-editor',{staticClass:"text-message",attrs:{"disabled":_vm.communication.status === 'E',"editorToolbar":_vm.editorToolbar,"editorOptions":_vm.editorSettings},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1):_c('v-textarea',{attrs:{"background-color":"white","outlined":"","auto-grow":"","label":"Texto"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}}),_c('v-checkbox',{staticClass:"mb-4",attrs:{"hide-details":"","color":"darkBlue","label":"Personalizar Estilo"},on:{"click":_vm.resetPersonalize},model:{value:(_vm.personTitle),callback:function ($$v) {_vm.personTitle=$$v},expression:"personTitle"}}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.personTitle),expression:"personTitle"}]},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-select',{attrs:{"background-color":"white","outlined":"","label":"Fonte","items":_vm.fontsType},model:{value:(_vm.form.font),callback:function ($$v) {_vm.$set(_vm.form, "font", $$v)},expression:"form.font"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"background-color":"white","outlined":"","type":"number","label":"Tamanho","value":_vm.form['font-size'] ? _vm.form['font-size'].replace('px', '') : ''},on:{"input":function($event){return _vm.updateSize($event)}}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.personTitle),expression:"personTitle"}],staticClass:"ml-1 mt-n5",attrs:{"no-gutters":""}},[_c('span',{staticClass:"titleColor"},[_vm._v("Cor")])]),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.personTitle),expression:"personTitle"}],staticClass:"ml-1",attrs:{"no-gutters":""}},[_c('div',{staticClass:"cardColor"},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"color",attrs:{"elevation":"0","color":_vm.form.color}},'v-card',attrs,false),on))]}}],null,false,877719519)},[_c('v-color-picker',{attrs:{"mode":"hexa","hide-mode-switch":"","swatches-max-height":"173"},on:{"input":function($event){return _vm.changeColor($event)}},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1),_c('span',{staticClass:"textColor mt-1 ml-2"},[_vm._v(_vm._s(_vm.form.color))])],1)],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }