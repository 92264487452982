<template>
  <div v-if="settings.expanded">
    <v-row
      v-for="(item, i) in formattedFaqs"
      :key="i"
      class="mx-12 mb-12"
      justify="center"
      no-gutters
    >
      <v-col align="center">
        <v-row justify="center" no-gutters>
          <span
            :style="
              `color: ${settings['text-color'] ||
                'black'}; font-size: 20px; font-weight: 600;`
            "
            >{{ item.grupo }}</span
          >
        </v-row>

        <v-card
          class="mt-4"
          style="background-color: transparent !important"
          width="750px"
          elevation="0"
        >
          <v-expansion-panels flat v-model="item.expandeds" multiple>
            <v-expansion-panel
              v-for="(faq, index) in item.faq"
              :key="index"
              class="my-2"
            >
              <v-card
                elevation="0"
                color="'transparent'"
                :style="
                  `border-bottom: 1px solid ${settings['item-color'] ||
                    '#000'}; border-radius: 0px;`
                "
              >
                <v-expansion-panel-header>
                  <h1 :style="`color: ${settings['text-color'] || 'black'};`">
                    {{ faq.pergunta }}
                  </h1>

                  <template v-slot:actions>
                    <v-icon
                      v-if="item.expandeds.includes(index)"
                      :color="settings['text-color'] || 'black'"
                      >mdi-minus</v-icon
                    >
                    <v-icon v-else :color="settings['text-color'] || 'black'"
                      >mdi-plus</v-icon
                    >
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pb-0 mb-0">
                  <v-row no-gutters>
                    <span
                      class="answer-html"
                      :style="`color: ${settings['text-color'] || 'black'};`"
                      v-html="formatedText(faq.resposta)"
                    ></span>
                  </v-row>
                </v-expansion-panel-content>
              </v-card>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </div>

  <div v-else>
    <v-row justify="center" no-gutters>
      <v-card
        class="mt-4"
        style="background-color: transparent !important"
        width="750px"
        elevation="0"
      >
        <v-expansion-panels
          flat
          multiple
          v-for="(item, i) in formattedFaqs"
          :key="i"
          v-model="item.groupExpandeds"
        >
          <v-expansion-panel>
            <v-card
              elevation="0"
              color="'transparent'"
              :style="
                `border-bottom: 1px solid ${settings['item-color'] ||
                  '#000'}; border-radius: 0px;`
              "
            >
              <v-expansion-panel-header class="py-6">
                <h1 :style="`color: ${settings['text-color'] || 'black'};`">
                  {{ item.grupo }}
                </h1>

                <template v-slot:actions>
                  <v-icon
                    v-if="item.groupExpandeds.includes(i)"
                    :color="settings['text-color'] || 'black'"
                    >mdi-minus</v-icon
                  >
                  <v-icon v-else :color="settings['text-color'] || 'black'"
                    >mdi-plus</v-icon
                  >
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="expansion-content">
                <v-card
                  style="background-color: transparent !important"
                  elevation="0"
                >
                  <v-expansion-panels flat multiple v-model="item.expandeds">
                    <v-expansion-panel
                      v-for="(faq, index) in item.faq"
                      :key="index"
                      class="my-2"
                    >
                      <v-card elevation="0" color="'transparent'">
                        <v-expansion-panel-header>
                          <h1
                            :style="
                              `color: ${settings['text-color'] || 'black'};`
                            "
                          >
                            {{ faq.pergunta }}
                          </h1>

                          <template v-slot:actions>
                            <v-icon
                              v-if="item.expandeds.includes(index)"
                              :color="settings['text-color'] || 'black'"
                              >mdi-minus</v-icon
                            >
                            <v-icon
                              v-else
                              :color="settings['text-color'] || 'black'"
                              >mdi-plus</v-icon
                            >
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pb-0 mb-0">
                          <v-row no-gutters>
                            <span
                              class="answer-html"
                              :style="
                                `color: ${settings['text-color'] || 'black'};`
                              "
                              v-html="formatedText(faq.resposta)"
                            ></span>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-card>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-expansion-panel-content>
            </v-card>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["formattedFaqs", "settings"],

  methods: {
    formatedText(item) {
      if (item) {
        let text = item.replaceAll(
          '<span style="color: rgba(0, 0, 0, 0.87);">',
          `<span style="color: ${this.settings["text-color"]}">`
        );

        return text;
      } else return "";
    },
  },
};
</script>

<style>
.answer-html > p {
  margin: 0px !important;
  padding: 0px !important;
}

.expansion-content > .v-expansion-panel-content__wrap {
  margin: 0px !important;
  padding: 0px !important;
  padding-bottom: 20px !important;
}
</style>
