<template>
  <div>
    <div v-for="(item, i) in section.items" :key="i">
      <v-row justify="center" no-gutters>
        <v-text-field
          label="Título"
          background-color="white"
          outlined
          hide-details
          v-model="item.title"
        ></v-text-field>
      </v-row>

      <v-row class="mt-6" justify="center" no-gutters>
        <v-text-field
          label="Link externo"
          background-color="white"
          outlined
          hide-details
          v-model="item.link"
        ></v-text-field>
      </v-row>

      <v-row class="mt-6" justify="center" no-gutters>
        <v-textarea
          background-color="white"
          outlined
          auto-grow
          hide-details
          label="Descrição"
          v-model="item.description"
        ></v-textarea>
      </v-row>

      <v-row class="mb-6" no-gutters>
        <set-image
          :key="i"
          :section="section"
          type="model"
          :model="item.media"
          @updateMedia="updateMedia($event, item)"
          @removeMedia="
            () => {
              item.media = '';
            }
          "
        />
      </v-row>

      <v-row class="mb-3" justify="center" no-gutters>
        <v-btn @click="remove(i)" class="remove" fab rounded elevation="0">
          <v-icon color="white">las la-trash</v-icon>
        </v-btn>
      </v-row>
    </div>

    <v-row class="mt-4" justify="center" no-gutters outlined
      ><v-btn @click="add" class="add" elevation="0"
        >Novo Material</v-btn
      ></v-row
    >
  </div>
</template>

<script>
import SetImage from "../media/SetImage.vue";
export default {
  components: { SetImage },
  props: {
    section: {
      type: Object,
    },
  },

  methods: {
    remove(index) {
      this.section.items.splice(index, 1);
    },

    add() {
      this.section.items.push({
        link: "",
        title: "Título",
        description: "Descrição",
        media: "",
      });
    },

    updateMedia(url, item) {
      item.media = url;
    },
  },
};
</script>

<style scoped>
.add {
  text-transform: none;
  padding: 11px 32px !important;
  width: 270px !important;
  height: 40px !important;
  border: 1px solid #1976d2 !important;
  box-sizing: border-box !important;
  border-radius: 100px !important;
  background-color: transparent !important;
  color: #1976d2 !important;
}

.remove {
  width: 44px !important;
  height: 44px !important;
  border-radius: 30px !important;
  background-color: #1976d2 !important;
  margin-top: 3px;
  color: white !important;
}

.titlePanel {
  font-family: Inter !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #192b46;
}
</style>
