<template>
  <div>
    <color-picker :colorSelected="section" type="layer-color" title="Cor de Fundo do Formulário" />
    <color-picker :colorSelected="section" type="text-color" title="Cor de Texto" />
    <color-picker :colorSelected="section" type="button-color" title="Cor do Botão" />
    <color-picker :colorSelected="section" type="text-button-color" title="Cor de Texto do Botão" />
  </div>
</template>

<script>
import ColorPicker from '../media/ColorPicker.vue'
export default {
  components: { ColorPicker },
    props:['section']
}
</script>