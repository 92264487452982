import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";

const state = {
  navbar: { open: false, sectionId: ""}
};

export default {
  state,
  actions,
  getters,
  mutations
};
