<template>
  <v-card
    v-if="programs"
    class="px-4"
    tile
    :min-height="settings.height ? settings.height : '774'"
    elevation="0"
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit :sectionId="settings.id" v-if="$route.name === 'BodyEdit' && hover" />
    <title-component :settings="settingsTitle" />
    <v-container style="max-width: 1340px">
      <v-tabs
        v-show="programs && programs.tabs && programs.tabs.length > 1"
        background-color="transparent"
        v-model="tabs"
        :grow="$vuetify.breakpoint.mdAndUp ? false : true"
        :centered="$vuetify.breakpoint.mdAndUp ? true : false"
        :color="settings['accent-color']"
      >
        <v-tab v-for="(item, i) in programs.tabs" :key="i">{{ item }}</v-tab>
      </v-tabs>
      <v-divider></v-divider>

      <v-tabs-items style="background-color: transparent !important" v-model="tabs">
        <v-tab-item v-for="(tab, index) in programs.tabs" :key="index">
          <v-row
            class="mt-12"
            no-gutters
            :justify="$vuetify.breakpoint.mdAndUp ? 'space-around' : 'center'"
          >
            <v-col
              :order="$vuetify.breakpoint.mdAndUp ? '1' : '2'"
              :cols="$vuetify.breakpoint.mdAndUp ? filterList && filterList.length ? '8' : '12' : '12'"
            >
              <v-row no-gutters :justify="$vuetify.breakpoint.mdAndUp ? filterList && filterList.length ? 'start' : 'center' : 'center'">
                <h1 :class="settings['text-color']" class="ml-6">{{ formatDate(tab) }}</h1>
              </v-row>
              <div :style="`overflow-y: auto; height: 500px; `">
                <div v-for="(event, i) in programs.events[index]" :style="`${filterList && filterList.length ? '' : ' display: flex; justify-content: center;'}`" :key="i">
                  <v-card
                    v-show="!event.hide"
                    class="mt-3"
                    elevation="0"
                    width="700px"
                    :color="settings['item-background-color']"
                  >
                    <v-row no-gutters>
                      <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mr-8' : 'mr-12'" cols="1">
                        <v-list :color="settings['item-background-color']">
                          <v-list-item>
                            <span v-show="showTime(event)" :class="settings['text-color']" class="time">
                              {{
                              event.horario_inicio
                              .split(" ")[1]
                              .split(":")[0]
                              }}:{{
                              event.horario_inicio.split(" ")[1].split(":")[1]
                              }}
                            </span>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col>
                        <v-list :color="settings['item-background-color']" rounded>
                          <v-row no-gutters
                            :class="
                              $vuetify.breakpoint.mdAndUp ? 'mt-4 pr-4' : 'mt-4'
                            "
                          >
                            <span v-if="event && event.sala && event.sala.nome" :class="settings['text-color']" class="zone">{{ event.sala.nome }}</span>
                          </v-row>
                          <v-row no-gutters
                            :class="
                              $vuetify.breakpoint.mdAndUp ? 'pr-4 pb-2' : ''
                            "
                          >
                            <span v-if="event && event.nome" :class="settings['text-color']" class="content">{{ event.nome }}</span>
                          </v-row>
                          <v-row no-gutters>
                            <span
                              v-for="(item, i) in event.lista_palestrantes"
                              :key="i"
                              :class="settings['text-color']"
                              class="names"
                            >{{i && event.lista_palestrantes.length > 1 ? ',': ''}} {{ item.nome }}</span>
                          </v-row>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </div>
            </v-col>
            
            <v-col
              v-if="filterList && filterList.length"
              :order="$vuetify.breakpoint.mdAndUp ? '2' : '1'"
              :class="$vuetify.breakpoint.mdAndUp ? 'ml-12' : 'mt-n8'"
            >
              <span class="filterTitle">Filtrar por:</span>
              <v-row
                v-for="(filter, i) in filterList"
                :key="i"
                :class="i === 0 ? '' : 'mt-n7'"
                no-gutters
                align="center"
              >
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : '2'">
                  <v-checkbox
                    :color="settings['accent-color']"
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-6' : ''"
                    @change="changeFilter($event, filter.nome)"
                    v-model="filter.value"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <span class="textCheck">{{ filter.nome }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import TitleComponent from "../title/TitleComponent.vue";
import MenuEdit from "../edit/MenuEdit";
export default {
  components: { TitleComponent, MenuEdit },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      filterTec: false,
      filterMarketing: false,
      tabs: 0,
      hover: false,
      settingsTitle: null,
      copyEvents: null,
      filters: new Set(),
      filterList: [],
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
    };
  },

  computed: {
    ...mapGetters(["programs"]),
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
    setTimeout(() => {this.createFilterList()}, 1300) 
  },

  methods: {
    showTime (activity) {
      if (activity.horario_inicio.includes('00:00:00') && activity.horario_termino.includes('00:00:00')) return false
      return true
    },
    formatDate(date) {
      const separete = date.split("/");
      const day = separete[0];
      const month = Number(separete[1]) - 1;
      return `${day} de ${this.months[month]}`;
    },

    createFilterList() {
      if (this.programs && this.programs.events && this.filterList.length === 0) {
        this.settings.items = this.programs.events;
        this.programs.events.forEach((eventsDay) => {
          eventsDay.forEach((event) => {
            event.lista_assuntos.forEach((assunto) => {
                this.filterList.push(assunto)
            }
            );
          });
        });
      }
      this.filterList = this.filterList.filter((assunto) => {
        return (
          !this[JSON.stringify(assunto)] &&
          (this[JSON.stringify(assunto)] = true)
        );
      }, Object.create(null));
    },

    changeFilter($event, name) {
      if ($event) this.filters.add(name);
      else this.filters.delete(name);
      this.programs.events.forEach((eventsDay) => {
        eventsDay.forEach((event) => {
          event.hide = false;
          const assuntosEvent = new Set(
            event.lista_assuntos.map((assunto) => assunto.nome)
          );
          for (let filter of this.filters) {
            if (!assuntosEvent.has(filter)) event.hide = true;
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.v-tab,
.time {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
}

h1 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
}

.content {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.names {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.zone {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.25px;
  text-align: left;
}

.textCheck {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.v-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
}

.filterTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}
</style>