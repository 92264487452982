<template>
  <v-card>
    <v-card-title>Publicar</v-card-title>
    <v-card-text>
      <v-row justify="center" no-gutters class="my-3">Confirma publicação da versão atual?</v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="buttonDialogClose" outlined @click="close">Cancelar</v-btn>
      <v-btn class="buttonDialogAdd" :loading="loading" outlined depressed style="border: none !important" @click="confirm"><b class="white--text">Confirmar</b> </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['loading'],
  methods: {
    close() {
      this.$emit("close");
    },

    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style scoped>
.buttonDialogAdd {
  text-transform: none;
  background-color: #0067af !important;
  height: 40px !important;
  width: 128px !important;
  border-radius: 56px !important;
  padding: 11px, 32px, 11px, 32px !important;
}

.buttonDialogClose {
  text-transform: none;
  color: #0067af !important;
  background-color: transparent !important;
  height: 40px !important;
  width: 128px !important;
  border-radius: 56px !important;
  padding: 11px, 32px, 11px, 32px !important;
}
</style>