<template>
  <div>
    <v-app-bar
      height="64"
      elevation="0"
      clipped-right
      color="bt_color"
      :app="$route.name === 'BodyEdit' ? true : false"
    >
      <v-row justify="space-between" class="px-6" align="center">
        <h2 class="white--text">{{ hotsite.nome }}</h2>
        <div>
          <v-btn @click="openConfig" class="btnOutlined mr-5" elevation="0">
            <b class="white--text">Configurações</b>
          </v-btn>
          <v-btn
            v-if="!publishedVersion"
            @click="saveHotsite('R')"
            class="btnOutlined mr-5"
            :loading="loadingR"
            elevation="0"
          >
            <b class="white--text">Salvar Rascunho</b>
          </v-btn>
          <v-btn @click="openDialogConfirm" class="btnNormal" elevation="0">
            <b class="white--text">{{
              publishedVersion ? "Salvar e Publicar" : "Publicar"
            }}</b>
          </v-btn>
        </div>
      </v-row>
    </v-app-bar>

    <v-dialog v-model="dialogConfirm" width="400" persistent>
      <dialog-confirm
        @close="closeDialogConfirm"
        :loading="loadingP"
        @confirm="saveHotsite('P')"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogConfirm from "./dialogs/DialogConfirm";
export default {
  components: {
    DialogConfirm,
  },
  data() {
    return {
      dialogConfig: false,
      dialogConfirm: false,
      published: false,
      loadingP: false,
      loadingR: false,
    };
  },

  computed: {
    ...mapGetters(["hotsite", "infos", "snackbar"]),
    publishedVersion() {
      if (
        this.hotsite.versao_especifica &&
        this.hotsite.versao_publicada &&
        this.hotsite.versao_especifica.codigo ===
          this.hotsite.versao_publicada.codigo
      )
        return true;
      else if (this.hotsite.versao_publicada && !this.hotsite.versao_especifica)
        return true;
      else if (this.published) return true;
      else return false;
    },
  },

  methods: {
    openConfig() {
      this.$store.dispatch("setNav", { open: true, sectionId: "config" });
    },

    async saveHotsite(status) {
      if (status === "P") this.loadingP = true;
      if (status === "R") this.loadingR = true;
      await this.$store
        .dispatch("saveHotsite", {
          code: this.hotsite.codigo,
          code_organizer: this.hotsite.codigo_organizador,
          name: this.hotsite.nome,
          domain: this.hotsite.dominio,
          slug: this.hotsite.slug,
          code_version: this.hotsite.versao_especifica.codigo,
          code_hotsite: this.hotsite.versao_especifica.codigo_hotsite,
          status: status,
          config: this.infos,
          token: this.$route.params.token,
        })
        .then((response) => {
          if (status === "P" && response.status === 200) {
            this.dialogConfirm = false;
            this.published = true;
          } else if (status === "P" && response.status !== 200)
            this.loadingP = false;
          else if (status === "R" && response.status !== 200)
            this.loadingR = false;
        });
      if (status === "P") this.loadingP = false;
      if (status === "R") this.loadingR = false;
    },

    closeDialogConfirm() {
      this.dialogConfirm = false;
    },

    openDialogConfirm() {
      this.dialogConfirm = true;
    },
  },
};
</script>

<style scoped>
* {
  font-family: Inter, sans-serif !important;
}

h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.btnNormal {
  text-transform: none !important;
  height: 40px !important;
  background-color: #1dd78a !important;
  border-radius: 56px !important;
  padding: 11px 32px !important;
  color: white !important;
}

.btnOutlined {
  margin: 0px;
  text-transform: none;
  background-color: transparent !important;
  border: 1px solid #ffffff !important;
  box-sizing: border-box !important;
  border-radius: 100px !important;
  height: 40 !important;
  padding: 13px 32px !important;
  color: white !important;
}

b {
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
  text-align: center !important;
}
</style>
