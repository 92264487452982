var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:_vm.$route.name === 'BodyEdit' ? 'editCard' : '',attrs:{"tile":"","elevation":"0","min-height":_vm.settings.height ? _vm.settings.height : '700px',"color":_vm.settings['background-type'] === 'color'
        ? _vm.settings['background-color']
        : '',"img":_vm.settings['background-type'] === 'image'
        ? _vm.settings['background-image']
        : ''},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.$route.name === 'BodyEdit' && _vm.hover)?_c('menu-edit',{attrs:{"sectionId":_vm.settings.id}}):_vm._e(),(_vm.alignCenter || !_vm.$vuetify.breakpoint.mdAndUp)?_c('title-component',{attrs:{"settings":_vm.settingsTitle}}):_vm._e(),_c('v-container',{staticStyle:{"max-width":"1340px"}},[(!_vm.$vuetify.breakpoint.mdAndUp || _vm.alignCenter)?_c('v-row',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pb-6' : 'pb-6 px-6',style:(_vm.$vuetify.breakpoint.mdAndUp ? 'padding: 0px 150px' : ''),attrs:{"justify":"center"}},[_c('span',{style:(("font-family: " + (_vm.settings.text.font ? _vm.settings.text.font : '') + "; font-size: " + (_vm.settings.text['font-size']
                ? _vm.settings.text['font-size']
                : _vm.infos.layout.text['font-size']) + "; color: " + (_vm.settings.text.color))),domProps:{"innerHTML":_vm._s(_vm.settings.text.content)}})]):_vm._e(),_c('v-row',{class:_vm.$vuetify.breakpoint.mdAndUp ? (_vm.alignCenter ? 'pa-2' : 'pa-12') : '',attrs:{"justify":"center","no-gutters":""}},[(!_vm.alignCenter)?_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pl-12' : '',attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? '6' : '12'}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',{attrs:{"justify":"start"}},[_c('h1',{style:(("font-family: " + (_vm.settings.title.font ? _vm.settings.title.font : '') + "; font-size: " + (_vm.settings.title['font-size']
                    ? _vm.settings.title['font-size']
                    : _vm.infos.layout.title['font-size']) + "; color: " + (_vm.settings.title.color)))},[_vm._v(" "+_vm._s(_vm.settings.title.content)+" ")])]):_vm._e(),_c('v-row',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'mt-10 pr-12' : 'pb-12 px-8',attrs:{"justify":_vm.$vuetify.breakpoint.mdAndUp ? 'start' : 'center'}},[_c('span',{style:(("font-family: " + (_vm.settings.text.font ? _vm.settings.text.font : '') + "; font-size: " + (_vm.settings.text['font-size']
                    ? _vm.settings.text['font-size']
                    : _vm.infos.layout.text['font-size']) + "; color: " + (_vm.settings.text.color))),domProps:{"innerHTML":_vm._s(_vm.settings.text.content)}})])],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? '6' : '12'}},[_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndUp
                ? 'pa-12 rounded-xl'
                : 'pa-7 py-10 rounded-xl',attrs:{"color":_vm.settings['layer-color'],"elevation":"0"}},[_c('v-form',{ref:"form"},_vm._l((_vm.settings.items),function(item,i){return _c('v-row',{key:i,attrs:{"justify":"center","align":"center"}},[(item.type === 'text')?_c('div',{staticStyle:{"width":"100%"}},[_c('span',{staticClass:"item-title mb-4",style:(("color: " + (_vm.settings['text-color'])))},[_vm._v(_vm._s(item.caption))]),_c('v-text-field',{attrs:{"outlined":"","rules":[_vm.rules.required],"color":"grey","background-color":"white"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1):_vm._e(),(item.type === 'email')?_c('div',{staticStyle:{"width":"100%"}},[_c('span',{staticClass:"item-title mb-4",style:(("color: " + (_vm.settings['text-color'])))},[_vm._v(_vm._s(item.caption))]),_c('v-text-field',{attrs:{"rules":[_vm.rules.email, _vm.rules.required],"outlined":"","color":"grey","background-color":"white"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1):_vm._e(),(item.type === 'phone')?_c('div',{staticStyle:{"width":"100%"}},[_c('span',{staticClass:"item-title mb-4",style:(("color: " + (_vm.settings['text-color'])))},[_vm._v(_vm._s(item.caption))]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phone.length <= 14 ? _vm.maskPhone : _vm.maskCellPhone),expression:"phone.length <= 14 ? maskPhone : maskCellPhone"}],attrs:{"outlined":"","color":"grey","rules":[_vm.rules.required],"background-color":"white"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1):_vm._e(),(item.type === 'submit')?_c('v-btn',{style:(("background-color: " + (_vm.settings['button-color']
                        ? ((_vm.settings['button-color']) + " !important")
                        : ''))),attrs:{"elevation":"0","dark":"","loading":_vm.loading,"width":"100%"},on:{"click":_vm.submitContact}},[_c('b',{style:(("color: " + (_vm.settings['text-button-color']
                          ? _vm.settings['text-button-color']
                          : '') + " !important"))},[_vm._v("Cadastrar")])]):_vm._e()],1)}),1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }