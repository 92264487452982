<template>
  <div>
    <!-- Seção -->
    <div v-if="option.tipo === 'secao'" class="identification-tag">
      {{ option.titulo }}
    </div>

    <!-- Input -->
    <div
      v-else-if="
      option.tipo !== 'localidade' &&
        option.tipo !== 'telefone' &&
          option.tipo !== 'cpf' &&
          option.tipo !== 'rg' &&
          option.tipo !== 'cnpj' &&
          option.tipo !== 'horario' &&
          option.tipo !== 'email' &&
          option.tipo !== 'multipla_escolha' &&
          option.tipo !== 'data' &&
          option.tipo !== 'caixa_selecao' &&
          option.tipo !== 'lista_suspensa' &&
          option.tipo !== 'resposta_longa' &&
          option.tipo !== 'upload_arquivo' &&
          option.tipo !== 'termo_responsabilidade' &&
          option.tipo !== 'paragrafo' &&
          option.tipo !== 'upload_imagem'
      "
    >
      <span
        class="item-title mb-4"
        :style="`color: ${section['text-color']}`"
        >{{ option.obrigatorio ? `${option.titulo} *` : option.titulo }}</span
      >
      <v-text-field
        ref="ticketName"
        v-model="option.valor"
        outlined
        dense
        :disabled="$route.name === 'BodyEdit'"
        background-color="white"
        :rules="option.obrigatorio ? [rulesValidation.required] : []"
      ></v-text-field>
    </div>

    <div
      v-if="
        option.tipo === 'telefone' ||
          option.tipo === 'horario' ||
          option.tipo === 'cpf' ||
          option.tipo === 'rg' ||
          option.tipo === 'cnpj'
      "
    >
      <span
        class="item-title mb-4"
        :style="`color: ${section['text-color']}`"
        >{{ option.obrigatorio ? `${option.titulo} *` : option.titulo }}</span
      >
      <v-text-field
        ref="ticketName"
        v-model="option.valor"
        outlined
        dense
        :disabled="$route.name === 'BodyEdit'"
        background-color="white"
        :rules="option.obrigatorio ? [rulesValidation.required] : []"
        v-mask="getMask(option.tipo)"
      ></v-text-field>
    </div>

    <div v-if="option.tipo === 'email'" style="width: 100%">
      <span
        class="item-title mb-4"
        :style="`color: ${section['text-color']}`"
        >{{ option.obrigatorio ? `${option.titulo} *` : option.titulo }}</span
      >
      <v-text-field
        ref="ticketName"
        type="email"
        v-model="option.valor"
        outlined
        dense
        :disabled="$route.name === 'BodyEdit'"
        background-color="white"
        :rules="
          option.obrigatorio
            ? [rulesValidation.required, rulesValidation.email]
            : []
        "
      ></v-text-field>
    </div>

    <!-- input data -->
    <div v-if="option.tipo === 'data'">
      <span
        class="item-title mb-4"
        :style="`color: ${section['text-color']}`"
        >{{ option.obrigatorio ? `${option.titulo} *` : option.titulo }}</span
      >
      <v-text-field
        outlined
        :rules="option.obrigatorio ? [rulesValidation.required] : []"
        v-mask="getMask(option.tipo)"
        v-model="option.valor"
        dense
        :disabled="$route.name === 'BodyEdit'"
        background-color="white"
      ></v-text-field>
    </div>

    <!-- Radio Options -->
    <div v-if="option.tipo === 'multipla_escolha'">
      <span
        class="item-title"
        :style="`color: ${section['text-color']}`"
        v-if="option.obrigatorio"
        >{{ option.titulo }} *</span
      >
      <span
        class="item-title"
        :style="`color: ${section['text-color']}`"
        v-else
        >{{ option.titulo }}</span
      >
      <v-radio-group
        v-model="option.valor"
        :mandatory="true"
        :disabled="$route.name === 'BodyEdit'"
        :rules="option.obrigatorio ? [rulesValidation.required] : []"
      >
        <v-radio
          v-for="(opcao, index) in option.opcoes"
          :key="index"
          :disabled="$route.name === 'BodyEdit'"
          :label="opcao"
          :value="opcao"
        >
          <template v-slot:label>
            <span :style="`color: ${section['text-color']}`">{{ opcao }}</span>
          </template>
        </v-radio>
      </v-radio-group>
    </div>

    <!-- Caixa Seleção -->
    <div v-if="option.tipo === 'caixa_selecao'" class="pb-4">
      <span
        class="item-title"
        v-if="option.obrigatorio"
        :style="`color: ${section['text-color']}`"
        >{{ option.titulo }} *</span
      >
      <span v-else :style="`color: ${section['text-color']}`">{{
        option.titulo
      }}</span>
      <v-checkbox
        style="height: 25px;"
        v-model="option.valor"
        :rules="option.obrigatorio ? [(v) => (v && v.length >= 1) || ''] : []"
        v-for="(opcao, index) in option.opcoes"
        :key="index"
        :disabled="$route.name === 'BodyEdit'"
        :value="opcao"
      >
        <template v-slot:label>
          <span :style="`color: ${section['text-color']}`">{{ opcao }}</span>
        </template>
      </v-checkbox>
    </div>

    <!-- Lista Suspensa -->
    <div v-if="option.tipo === 'lista_suspensa'">
      <span
        class="item-title mb-4"
        :style="`color: ${section['text-color']}`"
        >{{ option.obrigatorio ? `${option.titulo} *` : option.titulo }}</span
      >
      <v-select
        v-model="option.valor"
        :items="option.opcoes"
        item-text="text"
        item-value="value"
        dense
        :disabled="$route.name === 'BodyEdit'"
        background-color="white"
        :rules="option.obrigatorio ? [rulesValidation.required] : []"
        outlined
      ></v-select>
    </div>

    <!-- Resposta Longa -->
    <div v-if="option.tipo === 'resposta_longa'">
      <span
        class="item-title mb-4"
        :style="`color: ${section['text-color']}`"
        >{{ option.obrigatorio ? `${option.titulo} *` : option.titulo }}</span
      >
      <v-textarea
        outlined
        auto-grow
        dense
        :disabled="$route.name === 'BodyEdit'"
        background-color="white"
        :rules="option.obrigatorio ? [rulesValidation.required] : []"
        v-model="option.valor"
      ></v-textarea>
    </div>

    <!-- Termo Responsabilidade -->
    <div
      v-if="
        option.tipo === 'termo_responsabilidade' || option.tipo === 'paragrafo'
      "
    >
      <span
        class="mb-4 item-title"
        v-if="option.obrigatorio"
        :style="`color: ${section['text-color']}`"
        >{{ option.titulo }} *</span
      >
      <span
        class="item-title mb-4"
        :style="`color: ${section['text-color']}`"
        v-else
        >{{ option.titulo }}</span
      >
      <div
        :style="`color: ${section['text-color']}`"
        class="termo-responsabilidade-item pa-2"
        v-html="option.conteudo"
      ></div>
      <v-checkbox
        v-model="option.valor"
        color="primary"
        :disabled="$route.name === 'BodyEdit'"
        :rules="option.obrigatorio ? [(v) => !!v || 'Você precisa aceitar os termos!'] : []"
      >
        <template v-slot:label>
          <span :style="`color: ${section['text-color']}`"
            >Li e aceito os termos</span
          >
        </template>
      </v-checkbox>
    </div>
    <div id="google-place"></div>
    <div id="google-autocomplete"></div>
    <div v-if="option.tipo === 'localidade'">
      <span
        class="item-title mb-4"
        :style="`color: ${section['text-color']}`"
        >{{ option.obrigatorio ? `${option.titulo} *` : option.titulo }}</span
      >
      <v-autocomplete
        background-color="white"
        outlined
        v-model="option.valor"
        item-text="description"
        :item-value="valueFormated"
        autocomplete="off"
        :menu-props="{
          offsetY: true,
          contentClass: 'participants-filter-props',
        }"
        :items="eventPlaces"
        no-data-text="Digite para buscar o local"
        :rules="option.obrigatorio ? [rulesValidation.required] : []"
        :search-input.sync="location"
      ></v-autocomplete>
    </div>

    <!-- Upload Arquivos -->
    <div
      v-if="option.tipo === 'upload_arquivo' || option.tipo === 'upload_imagem'"
    >
      <v-text-field
        outlined
        :label="option.obrigatorio ? `${option.titulo} *` : option.titulo"
        v-model="option.valor"
        background-color="white"
        dense
        :disabled="$route.name === 'BodyEdit'"
        @click="onPickFile(option)"
        @click:append="onPickFile(option)"
        append-icon="mdi-publish"
      ></v-text-field>
      <!-- Hidden -->
      <input
        type="file"
        @change="onFileSelected"
        style="display: none"
        :id="`fileInput-${option.tipo}`"
      />
      <v-row class="mt-n5" no-gutters
        ><span style="font-size: 10px">Tamanho máximo de 500kb</span>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from 'vuex';
export default {
  props: ["option", "section"],
  directives: {
    mask,
  },
  data() {
    return {
      rulesValidation: {
        required: (value) => !!value || "Este campo é obrigatório.",
        minChar: (value) =>
          (!!value && value.length >= 6) || "Mínimo de 6 caracteres.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (!!value && pattern.test(value)) || "E-mail inválido.";
        },
        dateValid: (value) => {
          const date = (value || "")
            .replace(/(\d{2})(\d{2})(\d{4})/, "$3,$2,$1")
            .split(",")
            .map((x, i) => (i === 1 ? Number(x) - 1 : x));
          return (
            (vm.$moment(date).isValid() && value.length === 8) ||
            "Data inválida."
          );
        },
        ageThreshold: (value) => {
          const date = (value || "")
            .replace(/(\d{2})(\d{2})(\d{4})/, "$3,$2,$1")
            .split(",")
            .map((x, i) => (i === 1 ? Number(x) - 1 : x));
          const yearDifference = vm.$moment().year() - vm.$moment(date).year();
          return yearDifference <= 100 && yearDifference >= 15
            ? true
            : "Data inválida.";
        },
      },
      location: null,
      locations: []
    };
  },
  watch: {
    location(v) {
      if (v && v.length > 3) {
        this.searchLocation(v);
      } else this.$store.commit("SET_ALL_EVENT_PLACES", []);
    },
  },
  computed: {
    ...mapGetters(["eventPlaces"]),
  },
  methods: {
    textFormated(v) {
      return `${v.cidade && v.cidade.nome ? v.cidade.nome : ""} - ${
        v.cidade && v.cidade.estado ? v.cidade.estado : ""
      } - ${v.country && v.country.long_name ? v.country.long_name : ""}`;
    },
    valueFormated(v) {
      let valueReplace = v.description.split(' - ')
      return `${valueReplace[0]}/${valueReplace[1]}/${valueReplace[2]}`
    },
    async searchLocation(v) {
      await this.$store.dispatch("getGlobalPlaces", v);
    },
    onPickFile(el) {
      this.selectedOption = el;
      setTimeout(function() {
        document.getElementById(`fileInput-${el.tipo}`).click();
      }, 200);
    },
    onFileSelected(event) {
      if (this.option.tipo === "upload_arquivo") {
        this.option.valor = event.target.value;
        this.option.file = event.target.files[0];
      }
      if (this.option.tipo === "upload_imagem") {
        this.option.valor = event.target.value;
        this.option.image = event.target.files[0];
      }

      this.selectedOption = null;
      this.$forceUpdate();
    },
    getMask(el) {
      switch (el) {
        case "telefone":
          return "(##) #########";
        case "data":
          return "##/##/####";
        case "horario":
          return "time";
        case "cnpj":
          return "##.###.###/####-##";
        case "rg":
          return "#.###.###-#";
        case "cpf":
          return "###.###.###-##";
        default:
          return "";
      }
    },
    async saveNameTicket() {
      this.$emit("save");
    },
  },
};
</script>
