import Vue from 'vue'
import Router from 'vue-router'
import Body from '../views/Body.vue'

Vue.use(Router)

const router = new Router({
  routes: [
  {
    path: '/:slug/:version',
    name: 'Body Version',
    component: Body
  },
  {
    path: '/:slug',
    name: 'Body',
    component: Body
  },
  {
    path: '/',
    name: 'BodyDomain',
    component: Body
  },
  {
    path: '/:slug/:version/edit/:token',
    name: 'BodyEdit',
    component: Body
    },
  ],
  mode: 'history',
})


export default router
