import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";

const state = {
  dialogSection: {
    open: false,
    selectedSection:null
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
