<template>
  <v-card
    class="pb-12"
    tile
    elevation="0"
    :min-height="settings.height ? settings.height : '712px'"
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit :sectionId="settings.id" v-if="$route.name === 'BodyEdit' && hover" />
    <title-component class="pb-12" :settings="settingsTitle" />
    <v-row no-gutters justify="center">
      <v-card
        elevation="0"
        :height="$vuetify.breakpoint.mdAndUp ? '488' : '300'"
        :width="$vuetify.breakpoint.mdAndUp ? '1000' : '300'"
      >
        <v-carousel
          cycle
          interval="3000"
          :height="$vuetify.breakpoint.mdAndUp ? '488' : '300'"
          show-arrows-on-hover
          hide-delimiters
        >
          <v-carousel-item v-for="(item, i) in settings.items" :key="i" :src="item"></v-carousel-item>
        </v-carousel>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
import TitleComponent from "../title/TitleComponent.vue";
import MenuEdit from "../edit/MenuEdit";
export default {
  components: { TitleComponent, MenuEdit },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      settingsTitle: null,
      hover: false,
    };
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },
};
</script>