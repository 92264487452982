<template>
  <div class="mt-6">
    <v-row class="mb-4" no-gutters>
      <span class="titlePanel">Personalizado</span>
    </v-row>

    <div v-for="(item, i) in section.items" :key="i">
      <v-row justify="center" no-gutters>
        <v-text-field
          label="Título"
          background-color="white"
          outlined
          hide-details
          v-model="item.title"
        ></v-text-field>
      </v-row>

      <v-row class="mt-6" justify="center" no-gutters>
        <v-textarea
          background-color="white"
          outlined
          auto-grow
          hide-details
          label="Descrição"
          v-model="item.description"
        ></v-textarea>
      </v-row>

      <v-row class="mt-6" justify="center" no-gutters>
        <v-text-field
          label="Valor"
          background-color="white"
          outlined
          v-currency="currencyOptions"
          prefix="R$"
          hide-details
          v-model="item.price"
        ></v-text-field>
      </v-row>

      <v-row no-gutters>
        <color-picker
          :colorSelected="section"
          type="model"
          :model="item.backgroundColor"
          title="Cor de fundo"
          @changeColor="
            (color) => {
              item.backgroundColor = color;
            }
          "
        />
      </v-row>

      <v-row class="mt-4" no-gutters>
        <span class="subtitlePanel">Botão</span>
      </v-row>

      <v-row class="mt-4" justify="center" no-gutters>
        <v-text-field
          label="Texto"
          background-color="white"
          outlined
          hide-details
          v-model="item.buttonText"
        ></v-text-field>
      </v-row>

      <v-row class="mt-4" justify="center" no-gutters>
        <v-text-field
          label="Link externo"
          background-color="white"
          outlined
          hide-details
          v-model="item.link"
        ></v-text-field>
      </v-row>

      <v-row no-gutters class="mt-1">
        <color-picker
          :colorSelected="section"
          type="model"
          :model="item.buttonColor"
          title="Cor"
          @changeColor="
            (color) => {
              item.buttonColor = color;
            }
          "
        />
      </v-row>

      <v-row no-gutters class="mt-1">
        <color-picker
          :colorSelected="section"
          type="model"
          :model="item.buttonTextColor"
          title="Cor do Texto"
          @changeColor="
            (color) => {
              item.buttonTextColor = color;
            }
          "
        />
      </v-row>

      <v-row class="mb-3" justify="center" no-gutters>
        <v-btn @click="remove(i)" class="remove" fab rounded elevation="0">
          <v-icon color="white">las la-trash</v-icon>
        </v-btn>
      </v-row>
    </div>

    <v-row class="mt-4" justify="center" no-gutters outlined
      ><v-btn @click="add" class="add" elevation="0"
        >Novo Ingresso</v-btn
      ></v-row
    >
  </div>
</template>

<script>
import { CurrencyDirective } from "vue-currency-input";
import ColorPicker from "../../media/ColorPicker.vue";
export default {
  directives: { currency: CurrencyDirective },
  components: { ColorPicker },
  props: ["section"],

  data() {
    return {
      currencyOptions: {
        currency: null,
        autoDecimalMode: true,
      },
    };
  },

  mounted() {
    if (!this.section.items) this.section.items = [];
  },

  methods: {
    remove(index) {
      this.section.items.splice(index, 1);
    },

    add() {
      this.section.items.push({
        link: "",
        title: "Título",
        description: "Descrição",
        price: "",
        backgroundColor: "",
        buttonText: "",
        buttonColor: "",
        buttonTextColor: "",
      });
    },
  },
};
</script>

<style scoped>
.add {
  text-transform: none;
  padding: 11px 32px !important;
  width: 270px !important;
  height: 40px !important;
  border: 1px solid #1976d2 !important;
  box-sizing: border-box !important;
  border-radius: 100px !important;
  background-color: transparent !important;
  color: #1976d2 !important;
}

.remove {
  width: 44px !important;
  height: 44px !important;
  border-radius: 30px !important;
  background-color: #1976d2 !important;
  margin-top: 3px;
  color: white !important;
}

.titlePanel {
  font-family: Inter !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #192b46;
}

.subtitlePanel {
  font-family: Inter !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #192b46;
}
</style>
