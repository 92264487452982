<template>
  <div>
    <v-row v-if="!section.items" justify="start" no-gutters>
      <h1>Nenhum palestrante cadastrado.</h1>
    </v-row>
    <div v-else>
      <v-row justify="start" no-gutters>
        <v-select
          :items="types"
          v-model="section.typeShow"
          class="mb-n5"
          outlined
          background-color="white"
        ></v-select>
      </v-row>

      <v-row
        v-show="section.typeShow === 'Específicos'"
        no-gutters
        align="center"
        class="mb-4"
      >
        <v-checkbox
          color="#3c4b62"
          hide-details
          v-model="showAll"
          @change="handleChangeShowAll"
        ></v-checkbox>
        <v-col class="mt-5 ml-2">
          <strong>{{
            allSelectedSpeakers ? "Desmarcar Todos" : "Marcar Todos"
          }}</strong>
        </v-col>
      </v-row>

      <div v-show="section.typeShow === 'Específicos'">
        <v-row
          v-for="(speaker, i) in section.items"
          :key="i"
          justify="start"
          no-gutters
        >
          <v-checkbox
            color="#3c4b62"
            hide-details
            v-model="speaker.show"
          ></v-checkbox>
          <v-col class="mt-2 ml-2">
            <b>{{ speaker.nome }}</b>
            <br />
            <span>{{ speaker.cargo }} - {{ speaker.empresa }}</span>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section: {
      type: Object,
    },
  },

  computed: {
    allSelectedSpeakers() {
      return this.section.items.every((item) => item.show);
    },
  },

  watch: {
    allSelectedSpeakers(v) {
      this.showAll = v;
    },
  },

  data() {
    return {
      all: true,
      copySpeakers: [],
      types: ["Todos", "Específicos"],
      type: "",
      showAll: true,
    };
  },

  methods: {
    handleChangeShowAll() {
      this.section.items = this.section.items.map((item) => {
        return {
          ...item,
          show: this.showAll,
        };
      });
    },
  },
};
</script>

<style scoped>
* {
  font-family: Inter, sans-serif !important;
}
h1 {
  width: 270px;
  height: 21px;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #3c4b62;
}

h2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #3c4b62;
}

b {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #3c4b62;
}

span {
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
}

.v-btn {
  text-transform: none;
  padding: 11px 32px !important;
  width: 270px !important;
  height: 40px !important;
  border: 1px solid #1976d2 !important;
  box-sizing: border-box !important;
  border-radius: 100px !important;
  background-color: transparent !important;
  color: #1976d2 !important;
}
</style>
