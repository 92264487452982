var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selectedSection.type === 'speakers')?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"background-color":"white","label":"Quantidade Exibida","outlined":"","hide-details":"","type":"number"},model:{value:(_vm.selectedSection['max-speakers']),callback:function ($$v) {_vm.$set(_vm.selectedSection, 'max-speakers', $$v)},expression:"selectedSection['max-speakers']"}})],1):_vm._e(),_c('v-row',[(
        _vm.selectedSection.type === 'logos' ||
          (_vm.selectedSection.type === 'speakers' &&
            !_vm.selectedSection.overlappingName)
      )?_c('v-col',[_c('v-select',{attrs:{"background-color":"white","outlined":"","label":"Tamanho da Imagem","item-text":"name","item-value":"value","hide-details":"","items":_vm.sizesImage},model:{value:(_vm.selectedSection['item-size']),callback:function ($$v) {_vm.$set(_vm.selectedSection, 'item-size', $$v)},expression:"selectedSection['item-size']"}})],1):_vm._e(),(_vm.selectedSection.type === 'speakers')?_c('v-col',[_c('v-select',{attrs:{"background-color":"white","outlined":"","label":"Arredondamento da Borda","item-text":"name","hide-details":"","item-value":"value","items":_vm.typesBorder},model:{value:(_vm.selectedSection['item-border']),callback:function ($$v) {_vm.$set(_vm.selectedSection, 'item-border', $$v)},expression:"selectedSection['item-border']"}})],1):_vm._e()],1),(
      _vm.selectedSection.type === 'speakers' && !_vm.selectedSection.overlappingName
    )?_c('div',[_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":""}},[_c('span',{staticClass:"titlePanel"},[_vm._v("Sombra")])]),_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"type":"number","hide-details":"","label":"Distância","outlined":"","background-color":"white"},model:{value:(_vm.selectedSection['distance']),callback:function ($$v) {_vm.$set(_vm.selectedSection, 'distance', $$v)},expression:"selectedSection['distance']"}})],1),_c('color-picker',{staticClass:"mt-4",attrs:{"title":"Cor","colorSelected":_vm.selectedSection,"type":"shadow"}})],1):_vm._e(),(_vm.selectedSection['layout'])?_c('v-row',{staticClass:"mt-5",attrs:{"no-gutters":""}},[_c('v-select',{attrs:{"background-color":"white","outlined":"","label":"Layout","item-text":"name","item-value":"value","items":_vm.typesLayout},on:{"change":_vm.changeTypeLayout},model:{value:(_vm.selectedSection['layout']),callback:function ($$v) {_vm.$set(_vm.selectedSection, 'layout', $$v)},expression:"selectedSection['layout']"}})],1):_vm._e(),(_vm.selectedSection['layout'] === 'form')?_c('div',[_c('form-config-ticket',{attrs:{"section":_vm.selectedSection}})],1):_vm._e(),(
      (_vm.selectedSection['max-columns'] &&
        _vm.selectedSection.type !== 'tickets') ||
        (_vm.selectedSection.type === 'tickets' &&
          _vm.selectedSection['layout'] === 'vertical')
    )?_c('v-row',{staticClass:"mt-5",attrs:{"no-gutters":""}},[_c('v-select',{attrs:{"background-color":"white","outlined":"","label":"Máximo de Colunas","item-text":"name","item-value":"value","items":_vm.typesColumn},model:{value:(_vm.selectedSection['max-columns']),callback:function ($$v) {_vm.$set(_vm.selectedSection, 'max-columns', $$v)},expression:"selectedSection['max-columns']"}})],1):_vm._e(),(
      (_vm.selectedSection.type === 'tickets' &&
        _vm.selectedSection['layout'] !== 'form') ||
        _vm.selectedSection.type === 'schedule'
    )?_c('color-picker',{class:_vm.selectedSection.type === 'schedule' ? 'mt-5' : 'mt-n3',attrs:{"title":_vm.selectedSection.type === 'schedule'
        ? 'Cor da Atividade'
        : 'Cor do ingresso',"colorSelected":_vm.selectedSection,"type":"item-background-color"}}):_vm._e(),(
      _vm.selectedSection.type === 'tickets' &&
        _vm.selectedSection['layout'] !== 'form'
    )?_c('color-picker',{staticClass:"mt-5",attrs:{"title":"Cor do Botão","colorSelected":_vm.selectedSection,"type":"button-color"}}):_vm._e(),(
      _vm.selectedSection.type === 'numbers' ||
        _vm.selectedSection.type === 'schedule'
    )?_c('color-picker',{class:_vm.selectedSection.type === 'schedule' ? 'mt-5' : 'mt-n5',attrs:{"title":_vm.selectedSection.type === 'schedule'
        ? 'Cor da Tab/Caixa de Seleção'
        : 'Cor do Número',"colorSelected":_vm.selectedSection,"type":"accent-color"}}):_vm._e(),(_vm.selectedSection.type === 'tickets')?_c('color-picker',{staticClass:"mt-5",attrs:{"title":"Cor de Texto do Botão","colorSelected":_vm.selectedSection,"type":"text-button-color"}}):_vm._e(),(_vm.selectedSection.type === 'speakers')?_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-checkbox',{attrs:{"label":"Nome sobreposto"},model:{value:(_vm.selectedSection.overlappingName),callback:function ($$v) {_vm.$set(_vm.selectedSection, "overlappingName", $$v)},expression:"selectedSection.overlappingName"}})],1):_vm._e(),(_vm.selectedSection['layout'] === 'custom')?_c('custom-tickets',{attrs:{"section":_vm.selectedSection}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }