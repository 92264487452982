<template>
  <v-row class="rowEdit" no-gutters
    ><div class="cardMenu">
      <v-btn class="btnEdit" text icon>
        <v-icon color="#1976D2" @click="openAddSection"
          >las la-plus</v-icon
        ></v-btn
      >
      <v-btn @click="openNavEdit" class="btnEdit" text icon>
        <v-icon color="#1976D2">las la-pencil</v-icon>
      </v-btn>
      <v-btn
        :disabled="sectionId === 'menu' || sectionIndex === 1"
        @click="moveElement('up')"
        class="btnEdit"
        text
        icon
      >
        <v-icon color="#1976D2">las la-angle-up</v-icon>
      </v-btn>
      <v-btn
        :disabled="sectionId === 'menu'"
        @click="moveElement('down')"
        class="btnEdit"
        text
        icon
      >
        <v-icon color="#1976D2">las la-angle-down</v-icon>
      </v-btn>
      <v-btn
        @click="removeSection"
        class="btnEdit"
        text
        icon
      >
        <v-icon color="#1976D2">las la-trash</v-icon>
      </v-btn>
    </div>
  </v-row>
</template>

<script>
import AddSection from "./dialogs/AddSection";
import { mapGetters } from "vuex";
export default {
  components: {
    AddSection,
  },
  props: {
    sectionId: { type: String },
  },

  data() {
    return {
      dialogSection: false,
      sectionIndex: null,
    };
  },

  computed: {
    ...mapGetters(["infos"]),
  },

  created() {
    this.infos.sections.forEach((section, i) => {
      if (this.sectionId === section.id) this.sectionIndex = i;
    });
  },

  methods: {
    openNavEdit() {
      this.$store.dispatch("setNav", { open: true, sectionId: this.sectionId });
    },
    removeSection() {
      if (this.sectionId)
        this.infos.sections.forEach((section, i) => {
          if (this.sectionId === section.id) this.infos.sections.splice(i, 1);
        });
      this.$store.dispatch("setInfos", this.infos);
      this.$store.dispatch("setNav", { open: false, sectionId: null });
    },

    openAddSection() {
      this.$store.dispatch("setDialogSection", {
        open: true,
        selectedSection: this.sectionId,
      });
    },

    moveElement(direction) {
      let indexOld,
        indexNew = 0;
      if (this.sectionId)
        this.infos.sections.forEach((section, i) => {
          if (this.sectionId === section.id) indexOld = i;
        });
      indexNew = direction === "up" ? indexOld - 1 : indexOld + 1;
      this.infos.sections.splice(
        indexNew,
        0,
        this.infos.sections.splice(indexOld, 1)[0]
      );
    },
  },
};
</script>
