var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.$route.name === 'BodyEdit' ? 'editCard' : '',attrs:{"tile":"","min-height":_vm.settings.height ? _vm.settings.height : '578px',"elevation":"0","color":_vm.settings['background-type'] === 'color'
      ? _vm.settings['background-color']
      : '',"img":_vm.settings['background-type'] === 'image'
      ? _vm.settings['background-image']
      : ''},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(_vm.$route.name === 'BodyEdit' && _vm.hover)?_c('menu-edit',{attrs:{"sectionId":_vm.settings.id}}):_vm._e(),_c('title-component',{attrs:{"settings":_vm.settingsTitle}}),_c('div',{staticClass:"div-style-width"},[(
        (_vm.products &&
          _vm.products.products &&
          _vm.settings.layout === 'vertical' &&
          _vm.$vuetify.breakpoint.mdAndUp) ||
          (_vm.products &&
            _vm.products.products &&
            _vm.settings.layout === 'horizontal') ||
          (!_vm.$vuetify.breakpoint.mdAndUp && _vm.settings.layout !== 'form')
      )?_c('v-row',{staticStyle:{"max-width":"1340px !important"},attrs:{"justify":"center","no-gutters":""}},[(
          _vm.products &&
            _vm.products.products &&
            _vm.settings.layout === 'vertical' &&
            _vm.$vuetify.breakpoint.mdAndUp
        )?_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows":_vm.$vuetify.breakpoint.mdAndUp &&
            _vm.splitArray &&
            _vm.splitArray.length &&
            _vm.splitArray.length > 1,"cycle":!_vm.$vuetify.breakpoint.mdAndUp}},_vm._l((_vm.splitArray),function(carousel,index){return _c('v-carousel-item',{key:index},[_c('v-container',{staticStyle:{"overflow":"auto"},attrs:{"fill-height":""}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},_vm._l((_vm.splitArray[index]),function(item,i){return _c('v-col',{key:i,class:_vm.settings['text-color'],attrs:{"cols":_vm.settings['max-coloumns'],"align":"center"}},[_c('v-card',{staticClass:"mt-3 mx-2",attrs:{"elevation":"0","max-width":"\n                  255\n                ","height":"100%","max-height":"100%","color":_vm.settings['item-background-color']}},[_c('v-row',{attrs:{"no-gutters":"","justify":"start","align":"start"}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-row',{staticClass:"pt-2 px-8 ",staticStyle:{"height":"100%","min-height":"100px","display":"flex","justify-content":"center","align-items":"center"},attrs:{"justify":"center","no-gutters":""}},[_c('b',{staticClass:"ticketName black--text"},[_vm._v(_vm._s(item.nome)+" - "+_vm._s(item.nome_tipo))])]),_c('v-card',{staticClass:"mx-9 mb-5 mt-5",attrs:{"elevation":"0","color":"transparent","height":"200px"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"align":"start"}},[_c('span',{staticClass:"description black--text",staticStyle:{"white-space":"pre-line","text-align":"left !important"}},[_vm._v(" "+_vm._s(_vm.descriptionVertical(item))+" ")]),(
                                item.observacoes &&
                                  item.observacoes.length >= 150
                              )?_c('dialog-ticket',{staticClass:"mr-3 py-2",attrs:{"item":item}}):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"py-5",attrs:{"justify":"center","no-gutters":"","align":"center"}},[_c('v-col',[_c('v-row',{staticClass:"mb-1",attrs:{"justify":"center"}},[_c('b',{directives:[{name:"show",rawName:"v-show",value:(!item.encerrado),expression:"!item.encerrado"}],staticClass:"black--text"},[_vm._v(" "+_vm._s(item.valor + item.valor_conveniencia ? ("R$ " + ((item.valor + item.valor_conveniencia) .toFixed(2) .replace(".", ","))) : "Grátis")+" ")])]),_c('v-btn',{style:(("background-color: " + (_vm.settings['button-color']
                                ? ((_vm.settings['button-color']) + " !important")
                                : '') + "; " + (item.encerrado
                                ? 'cursor: default !important'
                                : ''))),attrs:{"dark":"","elevation":"0","readonly":!!item.encerrado},on:{"click":function($event){return _vm.openCheckout(item)}}},[_c('b',{style:(("color: " + (_vm.settings['text-button-color']
                                  ? _vm.settings['text-button-color']
                                  : '') + " !important"))},[_vm._v(_vm._s(item.encerrado ? "Encerrado" : item.valor + item.valor_conveniencia ? "Comprar" : "Fazer Inscrição"))])])],1)],1)],1)],1)],1)}),1)],1)],1)}),1):_vm._e(),(
          (_vm.products &&
            _vm.products.products &&
            _vm.settings.layout === 'horizontal') ||
            (!_vm.$vuetify.breakpoint.mdAndUp &&
              _vm.settings.layout !== 'form' &&
              _vm.settings.layout !== 'custom')
        )?_c('v-container',{staticClass:"mb-12",staticStyle:{"overflow":"auto"},attrs:{"fill-height":""}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},_vm._l((_vm.products.products),function(item,i){return _c('v-col',{key:i,class:_vm.settings['text-color'],attrs:{"cols":"12\n            ","align":"center"}},[_c('v-card',{staticClass:"mt-3 mx-2",attrs:{"elevation":"0","max-width":_vm.$vuetify.breakpoint.mdAndUp ? '900' : '',"max-height":_vm.$vuetify.breakpoint.mdAndUp ? '400' : '',"min-height":_vm.$vuetify.breakpoint.mdAndUp ? '105px' : '',"color":_vm.settings['item-background-color']}},[_c('v-row',{class:_vm.$vuetify.breakpoint.mdAndUp
                    ? item.observacoes
                      ? 'pt-7'
                      : 'pt-8'
                    : 'pt-5',attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"px-6",attrs:{"align":"start","cols":_vm.$vuetify.breakpoint.mdAndUp ? '8' : '12'}},[_c('b',{staticClass:"black--text"},[_vm._v(_vm._s(item.nome)+" - "+_vm._s(item.nome_tipo))]),_c('br'),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('b',{staticClass:"description"},[_vm._v(_vm._s(item.observacoes ? item.observacoes.length >= 50 ? ((item.observacoes.slice(0, 50)) + "...") : item.observacoes : "")+" ")]):_c('b',{staticClass:"description"},[_vm._v(_vm._s(item.observacoes ? item.observacoes : ""))]),(
                        _vm.$vuetify.breakpoint.mdAndUp &&
                          item.observacoes &&
                          item.observacoes.length >= 50
                      )?_c('dialog-ticket',{staticClass:"ml-2",attrs:{"item":item}}):_vm._e()],1)],1),_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'mt-3 pb-5',attrs:{"align":"end","cols":_vm.$vuetify.breakpoint.mdAndUp ? '4' : '12'}},[_c('v-row',{class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'pl-6',attrs:{"no-gutters":"","justify":_vm.$vuetify.breakpoint.mdAndUp ? 'end' : 'space-between',"align":"center"}},[_c('b',{directives:[{name:"show",rawName:"v-show",value:(!item.encerrado),expression:"!item.encerrado"}],staticClass:"black--text",class:_vm.alignHorizontal || !_vm.$vuetify.breakpoint.mdAndUp
                          ? 'mr-4 price'
                          : 'price'},[_vm._v(" "+_vm._s(item.valor + item.valor_conveniencia ? ("R$ " + ((item.valor + item.valor_conveniencia) .toFixed(2) .replace(".", ","))) : "Grátis")+" ")]),_c('v-btn',{staticClass:"mr-6",style:(("background-color: " + (_vm.settings['button-color']
                            ? ((_vm.settings['button-color']) + " !important")
                            : '') + "; " + (item.encerrado ? 'cursor: default !important' : ''))),attrs:{"dark":"","elevation":"0","readonly":!!item.encerrado},on:{"click":function($event){return _vm.openCheckout(item)}}},[_c('b',{style:(("color: " + (_vm.settings['text-button-color']
                              ? _vm.settings['text-button-color']
                              : '') + " !important"))},[_vm._v(_vm._s(item.encerrado ? "Encerrado" : item.valor + item.valor_conveniencia ? "Comprar" : "Fazer Inscrição"))])])],1)],1)],1)],1)],1)}),1)],1):_vm._e()],1):_vm._e(),(_vm.settings.layout === 'form' && _vm.settings['type-ticket'])?_c('div',{style:(_vm.$vuetify.breakpoint.mdAndUp ? 'width:1340px' : '')},[_c('v-row',{style:(_vm.$vuetify.breakpoint.mdAndUp
            ? 'max-width: 1340px; width:100%'
            : 'width: 100vw'),attrs:{"no-gutters":"","justify":"center"}},[(_vm.formatedText)?_c('v-col',{staticClass:"mt-10 px-12",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? '5' : '12'}},[_c('div',{style:(("text-align: " + (_vm.settings.text['text-align']) + ";"))},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatedText)}})])]):_vm._e(),_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pa-12' : 'pa-4',attrs:{"cols":!_vm.$vuetify.breakpoint.mdAndUp ? '12' : '7'}},[_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndUp
                ? 'pa-10 rounded-xl'
                : 'pa-7 rounded-xl',attrs:{"color":_vm.settings['layer-color'],"elevation":"0"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},_vm._l((_vm.settings['type-ticket'].extradata),function(form,i){return _c('form-tickets',{key:i,attrs:{"option":form,"section":_vm.settings}})}),1),_c('v-row',{staticClass:"mb-n3 mt-3",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-btn',{staticClass:"submit-registration",style:(("background-color: " + (_vm.settings['button-color']
                      ? ((_vm.settings['button-color']) + " !important")
                      : ''))),attrs:{"disabled":!_vm.valid,"elevation":"0","loading":_vm.loading,"rounded":""},on:{"click":_vm.saveTicket}},[_c('b',{style:(("color: " + (_vm.settings['text-button-color']
                        ? _vm.settings['text-button-color']
                        : '') + " !important"))},[_vm._v("Inscrever-se")])])],1)],1)],1)],1)],1):_vm._e(),(_vm.settings.layout === 'custom')?_c('v-carousel',{staticClass:"mb-12",attrs:{"hide-delimiters":"","show-arrows":(_vm.$vuetify.breakpoint.mdAndUp &&
          _vm.splitArray &&
          _vm.splitArray.length &&
          _vm.splitArray.length > 1) ||
          !_vm.$vuetify.breakpoint.mdAndUp,"cycle":!_vm.$vuetify.breakpoint.mdAndUp}},_vm._l((_vm.splitArray),function(carousel,index){return _c('v-carousel-item',{key:index},[_c('v-container',{staticStyle:{"overflow":"auto"},attrs:{"fill-height":""}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},_vm._l((_vm.splitArray[index]),function(item,i){return _c('v-col',{key:i,class:_vm.settings['text-color'],attrs:{"cols":_vm.settings['max-coloumns'],"align":"center"}},[_c('v-card',{staticClass:"mt-3 mx-2",staticStyle:{"border-radius":"15px"},attrs:{"elevation":"0","max-width":"\n                  255\n                ","height":"100%","max-height":"100%","color":item.backgroundColor || 'grey'}},[_c('v-row',{attrs:{"no-gutters":"","justify":"start","align":"start"}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-row',{staticClass:"pt-2 px-8 ",staticStyle:{"height":"100%","min-height":"100px","display":"flex","align-items":"center"},attrs:{"no-gutters":""}},[_c('b',{staticClass:"ticketName black--text"},[_vm._v(_vm._s(item.title))])]),_c('v-card',{staticClass:"mx-9 mb-5 mt-5",attrs:{"elevation":"0","color":"transparent","height":"200px"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"align":"start"}},[_c('span',{staticClass:"description black--text",staticStyle:{"white-space":"pre-line","text-align":"left !important"}},[_vm._v(" "+_vm._s(item.description)+" ")]),(
                              item.observacoes &&
                                item.observacoes.length >= 150
                            )?_c('dialog-ticket',{staticClass:"mr-3 py-2",attrs:{"item":item}}):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"py-5",attrs:{"justify":"center","no-gutters":"","align":"center"}},[_c('v-col',[_c('v-row',{staticClass:"mb-2",attrs:{"justify":"center"}},[_c('b',{directives:[{name:"show",rawName:"v-show",value:(!item.encerrado),expression:"!item.encerrado"}],staticClass:"black--text"},[_vm._v(" "+_vm._s(item.price ? ("R$ " + (item.price)) : "Grátis")+" ")])]),_c('v-btn',{style:(("background-color: " + (item.buttonColor ||
                            'black') + " !important;")),attrs:{"dark":"","elevation":"0"},on:{"click":function($event){return _vm.handleOpenExternalLink(item)}}},[_c('b',{style:(("color: " + (item.buttonTextColor
                                ? item.buttonTextColor
                                : 'white') + " !important"))},[_vm._v(_vm._s(item.buttonText || "Comprar"))])])],1)],1)],1)],1)],1)}),1)],1)],1)}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }