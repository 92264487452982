<template>
  <div>
    <v-row class="mb-6" no-gutters>
      <v-btn
        @click="setMediaType('image')"
        elevation="0"
        fab
        text
        class="buttonIcon mr-4"
        :style="`background-color: ${
          section.media.type !== 'image' ? 'white' : '#1976D2'
        } !important`"
      >
        <v-icon :color="section.media.type === 'image' ? 'white' : 'darkBlue'">la-image</v-icon>
      </v-btn>
      <v-btn
        @click="setMediaType('youtube')"
        elevation="0"
        fab
        text
        :style="`background-color: ${
          section.media.type !== 'youtube' ? 'white' : '#1976D2'
        } !important`"
        class="buttonIcon"
      >
        <v-icon :color="section.media.type === 'youtube' ? 'white' : 'darkBlue'">la-youtube</v-icon>
      </v-btn>
    </v-row>
    <set-image v-if="section.media.type === 'image'" :section="section" type="media" />
    <v-row no-gutters>
      <v-text-field
        v-if="section.media.type === 'image'"
        outlined
        class="mt-3"
        background-color="white"
        type="number"
        label="Tamanho"
        v-model="section.media.height"
      ></v-text-field>
    </v-row>
    <v-text-field
      v-if="section.media.type === 'youtube'"
      outlined
      background-color="white"
      label="Video Id"
      v-model="section.media.videoid"
    ></v-text-field>
  </div>
</template>

<script>
import SetImage from "../../media/SetImage.vue";
export default {
  components: { SetImage },
  props: {
    section: {
      type: Object,
    },
  },

  methods: {
    setMediaType(type) {
      this.section.media.type = type;
    },
  },
};
</script>

<style scoped>
.buttonIcon {
  width: 32px !important;
  height: 32px !important;
  color: white !important;
  border: 1px solid #dadde1 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}
</style>