<template>
  <div>
    <v-app-bar
      :color="settings['background-color']"
      :height="hover && $route.name === 'BodyEdit' ? '100px' : '80px'"
      elevation="0"
      :app="$route.name === 'BodyEdit' ? false : true"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
      :class="$route.name === 'BodyEdit' ? 'editCard ' : 'app-bar-style'"
      style="width: 100%"
    >
      <menu-edit
        v-if="$route.name === 'BodyEdit' && hover"
        class="menuEdit"
        :sectionId="settings.id"
      />
      <div class="div-style">
        <v-row
          :style="
            `max-width: ${
              $vuetify.breakpoint.mdAndUp ? '1340px' : ''
            }; width: ${
              $vuetify.breakpoint.mdAndUp ? '100%' : '100vw'
            } !important`
          "
          justify="space-around"
          align="center"
        >
          <v-col cols="2" :align="$vuetify.breakpoint.mdAndUp ? '' : ''">
            <v-img
              :width="$vuetify.breakpoint.mdAndUp ? '' : '200'"
              max-width="100vw"
              :height="settings.logo_height"
              contain
              eager
              :src="settings.logo"
            ></v-img>
          </v-col>
          <v-col class="pr-12" v-if="$vuetify.breakpoint.mdAndUp" cols="10">
            <v-row no-gutters justify="end" align="center">
              <div v-for="(item, index) in settings.options" :key="index">
                <a
                  style="text-decoration: none"
                  :href="item.destination === 'Link Externo' ? item.action : ''"
                  target="_blank"
                  v-if="
                    item.style !== 'button' &&
                      item.destination === 'Link Externo'
                  "
                  class="mr-10 pointer black--text"
                >
                  <b>{{ item.caption }}</b>
                </a>
                <b
                  v-if="
                    item.style !== 'button' &&
                      item.destination !== 'Link Externo'
                  "
                  @click="actionButton(item)"
                  class="mr-10 pointer black--text"
                  >{{ item.caption }}</b
                >
                <v-btn
                  v-if="item.style === 'button'"
                  :href="item.destination === 'Link Externo' ? item.action : ''"
                  elevation="0"
                  min-width="155px"
                  target="_blank"
                  height="44px"
                  :style="
                    `background-color: ${
                      item.color ? item.color : ''
                    } !important`
                  "
                  class="mx-2"
                  :loading="oneCheckoutOpen"
                  @click="actionButton(item)"
                >
                  <b
                    :style="
                      `color: ${
                        item.textColor ? item.textColor : ''
                      } !important`
                    "
                    >{{ item.caption }}</b
                  >
                </v-btn>
              </div>
            </v-row>
          </v-col>
          <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-col
            cols="2"
            align="end"
            class="mr-2"
            v-if="!$vuetify.breakpoint.mdAndUp"
          >
            <v-icon color="blackIcon" @click="drawer = true"
              >las la-bars</v-icon
            >
          </v-col>
        </v-row>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-if="drawer"
      v-model="drawer"
      :color="settings['background-color']"
      right
      fixed
      temporary
    >
      <v-row no-gutters justify="end">
        <v-icon class="mt-4 mr-4" color="blackIcon" @click="drawer = false"
          >las la-close</v-icon
        >
      </v-row>
      <v-row no-gutters justify="center">
        <v-list v-if="settings">
          <v-list-item v-for="(item, index) in settings.options" :key="index">
            <v-row class="mt-4" no-gutters>
              <a
                style="text-decoration: none"
                :href="item.destination === 'Link Externo' ? item.action : ''"
                target="_blank"
                v-if="
                  item.style !== 'button' && item.destination === 'Link Externo'
                "
                class="mr-10 pointer black--text"
              >
                <b>{{ item.caption }}</b>
              </a>
              <b
                v-else-if="
                  item.style !== 'button' && item.destination !== 'Link Externo'
                "
                @click="actionButton(item)"
                class="pointer black--text"
                >{{ item.caption }}</b
              >
              <v-btn
                v-else
                :href="item.destination === 'Link Externo' ? item.action : ''"
                class="mt-5 ml-3"
                elevation="0"
                width="90%"
                height="44px"
                dark
                :style="
                  `background-color: ${item.color ? item.color : ''} !important`
                "
                :loading="oneCheckoutOpen"
                @click="actionButton(item)"
              >
                <b
                  :style="
                    `color: ${item.textColor ? item.textColor : ''} !important`
                  "
                  >{{ item.caption }}</b
                >
              </v-btn>
            </v-row>
          </v-list-item>
        </v-list>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuEdit from "../edit/MenuEdit";
export default {
  components: { MenuEdit },
  data() {
    return {
      drawer: false,
      settings: null,
      hover: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["infos", "oneCheckoutOpen"]),
  },

  created() {
    this.infos.sections.forEach((item) => {
      if (item.type === "menu") {
        this.settings = item;
        this.settings.options = this.settings.options.map((item) => {
          return {
            ...item,
            color: item.color ? item.color : "",
          };
        });
      }
    });
  },

  methods: {
    closeAndScroll(item) {
      this.drawer = false;
      this.$vuetify.goTo(item);
    },

    actionButton(item) {
      if (item.action && item.action.charAt(0) === "#")
        this.$vuetify.goTo(item.action);
      else if (item.destination === "Abrir Checkout") {
        this.openCheckout();
      }
    },
    async openCheckout() {
      await this.$store.dispatch("setOneCheckoutOpen", true);
    },
  },
};
</script>

<style scoped>
.menuEdit {
  z-index: 999 !important;
  position: absolute !important;
  top: 0;
}

.div-style {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
