<template>
  <v-card
    :min-height="settings.height ? settings.height : '500px'"
    elevation="0"
    tile
    :color="
      settings['background-type'] === 'color'
        ? settings['background-color']
        : ''
    "
    :img="
      settings['background-type'] === 'image'
        ? settings['background-image']
        : ''
    "
    :class="$route.name === 'BodyEdit' ? 'editCard' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <menu-edit
      :sectionId="settings.id"
      v-if="$route.name === 'BodyEdit' && hover"
    />
    <title-component :settings="settingsTitle" />
    <v-row class="mx-12 my-12" justify="center" no-gutters>
      <v-card
        style="background-color: transparent !important"
        width="750px"
        elevation="0"
      >
        <v-expansion-panels flat>
          <v-expansion-panel
            v-for="(item, i) in settings.items"
            :key="i"
            class="my-2"
          >
            <v-card
              elevation="0"
              :color="settings['item-color'] || 'grey lighten-2'"
            >
              <v-expansion-panel-header
                ><h1 :style="`color: ${settings['text-color'] || 'black'};`">
                  {{ item.question }}
                </h1></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <span :style="`color: ${settings['text-color'] || 'black'};`">{{
                  item.answer
                }}</span>
              </v-expansion-panel-content>
            </v-card>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
import TitleComponent from "../title/TitleComponent.vue";
import MenuEdit from "../edit/MenuEdit";
export default {
  components: { TitleComponent, MenuEdit },
  props: {
    settings: {
      type: Object,
    },
  },

  data() {
    return {
      settingsTitle: null,
      hover: false,
    };
  },

  created() {
    this.settingsTitle = {
      title: this.settings.title,
      subtitle: this.settings.subtitle,
      "background-color": "transparent",
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.25px;
  text-align: left;
}

span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}
</style>
