<template>
  <div v-if="form">
    <v-row
      v-if="
        (section.type === 'content' && formType === 'text') ||
          (section.type === 'tickets' && formType === 'text')
      "
      no-gutters
    >
      <v-select
        class="my-2"
        outlined
        background-color="white"
        type="number"
        label="Alinhamento do Texto"
        :items="aligns"
        v-model="form['text-align']"
      ></v-select>
    </v-row>

    <v-card
      v-if="
        (section.type === 'content' && formType === 'text') ||
          (section.type === 'tickets' && formType === 'text') ||
          (section.type === 'cover' && formType === 'text')
      "
      elevation="0"
    >
      <vue-editor
        :disabled="communication.status === 'E'"
        v-model="form.content"
        :editorToolbar="editorToolbar"
        :editorOptions="editorSettings"
        class="text-message"
      ></vue-editor>
    </v-card>

    <v-textarea
      v-else
      background-color="white"
      outlined
      auto-grow
      label="Texto"
      v-model="form.content"
    ></v-textarea>

    <v-checkbox
      class="mb-4"
      hide-details
      color="darkBlue"
      label="Personalizar Estilo"
      v-model="personTitle"
      @click="resetPersonalize"
    ></v-checkbox>

    <v-row v-show="personTitle">
      <v-col cols="7">
        <v-select
          background-color="white"
          outlined
          label="Fonte"
          :items="fontsType"
          v-model="form.font"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          background-color="white"
          outlined
          type="number"
          label="Tamanho"
          :value="form['font-size'] ? form['font-size'].replace('px', '') : ''"
          @input="updateSize($event)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="ml-1 mt-n5" v-show="personTitle" no-gutters>
      <span class="titleColor">Cor</span>
    </v-row>
    <v-row class="ml-1" v-show="personTitle" no-gutters>
      <div class="cardColor">
        <v-row no-gutters align="center">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-card
                v-bind="attrs"
                v-on="on"
                elevation="0"
                class="color"
                :color="form.color"
              ></v-card>
            </template>
            <v-color-picker
              mode="hexa"
              hide-mode-switch
              swatches-max-height="173"
              v-model="color"
              @input="changeColor($event)"
            ></v-color-picker>
          </v-menu>

          <span class="textColor mt-1 ml-2">{{ form.color }}</span>
        </v-row>
      </div>
    </v-row>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  props: {
    form: { type: Object },
    section: { type: Object, default: null },
    formType: { type: String },
  },
  components: { VueEditor },
  data() {
    return {
      fontsType: ["Inter", "Montserrat", "Roboto", "Work Sans"],
      personTitle: false,
      type: "hex",
      hex: "#ffffff",
      aligns: [
        { text: "Esquerda", value: "start" },
        { text: "Centralizado", value: "center" },
        { text: "Direita", value: "end" },
        { text: "Justificado", value: "justify" },
      ],
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"],
      ],
      editorSettings: {},
      communication: [],
    };
  },

  computed: {
    color: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },
    showColor() {
      if (typeof this.color === "string") return this.color;

      return JSON.stringify(
        Object.keys(this.color).reduce((color, key) => {
          color[key] = Number(this.color[key].toFixed(2));
          return color;
        }, {}),
        null,
        2
      );
    },
  },

  mounted() {
    if (
      (this.form && this.form.font) ||
      this.form["font-size"] ||
      this.form.color
    )
      this.personTitle = true;
  },

  methods: {
    resetPersonalize() {
      if (!this.personTitle) {
        this.form.font = "";
        this.form["font-size"] = "";
        this.form.color = "";
      }
    },

    updateSize(event) {
      let model = "";
      model = event + "px";
      this.form["font-size"] = model;
    },

    changeColor(event) {
      this.form.color = event;
    },
  },
};
</script>

<style scoped>
* {
  font-family: Inter, sans-serif !important;
}

.text-message {
  background-color: white;
}
.titleColor {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #192b46;
}

.cardColor {
  height: 40px;
  width: 130px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #a3aab5;
  box-sizing: border-box;
  border-radius: 4px;
}

.color {
  width: 32px;
  height: 32px;
  margin-left: 3px;
  margin-top: 3px;
  background: #ffffff;
  border: 1px solid #c8ccd3;
  box-sizing: border-box;
  border-radius: 4px;
}
</style>
